import { isNumber } from 'lodash';
import React, { useState } from 'react';

import AttachmentItem from 'components/AttachmentItem';
import Container from 'components/Container/default';
import FlexContainer from 'components/Container/FlexContainer';
import PreviewModal from 'components/PreviewModal';
import Text from 'components/Text';
import { ATTACHMENT_LIMIT } from 'constants/constants';
import translation from 'constants/translation';
import { Colors, FontSize, FontWeight } from 'theme';
import { FlexAlignItems, FlexWrap } from 'theme/layout';

interface AttachmentsContainerProps {
	attachments: File[];
	onDelete: (index: number | string) => void;
}

const AttachmentsContainer = (props: AttachmentsContainerProps) => {
	const totalFileSize = props.attachments.reduce((acc, curr) => acc + curr.size, 0);
	const [fileIndex, setFileIndex] = useState<number | null>(null);

	const attachmentItems = props.attachments.map((attachment, index) => (
		<AttachmentItem
			id={index}
			canDelete
			onDelete={(_, id) => props.onDelete(id)}
			url={URL.createObjectURL(attachment)}
			onClick={(_, id) => setFileIndex(id as number)}
			fileType={attachment.type}
			name={attachment.name}
			tooltipId={index}
		/>
	));

	return (
		<Container margin={{ top: 16 }} bgColor={Colors.transparent}>
			{totalFileSize > ATTACHMENT_LIMIT && (
				<Text
					htmlTagName="div"
					color={Colors.error600}
					fontSize={FontSize.text_xs}
					fontWeight={FontWeight.semiBold}
					margin={{ bottom: 10 }}
				>
					{translation.file_size_exceeds}
				</Text>
			)}
			<FlexContainer
				wrap={FlexWrap.wrap}
				alignItems={FlexAlignItems.center}
				rowGap={10}
				bgColor={Colors.transparent}
			>
				{attachmentItems}
			</FlexContainer>
			<PreviewModal
				isLocalFile
				data={props.attachments}
				isOpen={isNumber(fileIndex)}
				activeIndex={fileIndex ?? 0}
				toggle={() => setFileIndex(null)}
			/>
		</Container>
	);
};

export default AttachmentsContainer;
