import React from 'react';

import { Screens } from 'types';

interface GlobalAppInterface {
	currentScreen: Screens;
	isDesktop: boolean;
	isTablet: boolean;
	isMobile: boolean;
}

/**
 * Global App context stores the device information
 * @property { currentScreen } - Tells the current screen type. One of desktop | tablet | mobile
 * @property { isDesktop } - Returns true in Desktop screens
 * @property { isMobile } - Returns true in Mobile screens
 * @property { isTablet } - Returns true in Tablet screens
 */
export const GlobalAppContext = React.createContext<GlobalAppInterface>({
	currentScreen: Screens.DESKTOP,
	isDesktop: true,
	isTablet: false,
	isMobile: false,
});

export const FormikContext = React.createContext({ formik: {} });
