import styled from '@emotion/styled';
import React, { useContext } from 'react';

import { ReactComponent as DeletedImage } from 'assets/images/deleted.svg';
import EmptyState from 'components/EmptyState';
import Text from 'components/Text';
import { EMPTY_STATE_DIMENSIONS } from 'constants/constants';
import { GlobalAppContext } from 'helpers/contexts';
import { FontSize, Colors, FontWeight, TextAlign } from 'theme';

const EmptyStateContainer = styled(EmptyState)`
	height: 100%;
	width: 100%;
	place-items: center;
	place-content: center;
	grid-auto-rows: max-content;
`;

const PageNotFound = () => {
	const { isMobile, currentScreen } = useContext(GlobalAppContext);

	return (
		<EmptyStateContainer
			margin={{ top: 40 }}
			image={DeletedImage}
			label="page_not_found"
			labelProps={{ fontSize: isMobile ? FontSize.text_md : FontSize.text_xl }}
			{...EMPTY_STATE_DIMENSIONS[currentScreen]}
		>
			<Text
				translation="page_not_found_description"
				color={Colors.grey500}
				fontSize={FontSize.text_sm}
				fontWeight={FontWeight.semiBold}
				margin={{ top: 12 }}
				width={EMPTY_STATE_DIMENSIONS[currentScreen].imageWidth}
				textAlign={TextAlign.center}
			/>
		</EmptyStateContainer>
	);
};

export default PageNotFound;
