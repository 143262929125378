import styled from '@emotion/styled';
import React from 'react';

import FlexContainer from 'components/Container/FlexContainer';
import { Box } from 'theme/types';

export interface CheckboxProps {
	/**
	 * Margin for item
	 */
	isSelected?: boolean;
	/**
	 * Margin for item
	 */
	isIndeterminate?: boolean;
	/**
	 * Margin for item
	 */
	isDisabled?: boolean;
	/**
	 * Margin for item
	 */
	size?: 'sm' | 'md';
	/**
	 * Margin for item
	 */
	isCircle?: boolean;
	/**
	 * Margin for item
	 */
	margin?: Partial<Box>;
	/**
	 * Button click event
	 */
	onClick?: (event: React.MouseEvent<HTMLDivElement>, checked: boolean) => void;
}

export const StyledContainer = styled(FlexContainer)<{
	isDisabled?: boolean;
	isCircle?: boolean;
	isSelected?: boolean;
	size?: 'sm' | 'md';
}>`
	cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
	:focus-visible {
		${({ theme }) => `outline: ${theme.colors.transparent}`};
	}
	${({ isCircle, size }) =>
		isCircle ? 'border-radius : 50%' : `border-radius : ${size === 'sm' ? '4px' : '6px'}`};
	${({ isDisabled, isSelected, theme }) =>
		!isDisabled &&
		`
			:focus {
				box-shadow: 0px 0px 0px 2px ${theme.colors.primary100};
				> div {
					border: 1px solid ${theme.colors.primary300};
					${!isSelected && `background-color: ${theme.colors.primary25};`}
				}
			}
			:hover {
				> div {
					border: 1px solid ${theme.colors.primary600};
					${!isSelected && `background-color: ${theme.colors.primary25};`}
				}
			}
		`}
	> div {
		${({ isCircle, size }) =>
			isCircle ? 'border-radius : 50%' : `border-radius : ${size === 'sm' ? '4px' : '6px'}`};
		${({ isDisabled, theme }) =>
			isDisabled &&
			`
				border: 1px solid ${theme.colors.grey200};
				background-color: ${theme.colors.grey100};
			`}
	}
`;
