import { CustomField, FeedbackVisibility, ModuleItem } from './feedbacks';

export enum Sections {
	FEEDBACK = 'feedback',
	ROADMAP = 'roadmap',
	RELEASE_NOTES = 'releaseNotes',
}

export interface PortalSections {
	displayName: string;
	canView: boolean;
	index: number;
}
//
export interface PortalConfig {
	isEnabled: boolean;
	theme: Theme;
	workspaceId: string;
	visibility: FeedbackVisibility;
	isDashboardUser: boolean;
	boardUniqueName: string;
	sections: {
		[Sections.FEEDBACK]: PortalSections;
		[Sections.ROADMAP]: PortalSections;
		[Sections.RELEASE_NOTES]: PortalSections;
	};
	submittedFeedbackVisibility: FeedbackVisibility;
	actions: Actions;
	visibleRoadmaps: string;
	visibleCustomFields: CustomField[];
	redirectUrl?: string;
}

export interface Actions {
	canVote: boolean;
	canComment: boolean;
	canSubmitWithoutLogin: boolean;
	canSubmit: boolean;
	submissionMessage: string;
}

export interface Theme {
	logo: string;
	favIcon: string;
	tabLabel: string;
	color: string;
	showZedaLogo: boolean;
	websiteURL: string;
}

export interface WorkspaceInfoType {
	id: string;
	name: string;
	image: string | null;
	isActive: boolean;
	description: string | null;
	uniqueName: string;
	uniquePortalName: string;
	uniqueCode: string;
	portalCustomURL: string | null;
}

export interface AppState {
	configuration: PortalConfig | null;
	workspaceInfo: WorkspaceInfoType | null;
	loadingStatus: string | number;
}

export interface ExternalForm {
	type: ModuleItem[];
	visibleCustomFields: CustomField[];
	workspace: WorkspaceInfoType;
}
