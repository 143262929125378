import { createSelector } from 'reselect';

import { feedbackStateKeys } from 'constants/constants';
import {
	CustomFieldFormType,
	CustomFieldType,
	MappedFeedbackDetail,
	MappedRequestItem,
	ModuleItem,
} from 'types/feedbacks';
import { RootState } from 'types/state';

export function getState(state: RootState) {
	return state.feedbackReducer;
}

export function getFeedbackInfo(state: RootState) {
	return state.feedbackReducer.list;
}

export const getSortedTypeList = createSelector(getState, (settings) =>
	settings.types
		?.filter((type) => type.key !== 'TRANSCRIPT')
		.sort((first, second) => first.index - second.index)
);

export const getSortedStatusList = createSelector(getState, (settings) => {
	const feedbackStates = Object.values(feedbackStateKeys);
	const sortedStatusList =
		settings.status.sort((status1, status2) => {
			// if same states simply order by
			if (status1.state === status2.state) {
				return status1.index - status2.index;
			}

			// sort by states
			const state1index = feedbackStates.findIndex((stateInfo) => stateInfo === status1.state);
			const state2index = feedbackStates.findIndex((stateInfo) => stateInfo === status2.state);

			return state1index - state2index;
		}) ?? [];

	return sortedStatusList;
});

export const getStatusListWithBacklog = createSelector(getSortedStatusList, (statusList) => {
	const mappedStatusList = statusList.map((status, index) => ({ ...status, index: index + 1 }));
	return mappedStatusList;
});

export const getAllFeedbacks = createSelector(
	getFeedbackInfo,
	getStatusListWithBacklog,
	getSortedTypeList,
	(feedbackInfo, statusList, typeList) => {
		return feedbackInfo.feedbacks?.map((request): MappedRequestItem => {
			// Created Status
			const status: ModuleItem | undefined = statusList.find(
				(statusItem: ModuleItem) => statusItem.id === request.statusId
			);

			const type: ModuleItem | undefined = typeList.find(
				(typeItem: ModuleItem) => typeItem.id === request.typeId
			);

			return { ...request, status, type };
		});
	}
);

export const getFeedbackDetail = createSelector(
	getState,
	getStatusListWithBacklog,
	getSortedTypeList,
	(state, statusList, typeList) => {
		const status: ModuleItem | undefined = statusList.find(
			(statusItem: ModuleItem) => statusItem.id === state.feedbackDetails.statusId
		);
		const type: ModuleItem | undefined = typeList.find(
			(typeItem: ModuleItem) => typeItem.id === state.feedbackDetails.typeId
		);
		return { ...state.feedbackDetails, status, type } as MappedFeedbackDetail;
	}
);

export const getTypeOptions = createSelector(getSortedTypeList, (types) =>
	types.map((type) => {
		return {
			label: type.label,
			value: type.id,
		};
	})
);

export function getVisibleCustomFields(state: RootState) {
	return state.appReducer.configuration?.visibleCustomFields ?? [];
}

export const getExternalFeedbackFormCustomFields = createSelector(
	getState,
	(state): CustomFieldFormType[] =>
		state.visibleCustomFields?.map((customField) => ({
			name: customField.name,
			type: customField.type,
			typeId: customField.id,
			options: Array.isArray(customField.options)
				? (customField.options || []).map((option) => option.value)
				: [],
			isRequired: customField.isRequired,
			value: customField.type === CustomFieldType.MULTI_SELECT ? [] : '',
			configurations: customField.configurations,
		}))
);

export const getRequestFormCustomFields = createSelector(
	getVisibleCustomFields,
	(customFields): CustomFieldFormType[] =>
		customFields.map((customField) => ({
			name: customField.name,
			type: customField.type,
			typeId: customField.id,
			options: Array.isArray(customField.options)
				? (customField.options || []).map((option) => option.value)
				: [],
			isRequired: customField.isRequired,
			value: customField.type === CustomFieldType.MULTI_SELECT ? [] : '',
			configurations: customField.configurations,
		}))
);

export const getMappedCommentsList = createSelector(
	(comments: any, sortState: any) => ({ comments, sortState }),
	({ comments, sortState }): any => {
		const mappedComments = comments.reduce((acc: any, comment: any) => {
			// const canEdit = userReducer.info?.id === comment?.commentedBy?.id;

			if (comment.parentCommentId) {
				const { parentCommentId } = comment;
				const replies = acc[parentCommentId]?.replies ?? [];

				replies.push({ ...comment });
				acc[parentCommentId] = { ...acc[parentCommentId], replies };
				return acc;
			}

			acc[comment.id] = { ...comment, replies: acc[comment.id]?.replies ?? [] };
			return acc;
		}, {});

		return Object.values(mappedComments).sort((comment1: any, comment2: any) =>
			sortState === 'NEWEST'
				? new Date(comment2.createdAt).getTime() - new Date(comment1.createdAt).getTime()
				: new Date(comment1.createdAt).getTime() - new Date(comment2.createdAt).getTime()
		);
	}
);
