import React from 'react';

import { Sections } from 'types/app';

const FeedbackScreen = React.lazy(() => import('../Screens/Feedback'));
const FeedbackRoadmap = React.lazy(() => import('../Screens/Roadmap'));
const ReleaseNoteList = React.lazy(() => import('../Screens/ReleaseNotes/ListView'));
const ReleaseNoteDetails = React.lazy(() => import('../Screens/ReleaseNotes/DetailsView'));
const FormContainer = React.lazy(() => import('../Screens/Feedback/ListView/FormContainer'));
const FeedbackDetailScreen = React.lazy(() => import('../Screens/Feedback/DetailsView/index'));

export enum RouteConstants {
	FEEDBACK_ROADMAP = '/roadmap',
	FEEDBACK_LIST = '/feedback/*',
	FEEDBACK_DETAIL = '/feedback/:id',
	RELEASE_NOTE_LIST = '/release-notes',
	RELEASE_NOTE_DETAIL = '/release-notes/:id',
	AUTH_CALLBACK = '/auth/callback',
	AUTO_LOGIN = '/auto-login',
	FEEDBACK_FORM = '/feedback-form',
	EXTERNAL_FEEDBACK_FORM = '/req/form/:id',
}

interface RouteConfig {
	key: string;
	component: React.ComponentType<any>;
	path: RouteConstants;
	section?: Sections[];
}

const appRoutes: RouteConfig[] = [
	{
		key: 'feedback_list',
		component: FeedbackScreen,
		path: RouteConstants.FEEDBACK_LIST,
		section: [Sections.FEEDBACK],
	},
	{
		key: 'feedback_detail',
		component: FeedbackDetailScreen,
		path: RouteConstants.FEEDBACK_DETAIL,
		section: [Sections.FEEDBACK, Sections.ROADMAP],
	},
	{
		key: 'feedback_roadmap',
		component: FeedbackRoadmap,
		path: RouteConstants.FEEDBACK_ROADMAP,
		section: [Sections.ROADMAP],
	},
	{
		key: 'release_note_list',
		component: ReleaseNoteList,
		path: RouteConstants.RELEASE_NOTE_LIST,
		section: [Sections.RELEASE_NOTES],
	},
	{
		key: 'release_note_details',
		component: ReleaseNoteDetails,
		path: RouteConstants.RELEASE_NOTE_DETAIL,
		section: [Sections.RELEASE_NOTES],
	},
	{
		key: 'feedback_form',
		component: FormContainer,
		path: RouteConstants.FEEDBACK_FORM,
		section: [Sections.FEEDBACK],
	},
];

export const restrictedKeys = ['feedback', 'release-notes', 'no-sections', 'auth', 'auto-login'];

export default appRoutes;
