import React from 'react';

import DefaultSelect from 'components/Select/default';
import { BaseSelectProps, BasicOption } from 'components/Select/Select';
import Text from 'components/Text';
import { Colors, FontSize, FontWeight } from 'theme';

const Select = (
	props: {
		options: Omit<BasicOption, 'element'>[];
	} & BaseSelectProps
) => {
	const { placeholder, ...rest } = props;
	const options = props.options.map((option) => ({
		...option,
		element: (
			<Text
				color={option.disabled ? Colors.grey200 : Colors[option.itemProps?.color ?? 'grey900']}
				fontSize={FontSize[option.itemProps?.fontSize ?? 'text_md']}
				fontWeight={FontWeight[option.itemProps?.fontWeight ?? 'regular']}
				ellipsis
			>
				{option.label}
			</Text>
		),
	}));

	const placeholderComp = (
		<Text
			color={Colors.grey500}
			fontSize={FontSize[props.fontSize ?? 'text_md']}
			fontWeight={FontWeight.regular}
			ellipsis
		>
			{placeholder ?? 'Select'}
		</Text>
	);

	return <DefaultSelect {...rest} options={options} placeholder={placeholderComp} />;
};

export default Select;
