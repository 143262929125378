import React from 'react';

import { TextAlign } from 'theme';
import { FlexAlignSelf, FlexJustify } from 'theme/layout';
import { BorderModel, Box, PositionModel } from 'theme/types';
import { ColorKeys, FontSizeKeys, FontWeightkeys } from 'types';

export interface DropdownItemBaseProps {
	/**
	 * if item is active
	 */
	active?: boolean;
	/**
	 * if we need to redirect to a link
	 */
	href?: string;
	/**
	 * defines if we need to toggle the menu on click of item
	 */
	toggle?: boolean;
	/**
	 * Margin for item
	 */
	margin?: Partial<Box>;
	/**
	 * Additional CSS class
	 */
	className?: string;
	/**
	 * To disable the item
	 */
	disabled?: boolean;
	/**
	 * Button click event
	 */
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Unique id for the item
	 */
	id?: string;
	/**
	 * Alignment of text within item
	 */
	textAlign?: TextAlign;
	/**
	 * HTML name attribute
	 */
	name?: string;
	/**
	 * Maximum width of item
	 */
	maxWidth?: number | string;
	/**
	 * Minimum width of item
	 */
	minWidth?: number | string;
	/**
	 * Maximum height of item
	 */
	maxHeight?: number | string;
	/**
	 * Minimum height of item
	 */
	minHeight?: number | string;
	/**
	 * Set how item is positioned in DOM
	 */
	position?: PositionModel;
	/**
	 * Flex basis - Only applied if parent is a flex container
	 */
	flexBasis?: string | number;
	/**
	 * Flex grow - Only applied if parent is a flex container
	 */
	flexGrow?: number;
	/**
	 * Flex shrink - Only applied if parent is a flex container
	 */
	flexShrink?: number;
	/**
	 * Flex align self - Only applied if parent is a flex container
	 */
	alignSelf?: FlexAlignSelf;
	/**
	 * Flex justify self - Only applied if parent is a flex container
	 */
	justifySelf?: FlexJustify;
	/**
	 * Grid row start position - Only applied if parent is a grid container
	 */
	gridRowStart?: number;
	/**
	 * Grid row end position - Only applied if parent is a grid container
	 */
	gridRowEnd?: number;
	/**
	 * Grid column start position - Only applied if parent is a grid container
	 */
	gridColumnStart?: number;
	/**
	 * Grid column end position - Only applied if parent is a grid container
	 */
	gridColumnEnd?: number;
	/**
	 * Background color for the item
	 */
	bgColor?: ColorKeys;
	/**
	 * Height of the Button
	 */
	height?: string | number;
	/**
	 * Width of the Button
	 */
	width?: string | number;
	/**
	 * Border for item
	 */
	border?: BorderModel;
	/**
	 * Padding for item
	 */
	padding?: Partial<Box>;
	/**
	 * Font size for item children
	 */
	fontSize?: FontSizeKeys;
	/**
	 * Font weight for item children
	 */
	fontWeight?: FontWeightkeys;
	/**
	 * Color for item children
	 */
	color?: ColorKeys;
	/**
	 * Title for text
	 */
	title?: string;
	/**
	 * Cursor type
	 */
	cursor?: string;
	/**
	 * Element to be rendered inside item
	 */
	children?: React.ReactNode;
}

export interface SelectDropdownItemProps extends DropdownItemBaseProps {
	/**
	 * Whether to show checkbox
	 */
	showCheckbox?: boolean;
	/**
	 * Whether to show tick mark at the end when active
	 */
	tickOnSelect?: boolean;
}

export const DefaultDropdownStylingProps = {
	padding: { top: 8, bottom: 8, left: 14, right: 14 },
	border: { all: 0, radius: 4 },
	width: 320,
	height: 40,
	textAlign: TextAlign.left,
	cursor: 'pointer',
	color: 'grey900' as ColorKeys,
	fontSize: 'text_md' as FontSizeKeys,
	fontWeight: 'regular' as FontWeightkeys,
};
