import { MessageSquareLinesIcon, ChartSimpleGanttIcon, MegaphoneIcon } from 'zeda-icons/v3';

import { RouteConstants } from 'routes/config';
import { Space } from 'theme/layout';
import { Screens } from 'types';
import { Sections } from 'types/app';

export const HEADER_HEIGHT = {
	[Screens.DESKTOP]: 104,
	[Screens.TABLET]: 100,
	[Screens.MOBILE]: 76,
};

export const HEADER_INLINE_SPACING = {
	[Screens.DESKTOP]: Space.auto,
	[Screens.TABLET]: 0,
	[Screens.MOBILE]: 0,
};

export const HEADER_BLOCK_PADDING = {
	[Screens.DESKTOP]: 8,
	[Screens.TABLET]: 8,
	[Screens.MOBILE]: 16,
};

export const HeaderRouterConfig = {
	[Sections.FEEDBACK]: {
		link: RouteConstants.FEEDBACK_LIST.replace('/*', ''),
		icon: MessageSquareLinesIcon,
	},
	[Sections.ROADMAP]: { link: RouteConstants.FEEDBACK_ROADMAP, icon: ChartSimpleGanttIcon },
	[Sections.RELEASE_NOTES]: {
		link: RouteConstants.RELEASE_NOTE_LIST,
		icon: MegaphoneIcon,
	},
};
