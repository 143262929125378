import React from 'react';

import { FormikContext } from 'helpers/contexts';

const Form = (props: any) => {
	const { formik = {} } = props;

	return (
		<FormikContext.Provider value={{ formik }}>
			<form onSubmit={formik.handleSubmit} className={props.className} autoComplete="off">
				{props.children}
			</form>
		</FormikContext.Provider>
	);
};

export default Form;
