import { withTheme, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useContext, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';
import { MenuIcon } from 'zeda-icons/v3';

import NavSection from './NavSection';
import Profile from './Profile';

import { Logout, UserLogin } from 'actions/UserAction';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import FlexContainer from 'components/Container/FlexContainer';
import GridContainer from 'components/Container/GridContainer';
import Image from 'components/Image';
import Text from 'components/Text';
import { envConfig } from 'constants/constants';
import translation from 'constants/translation';
import {
	HEADER_BLOCK_PADDING,
	HEADER_HEIGHT,
	HEADER_INLINE_SPACING,
} from 'containers/Header/Header';
import { GlobalAppContext } from 'helpers/contexts';
import { openInNewTab } from 'helpers/routes';
import { removeCookie, setPreviousURL } from 'helpers/storage';
import { getAvatarImageUrl } from 'helpers/utils';
import {
	getPortalSettings,
	getVisiblePortalSections,
	getWorkspaceDetails,
} from 'selectors/AppSelector';
import { getUserInfo } from 'selectors/UserSelector';
import { ColorFamily, Colors, FontSize, FontWeight } from 'theme';
import { Position, FlexAlignItems, FlexDirection, Space } from 'theme/layout';
import { RootState } from 'types/state';

const HeaderContainer = styled(GridContainer)<{ height: number }>`
	:after {
		content: '';
		position: fixed;
		top: ${({ height }) => height}px;
		left: 0;
		width: 100vw;
		height: 1px;
		background-color: ${Colors.grey200};
	}
`;

const StyledImage = styled(Image)`
	object-fit: contain;
`;

const Logo = styled(StyledImage)`
	width: auto;
	max-width: 160px;
`;

const Link = styled.a`
	text-decoration: none !important;
`;

type Props = ReduxProps;
interface ThemeProps {
	theme: Theme;
}

const Header: React.FC<Props & ThemeProps> = (props) => {
	const { userInfo, settings, sections, workspaceImage, workspaceDetails, websiteURL } = props;
	const [isSidebarOpen, setSidebarOpen] = useState(false);
	const { currentScreen, isMobile, isDesktop } = useContext(GlobalAppContext);

	const inlineMargin = HEADER_INLINE_SPACING[currentScreen];
	const blockPadding = HEADER_BLOCK_PADDING[currentScreen];

	const isUserLoggedIn = !!userInfo;

	const toggleSidebar = () => {
		setSidebarOpen((prev) => !prev);
	};

	const location = useLocation();

	const handleLogin = async () => {
		if (!settings) return;

		removeCookie('portalAccessToken');
		const { data, error } = await props.login(settings.workspaceId, settings.boardUniqueName);
		if (error) return;

		// Custom domain does not have uniqueName so different logic for that
		const isCustomDomain = window.location.host !== envConfig.portal_url;
		const previousURL = isCustomDomain
			? location.pathname.split('/').splice(2).join('/')
			: location.pathname.split('/').splice(3).join('/');
		setPreviousURL(previousURL);
		window.location.href = data.url;
	};

	const handleLogout = async () => {
		await props.logout();
		removeCookie('portalAccessToken');
		window.location.reload();
	};

	const redirectToDashboard = () => {
		const url = `${envConfig.dashboard_url}/${workspaceDetails?.uniqueName ?? ''}`;
		openInNewTab(url);
	};

	const UserInfo = isUserLoggedIn ? (
		<Profile
			userInfo={userInfo}
			onLogout={handleLogout}
			redirectToDashboard={redirectToDashboard}
		/>
	) : (
		<Button
			onClick={handleLogin}
			variant="tertiary"
			size="sm"
			colorTheme={ColorFamily.grey}
			translation="login_signup_cta"
			margin={{ left: Space.auto }}
		/>
	);

	const workspaceLogo = workspaceImage ? (
		<Logo
			src={workspaceImage}
			alt={workspaceDetails?.name ?? translation.customer_portal_logo}
			height={32}
			title={workspaceDetails?.name}
			margin={{ left: 8 }}
		/>
	) : (
		<FlexContainer alignItems={FlexAlignItems.center} columnGap={8}>
			<StyledImage
				src={getAvatarImageUrl({
					name: workspaceDetails?.name || translation.customer_portal,
					colors: props.theme.colors,
				})}
				alt={workspaceDetails?.name ?? translation.customer_portal_logo}
				height={32}
				width={32}
				title={workspaceDetails?.name}
				margin={{ left: 8 }}
			/>
			<Text fontSize={FontSize.text_sm} fontWeight={FontWeight.semiBold} color={Colors.grey700}>
				{workspaceDetails?.name}
			</Text>
		</FlexContainer>
	);

	return (
		<HeaderContainer
			className="app-header"
			height={HEADER_HEIGHT[currentScreen]}
			bgColor={Colors.white}
			zIndex={100}
			position={{ value: Position.fixed, top: 0, left: 0, right: 0 }}
			gridTemplateConfig={{ column: Array.from({ length: 12 }, () => '1fr') }}
			margin={{
				left: inlineMargin,
				right: inlineMargin,
			}}
			{...(isDesktop && { maxWidth: 1280 })}
		>
			<FlexContainer
				direction={FlexDirection.column}
				bgColor={Colors.white}
				padding={{
					top: blockPadding,
					bottom: blockPadding,
					...(isMobile && { left: 16, right: 16 }),
				}}
				columnGap={16}
				gridColumnStart={isMobile ? 1 : 2}
				gridColumnEnd={isMobile ? 13 : 12}
			>
				<FlexContainer alignItems={FlexAlignItems.center} flexGrow={1}>
					{isMobile && (
						<IconButton
							onClick={() => setSidebarOpen(true)}
							variant="tertiary"
							size="sm"
							colorTheme={ColorFamily.grey}
							icon={MenuIcon}
						/>
					)}
					{websiteURL ? (
						<Link href={websiteURL} target="_blank" rel="noreferrer">
							{workspaceLogo}
						</Link>
					) : (
						workspaceLogo
					)}
					{UserInfo}
				</FlexContainer>
				{!isMobile && (
					<NavSection featureRoadmapId={settings?.visibleRoadmaps} sections={sections} />
				)}
				{isSidebarOpen && (
					<NavSection
						isSidebarOpen={isSidebarOpen}
						toggleSidebar={toggleSidebar}
						sections={sections}
					/>
				)}
			</FlexContainer>
		</HeaderContainer>
	);
};

const mapStateToProps = (state: RootState) => ({
	sections: getVisiblePortalSections(state),
	userInfo: getUserInfo(state),
	settings: getPortalSettings(state),
	workspaceImage: state.appReducer.configuration?.theme?.logo,
	websiteURL: state.appReducer.configuration?.theme?.websiteURL,
	workspaceDetails: getWorkspaceDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	login: (id: string, boardUniqueName: string) => dispatch(UserLogin.request(id, boardUniqueName)),
	logout: () => dispatch(Logout.request()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default withTheme(connector(Header));
