enum Colors {
	// Primary colors

	// grey
	grey25 = '#FCFCFD',
	grey50 = '#F9FAFB',
	grey100 = '#F2F4F7',
	grey200 = '#EAECF0',
	grey300 = '#D0D5DD',
	grey400 = '#98A2B3',
	grey500 = '#667085',
	grey600 = '#475467',
	grey700 = '#344054',
	grey800 = '#1D2939',
	grey900 = '#101828',

	// Primary
	primary25 = '#EEF0FC',
	primary50 = '#DCE1F9',
	primary100 = '#CBD2F6',
	primary200 = '#BAC3F3',
	primary300 = '#A8B3F0',
	primary400 = '#96A3ED',
	primary500 = '#8594EA',
	primary600 = '#5E72E4',
	primary700 = '#3F57DE',
	primary800 = '#2E48DC',
	primary900 = '#233ED1',

	// Error
	error25 = '#FFFBFA',
	error50 = '#FEF3F2',
	error100 = '#FEE4E2',
	error200 = '#FEE4E2',
	error300 = '#FDA29B',
	error400 = '#F97066',
	error500 = '#F04438',
	error600 = '#D92D20',
	error700 = '#B42318',
	error800 = '#912018',
	error900 = '#7A271A',

	// Warning
	warning25 = '#FFFCF5',
	warning50 = '#FFFAEB',
	warning100 = '#FEF0C7',
	warning200 = '#FEDF89',
	warning300 = '#FEC84B',
	warning400 = '#FDB022',
	warning500 = '#F79009',
	warning600 = '#DC6803',
	warning700 = '#B54708',
	warning800 = '#93370D',
	warning900 = '#7A2E0E',

	// Info
	info25 = '#F5FAFF',
	info50 = '#EFF8FF',
	info100 = '#D1E9FF',
	info200 = '#B2DDFF',
	info300 = '#84CAFF',
	info400 = '#53B1FD',
	info500 = '#2E90FA',
	info600 = '#1570EF',
	info700 = '#175CD3',
	info800 = '#1849A9',
	info900 = '#194185',

	// Success
	success25 = '#F6FEF9',
	success50 = '#ECFDF3',
	success100 = '#D1FADF',
	success200 = '#A6F4C5',
	success300 = '#6CE9A6',
	success400 = '#32D583',
	success500 = '#12B76A',
	success600 = '#039855',
	success700 = '#027A48',
	success800 = '#05603A',
	success900 = '#054F31',

	// Secondary colors
	white = '#FFFFFF',
	black = '#000000',

	// blue grey
	bluegrey25 = '#FCFCFD',
	bluegrey50 = '#F8F9FC',
	bluegrey100 = '#EAECF5',
	bluegrey200 = '#D5D9EB',
	bluegrey300 = '#AFB5D9',
	bluegrey400 = '#717BBC',
	bluegrey500 = '#4E5BA6',
	bluegrey600 = '#3E4784',
	bluegrey700 = '#363F72',
	bluegrey800 = '#293056',
	bluegrey900 = '#101323',

	// Indigo
	indigo25 = '#F5F8FF',
	indigo50 = '#EEF4FF',
	indigo100 = '#E0EAFF',
	indigo200 = '#C7D7FE',
	indigo300 = '#A4BCFD',
	indigo400 = '#8098F9',
	indigo500 = '#6172F3',
	indigo600 = '#444CE7',
	indigo700 = '#3538CD',
	indigo800 = '#2D31A6',
	indigo900 = '#2D3282',

	// Purple
	purple25 = '#FAFAFF',
	purple50 = '#F4F3FF',
	purple100 = '#EBE9FE',
	purple200 = '#D9D6FE',
	purple300 = '#BDB4FE',
	purple400 = '#9B8AFB',
	purple500 = '#7A5AF8',
	purple600 = '#6938EF',
	purple700 = '#5925DC',
	purple800 = '#4A1FB8',
	purple900 = '#3E1C96',

	// Blue light
	bluelight25 = '#F5FBFF',
	bluelight50 = '#F0F9FF',
	bluelight100 = '#E0F2FE',
	bluelight200 = '#B9E6FE',
	bluelight300 = '#7CD4FD',
	bluelight400 = '#36BFFA',
	bluelight500 = '#0BA5EC',
	bluelight600 = '#0086C9',
	bluelight700 = '#026AA2',
	bluelight800 = '#065986',
	bluelight900 = '#0B4A6F',

	// Rose
	rose25 = '#FFF5F6',
	rose50 = '#FFF1F3',
	rose100 = '#FFE4E8',
	rose200 = '#FECDD6',
	rose300 = '#FEA3B4',
	rose400 = '#FD6F8E',
	rose500 = '#F63D68',
	rose600 = '#E31B54',
	rose700 = '#C01048',
	rose800 = '#A11043',
	rose900 = '#89123E',

	// Orange
	orange25 = '#FFFAF5',
	orange50 = '#FFF6ED',
	orange100 = '#FFEAD5',
	orange200 = '#FDDCAB',
	orange300 = '#FEB273',
	orange400 = '#FD853A',
	orange500 = '#FB6514',
	orange600 = '#EC4A0A',
	orange700 = '#C4320A',
	orange800 = '#9C2A10',
	orange900 = '#7E2410',

	transparent = 'transparent',

	// Toast colors
	successSecondary = '#ECF9F2',
	warningSecondary = '#FDFAED',
	errorSecondary = '#FAEEEC',
	infoSecondary = '#E2F3FF',
}

export enum ColorFamily {
	grey = 'grey',
	primary = 'primary',
	error = 'error',
	warning = 'warning',
	info = 'info',
	success = 'success',
	bluegrey = 'bluegrey',
	indigo = 'indigo',
	purple = 'purple',
	bluelight = 'bluelight',
	rose = 'rose',
	orange = 'orange',
}

export default Colors;
