import tinyColor from 'tinycolor2';

import { ColorFamily, Colors } from 'theme';
import { ColorKeys } from 'types';

export const displayInPx = (value: string | number): string =>
	typeof value === 'number' ? `${value}px` : value;

// For components where color behaviour is driven by theme
export const getColorByThemeAndGradient = (
	theme: ColorFamily,
	gradient: number,
	colors = Colors
): Colors => {
	const index = `${theme}${gradient}` as ColorKeys;

	return colors[index];
};

export const getColorTextByTheme = (theme: ColorFamily, colors = Colors): Colors => {
	const index = `${theme}600` as ColorKeys;

	const backgroundColor = colors[index];
	const r = parseInt(backgroundColor.substring(1, 3), 16);
	const g = parseInt(backgroundColor.substring(3, 5), 16);
	const b = parseInt(backgroundColor.substring(5, 7), 16);

	// Calculate luminance
	const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

	return luminance < 0.5 ? Colors.white : Colors.grey600;
};

export const convertHexToRGBA = (hexCode: string, opacity = 1): string => {
	let hex = hexCode.replace('#', '');

	if (hex.length === 3) {
		hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
	}

	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	return `rgba(${r},${g},${b},${opacity})`;
};

const colorPaletteRanges = {
	bright: [56, 49, 43, 36, 29, 20, 15],
	dark: [7, 11, 15],
};
const thresholdKey = 600;
const colorPaletteKeys = [25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

export const generateColorPalette = (colorValue: string, key: string) => {
	const color = tinyColor(colorValue);
	if (!color.isValid) return null;

	return colorPaletteKeys.reduce((acc: { [key: string]: string }, paletteKey, index) => {
		// a new instance is created in each loop, since tiny color mutates the color object when you call lighten or darken function of it
		const colorObj = tinyColor(colorValue);
		const colorKey = `${key}${paletteKey}`;
		const brightPalleteLength = colorPaletteRanges.bright.length;

		if (paletteKey === thresholdKey) {
			acc[colorKey] = colorValue;
			return acc;
		}

		acc[colorKey] =
			paletteKey < thresholdKey
				? colorObj.lighten(colorPaletteRanges.bright[index]).toString()
				: colorObj.darken(colorPaletteRanges.dark[index - (brightPalleteLength + 1)]).toString();
		return acc;
	}, {});
};
