import styled from '@emotion/styled';
import React from 'react';

import FlexContainer from '../Container/FlexContainer';
import Text from '../Text';

import { Colors, FontSize } from 'theme';
import { FlexAlignItems, FlexDirection, FlexJustify } from 'theme/layout';
import { fontWeight } from 'theme/typography';

export interface ToastProps {
	text: string;
	subtext: string | null | undefined;
	iconBorder: string;
	iconBg: string;
	bgColor: string;
	children: JSX.Element;
}

const IconContainer = styled(FlexContainer)<{ iconBorder: string }>`
	outline: 4px solid ${(props) => props.iconBorder};
`;

const Toast = (props: ToastProps) => {
	return (
		<FlexContainer bgColor={Colors.transparent} alignItems={FlexAlignItems.center} columnGap={16}>
			<IconContainer
				bgColor={props.iconBg}
				height={24}
				width={24}
				justify={FlexJustify.center}
				alignItems={FlexAlignItems.center}
				iconBorder={props.iconBorder}
				border={{ radius: 12 }}
			>
				{props.children}
			</IconContainer>
			<FlexContainer
				direction={FlexDirection.column}
				rowGap={4}
				bgColor={props.bgColor}
				width={300}
			>
				<Text fontWeight={fontWeight.semiBold} fontSize={FontSize.text_sm} color={Colors.grey500}>
					{props.text}
				</Text>
				<Text fontSize={FontSize.text_xs} color={Colors.grey500} ellipsis>
					{props.subtext}
				</Text>
			</FlexContainer>
		</FlexContainer>
	);
};

export default Toast;
