import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';

import { UserLogin } from 'actions/UserAction';
import { ReactComponent as PrivatePortalImage } from 'assets/images/private_portal.svg';
import LinkButton from 'components/Button/LinkButton';
import FlexContainer from 'components/Container/FlexContainer';
import EmptyState from 'components/EmptyState';
import Text from 'components/Text';
import { EMPTY_STATE_DIMENSIONS, envConfig } from 'constants/constants';
import { GlobalAppContext } from 'helpers/contexts';
import { removeCookie, setPreviousURL } from 'helpers/storage';
import { getPortalSettings } from 'selectors/AppSelector';
import { FontSize, TextAlign, Colors, FontWeight } from 'theme';
import { FlexJustify, FlexAlignItems, FlexDirection } from 'theme/layout';
import { RootState } from 'types/state';

const EmptyStateContainer = styled(EmptyState)`
	height: 100vh;
	width: 100vw;
	place-content: center;
	grid-auto-rows: max-content;
`;

const PrivatePortalLogin: React.FC<ReduxProps> = (props) => {
	const { isMobile, currentScreen } = useContext(GlobalAppContext);
	const location = useLocation();

	const handleLogin = async () => {
		if (!props.settings) return;
		removeCookie('portalAccessToken');
		const { data, error } = await props.login(
			props.settings.workspaceId,
			props.settings.boardUniqueName
		);
		if (error) return;

		// Custom domain doesn't have uniqueName so different logic for that
		const isCustomDomain = window.location.host !== envConfig.portal_url;
		const previousURL = isCustomDomain
			? location.pathname.split('/').splice(2).join('/')
			: location.pathname.split('/').splice(3).join('/');
		setPreviousURL(previousURL);
		window.location.href = data.url;
	};

	return (
		<EmptyStateContainer
			image={PrivatePortalImage}
			label="private_portal_unauthorized_title"
			labelProps={{ fontSize: isMobile ? FontSize.text_md : FontSize.text_xl }}
			{...EMPTY_STATE_DIMENSIONS[currentScreen]}
		>
			<FlexContainer
				margin={{ top: 8 }}
				justify={FlexJustify.center}
				alignItems={FlexAlignItems.center}
				direction={isMobile ? FlexDirection.column : FlexDirection.row}
				padding={{ left: 24, right: 24 }}
				columnGap={4}
				textAlign={TextAlign.center}
			>
				<Text
					translation="private_portal_unauthorized_desc"
					color={Colors.grey500}
					fontSize={FontSize.text_sm}
					fontWeight={FontWeight.semiBold}
				/>
				<LinkButton onClick={handleLogin} size="sm">
					<Text
						translation="login_cta"
						color={Colors.primary600}
						fontSize={FontSize.text_sm}
						fontWeight={FontWeight.semiBold}
					/>
				</LinkButton>
			</FlexContainer>
		</EmptyStateContainer>
	);
};

const mapStateToProps = (state: RootState) => ({
	settings: getPortalSettings(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	login: (id: string, boardUniqueName: string) => dispatch(UserLogin.request(id, boardUniqueName)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PrivatePortalLogin);
