import { ReduxAsyncAction } from 'helpers/action';
import { ANALYTICS_EVENTS } from 'helpers/analytics';
import request from 'helpers/request';
import { ReduxActionWithData } from 'types';
import { ExternalForm } from 'types/app';
import { AddRequestPayload, AddFeedbackCommentPayload, UpvotePayload } from 'types/feedbacks';

export const GetFeedbackFormData = new ReduxAsyncAction('FETCH_FEEDBACK_FORM_DATA');
GetFeedbackFormData.registerRequest(async (formId: string) => {
	const { data } = await request.get(`/form/config/`, {
		params: { id: formId },
	});
	return { data };
});
export const FETCH_FEEDBACK_FORM_DATA = GetFeedbackFormData.constants;
export type ExternalFormDataType = ReduxActionWithData<
	'FETCH_FEEDBACK_FORM_DATA',
	{ data: ExternalForm }
>;

export const GetInitialData = new ReduxAsyncAction('FETCH_INITIAL_DATA');
GetInitialData.registerRequest(async (workspaceId: string, boardUniqueName: string) => {
	const { data } = await request.get(
		`/feedback/initialize/?workspaceId=${workspaceId}&boardUniqueName=${boardUniqueName}`
	);

	return data;
});
export const FETCH_INITIAL_DATA = GetInitialData.constants;

export const FetchAllFeedbacks = new ReduxAsyncAction('FETCH_ALL_FEEDBACKS');
FetchAllFeedbacks.registerRequest(
	async (pageCount = 1, workspaceId: string, query = '', boardUniqueName: string) => {
		const targetApiUrl = query
			? `/feedback/all/${query}&workspaceId=${workspaceId}&page=${pageCount}&count=10&boardUniqueName=${boardUniqueName}`
			: `/feedback/all/?workspaceId=${workspaceId}&page=${pageCount}&count=10&boardUniqueName=${boardUniqueName}`;

		const { data } = await request.get(targetApiUrl);
		return data;
	}
);
export const FETCH_ALL_FEEDBACKS = FetchAllFeedbacks.constants;

export const FetchMoreFeedbacks = new ReduxAsyncAction('FETCH_MORE_FEEDBACKS');
FetchMoreFeedbacks.registerRequest(
	async (pageCount = 1, workspaceId: string, query = '', boardUniqueName: string) => {
		const targetApiUrl = query
			? `/feedback/all/${query}&workspaceId=${workspaceId}&page=${pageCount}&count=10&boardUniqueName=${boardUniqueName}`
			: `/feedback/all/?workspaceId=${workspaceId}&page=${pageCount}&count=10&boardUniqueName=${boardUniqueName}`;

		const { data } = await request.get(targetApiUrl);

		return data;
	}
);
export const FETCH_MORE_FEEDBACKS = FetchMoreFeedbacks.constants;

export const SubmitFeedback = new ReduxAsyncAction('SUBMIT_FEEDBACK');
SubmitFeedback.registerRequest(async (payload: AddRequestPayload) => {
	const {
		title,
		type,
		description,
		attachments,
		customFields,
		source,
		workspaceId,
		name,
		email,
		isLoggedIn,
		boardUniqueName,
		portalUniqueName,
	} = payload;
	const reqBody: { [key: string]: string } = {
		title: title.trim(),
		typeId: type,
		description,
		source,
		workspaceId,
		name: name ?? '',
		email: email ?? '',
	};
	const body = new FormData();

	Object.keys(reqBody).forEach((key) => {
		// skip empty values coming from optional fields
		if (reqBody[key]) {
			body.append(key, reqBody[key]);
		}
	});
	attachments.forEach((attachment) => {
		body.append('files', attachment);
	});
	body.append(`customFields`, JSON.stringify(customFields));
	const { data } = await request.post(
		`/feedback/?boardUniqueName=${boardUniqueName}&name=${portalUniqueName}`,
		body
	);

	(window as WindowExtended).zedaAnalytics.track(ANALYTICS_EVENTS.CREATE_FEEDBACK, { type });

	return { ...data, isLoggedIn };
});
export const SUBMIT_FEEDBACK = SubmitFeedback.constants;

export const FetchFeedbackDetail = new ReduxAsyncAction('FETCH_FEEDBACK_DETAIL');
FetchFeedbackDetail.registerRequest(
	async (body: { feedbackId: string; workspaceId: string; boardUniqueName: string }) => {
		const { feedbackId, workspaceId, boardUniqueName } = body;
		const { data } = await request.get(
			`/feedback/${feedbackId}/?workspaceId=${workspaceId}&boardUniqueName=${boardUniqueName}`
		);

		return data;
	}
);
export const FETCH_FEEDBACK_DETAIL = FetchFeedbackDetail.constants;

export const VoteFeedback = new ReduxAsyncAction('VOTE_FEEDBACK');
VoteFeedback.registerRequest(async (body: UpvotePayload) => {
	const { source, boardUniqueName, portalUniqueName, ...rest } = body;
	const { data } = await request.post(
		`/feedback/vote/?boardUniqueName=${boardUniqueName}&name=${portalUniqueName}`,
		rest
	);

	if (body.upvote) {
		(window as WindowExtended).zedaAnalytics.track(ANALYTICS_EVENTS.UPVOTE_FEEDBACK, {
			feedbackId: body.feedbackId,
		});
	}

	return { ...data, source, upvote: rest.upvote };
});
export const VOTE_FEEDBACK = VoteFeedback.constants;

/*
 * Fetch initial board data for roadmap
 */
export const FetchInitialBoard = new ReduxAsyncAction('FETCH_INITIAL_BOARD');
FetchInitialBoard.registerRequest(
	async (body: {
		workspaceId: string;
		typeId?: string;
		search?: string;
		boardUniqueName: string;
	}) => {
		const { data } = await request.get('/feedback/board-view', { params: body });
		return { data: data.data[0] };
	}
);
export const FETCH_INITIAL_BOARD = FetchInitialBoard.constants;

/*
 * Fetch subsequent board data for specific boards
 */
export const FetchBoardData = new ReduxAsyncAction('FETCH_BOARD_DATA');
FetchBoardData.registerRequest(
	async (body: {
		workspaceId: string;
		boardUniqueName: string;
		pageNumber: number;
		statusId: string;
		typeId?: string;
		search?: string;
	}) => {
		const { data } = await request.get('/feedback/board-view', {
			params: { ...body, pageCount: 10 },
		});
		return { data: data.data[0], statusId: body.statusId };
	}
);
export const FETCH_BOARD_DATA = FetchBoardData.constants;

export const AddFeedbackComment = new ReduxAsyncAction('ADD_FEEDBACK_COMMENT');
AddFeedbackComment.registerRequest(async (body: AddFeedbackCommentPayload) => {
	const {
		text,
		feedbackId,
		files,
		workspaceId,
		parentCommentId,
		name,
		email,
		boardUniqueName,
		portalUniqueName,
	} = body;
	const reqBody = {
		...(Boolean(parentCommentId) && { parentCommentId }),
		text,
		feedbackId,
		workspaceId,
		email,
		name,
	};

	const dataBody = new FormData();
	(Object.keys(reqBody) as Array<keyof typeof reqBody>).forEach((key) => {
		dataBody.append(key, reqBody[key] as string);
	});
	files.forEach((attachment) => {
		dataBody.append('files', attachment);
	});
	const { data } = await request.post(
		`/feedback/comment/?boardUniqueName=${boardUniqueName}&name=${portalUniqueName}`,
		dataBody
	);

	(window as WindowExtended).zedaAnalytics.track(ANALYTICS_EVENTS.COMMENT_FEEDBACK, { feedbackId });

	return data;
});
export const ADD_FEEDBACK_COMMENT = AddFeedbackComment.constants;

/**
 * Clear roadmap data
 */
export const ResetBoardView = new ReduxAsyncAction('RESET_BOARD_VIEW_DATA');
ResetBoardView.registerRequest(async () => {});
export const RESET_BOARD_VIEW_DATA = ResetBoardView.constants;

export const PostFeedbackForm = new ReduxAsyncAction('POST_FEEDBACK_FORM');
PostFeedbackForm.registerRequest(
	async (payload: Omit<AddRequestPayload, 'boardUniqueName' | 'portalUniqueName'>) => {
		const {
			title,
			type,
			description,
			attachments,
			customFields,
			source,
			workspaceId,
			name,
			email,
			isLoggedIn,
		} = payload;
		const reqBody: { [key: string]: string } = {
			title: title.trim(),
			typeId: type,
			description,
			source,
			workspaceId,
			name: name ?? '',
			email: email ?? '',
		};
		const body = new FormData();

		Object.keys(reqBody).forEach((key) => {
			// skip empty values coming from optional fields
			if (reqBody[key]) {
				body.append(key, reqBody[key]);
			}
		});
		attachments.forEach((attachment) => {
			body.append('files', attachment);
		});
		body.append(`customFields`, JSON.stringify(customFields));
		const { data } = await request.post('/form', body);

		return { ...data, isLoggedIn };
	}
);
export const POST_FEEDBACK_FORM = PostFeedbackForm.constants;
