import styled from '@emotion/styled';
import React, { useContext } from 'react';

import { ReactComponent as SomethingWentWrongImage } from 'assets/images/something_went_wrong.svg';
import LinkButton from 'components/Button/LinkButton';
import FlexContainer from 'components/Container/FlexContainer';
import EmptyState from 'components/EmptyState';
import Text from 'components/Text';
import { EMPTY_STATE_DIMENSIONS } from 'constants/constants';
import { GlobalAppContext } from 'helpers/contexts';
import { FontSize, TextAlign, Colors, FontWeight } from 'theme';
import { FlexJustify, FlexAlignItems, FlexDirection } from 'theme/layout';

const EmptyStateContainer = styled(EmptyState)`
	height: 100vh;
	width: 100vw;
	place-content: center;
	grid-auto-rows: max-content;
`;

const SomethingWentWrong = () => {
	const { isMobile, currentScreen } = useContext(GlobalAppContext);

	return (
		<EmptyStateContainer
			image={SomethingWentWrongImage}
			label="something_went_wrong"
			labelProps={{ fontSize: isMobile ? FontSize.text_md : FontSize.text_xl }}
			{...EMPTY_STATE_DIMENSIONS[currentScreen]}
		>
			<FlexContainer
				margin={{ top: 8 }}
				justify={FlexJustify.center}
				alignItems={FlexAlignItems.center}
				direction={isMobile ? FlexDirection.column : FlexDirection.row}
				padding={{ left: 24, right: 24 }}
				columnGap={4}
				textAlign={TextAlign.center}
			>
				<Text
					translation="something_went_wrong_desc"
					color={Colors.grey500}
					fontSize={FontSize.text_sm}
					fontWeight={FontWeight.semiBold}
				/>
				<LinkButton onClick={() => window.location.reload()} size="sm">
					<Text
						translation="retry_cta"
						color={Colors.primary600}
						fontSize={FontSize.text_sm}
						fontWeight={FontWeight.semiBold}
					/>
				</LinkButton>
			</FlexContainer>
		</EmptyStateContainer>
	);
};

export default SomethingWentWrong;
