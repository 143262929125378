import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

import { LinkButtonProps, ButtonProps } from './Button';

import Button from '.';

export const StyledLinkButton = styled(Button)<ButtonProps>`
	padding: 0;
	height: max-content;

	.btn-text {
		line-height: 1.5;
	}

	:hover {
		${({ theme }) => `background-color: ${theme.colors?.white}`};
	}

	// Anchor tag doesn't have disabled state. So handling disabled state manually
	${({ disabled, theme }) =>
		disabled &&
		`
		pointer-events: none;

		.btn-text {
			color: ${theme.colors.grey300};
		}
	`}
`;

const LinkButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
	const { isExternal = false, url, target, ...rest } = props;

	let LinkComponent: LinkButtonProps['htmlElement'] = 'button';
	let linkBtnProps = {};

	if (url) {
		linkBtnProps = isExternal ? { href: url, target } : { to: url };
		LinkComponent = isExternal ? 'a' : Link;
	}

	return (
		<StyledLinkButton {...linkBtnProps} {...rest} variant="tertiary" htmlElement={LinkComponent} />
	);
};

export default LinkButton;
