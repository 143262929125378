import React from 'react';

import DefaultMultiSelect from 'components/MultiSelect/default';
import { BaseMultiSelectProps, BasicOption } from 'components/MultiSelect/MultiSelect';
import Text from 'components/Text';
import { Colors, FontSize, FontWeight } from 'theme';

type DefaultMultiSelectProp = {
	options: Omit<BasicOption, 'element'>[];
} & BaseMultiSelectProps;

const MultiSelect = (props: DefaultMultiSelectProp) => {
	const { placeholder, ...rest } = props;

	const options = props.options.map((option) => ({
		...option,
		element: (
			<Text
				color={option.disabled ? Colors.grey200 : Colors[option.itemProps?.color ?? 'grey900']}
				fontSize={FontSize[option.itemProps?.fontSize ?? 'text_md']}
				fontWeight={FontWeight[option.itemProps?.fontWeight ?? 'regular']}
				ellipsis
			>
				{option.label}
			</Text>
		),
	}));

	const placeholderComp = (
		<Text
			color={Colors.grey500}
			fontSize={FontSize[props.fontSize ?? 'text_md']}
			fontWeight={FontWeight.regular}
			ellipsis
		>
			{placeholder ?? 'Multi Select'}
		</Text>
	);

	return <DefaultMultiSelect {...rest} options={options} placeholder={placeholderComp} />;
};

export default MultiSelect;
