import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import ReduxThunk from 'redux-thunk';

import AppReducer from './AppReducer';
import FeedbackReducer from './FeedbackReducer';
import ReleaseNoteReducer from './ReleaseNotesReducer';
import UserReducer from './UserReducer';

export const reducers = {
	appReducer: AppReducer,
	feedbackReducer: FeedbackReducer,
	releaseNoteReducer: ReleaseNoteReducer,
	userReducer: UserReducer,
};

const rootReducer = combineReducers(reducers);
const composeEnhancers = (window as WindowExtended).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(ReduxThunk)));

export default store;
