import React from 'react';

import { ReactComponent as ZedaIcon } from 'assets/images/zeda.svg';
import FlexContainer, { FlexProps } from 'components/Container/FlexContainer';
import Text from 'components/Text';
import { openInNewTab } from 'helpers/utils';
import { Colors, FontSize, FontWeight } from 'theme';
import { FlexAlignItems, FlexJustify } from 'theme/layout';

const ZedaBranding = (props: FlexProps) => {
	return (
		<FlexContainer
			cursor="pointer"
			padding={{ top: 8, bottom: 8, left: 16, right: 16 }}
			bgColor={Colors.grey25}
			justify={FlexJustify.center}
			alignItems={FlexAlignItems.center}
			border={{ radius: 4 }}
			margin={{ top: 8 }}
			onClick={() => openInNewTab('https://zeda.io')}
			{...props}
		>
			<Text
				fontWeight={FontWeight.semiBold}
				margin={{ right: 8 }}
				color={Colors.grey500}
				translation="powered_by"
				fontSize={FontSize.text_sm}
			/>
			<ZedaIcon />
		</FlexContainer>
	);
};

export default ZedaBranding;
