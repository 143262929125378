import { FormikProps } from 'formik';
import React from 'react';
import { ConnectedProps } from 'react-redux';
import { ArrowSmLeftIcon } from 'zeda-icons/v3';

import Body from './Body';
import { FormikValueType, FormContainer } from './FeedbackForm';

import { ReactComponent as FeedbackSubmitted } from 'assets/images/FeedbackSubmitted.svg';
import Button from 'components/Button';
import LinkButton from 'components/Button/LinkButton';
import Container from 'components/Container/default';
import FlexContainer from 'components/Container/FlexContainer';
import Text from 'components/Text';
import ZedaBranding from 'components/ZedaBranding';
import { useDeviceDimensions } from 'hooks';
import { PropsWithRouter, withRouter } from 'routes/WithRouterComponent';
import connector from 'Screens/Feedback/Feedback';
import { ColorFamily, Colors, FontSize, FontWeight, Overflow, TextAlign } from 'theme';
import { FlexAlignItems, FlexDirection, FlexJustify } from 'theme/layout';

interface Props extends ReduxProps, PropsWithRouter {
	formik: FormikProps<FormikValueType>;
	attachments: File[];
	setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
	isSubmitted: boolean;
	setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
	toggle: () => void;
	width?: number | string;
	isExternalForm?: boolean;
	descriptionId?: string;
	isTransparent?: boolean;
}

const FeedbackForm = (props: Props) => {
	const {
		formik,
		attachments,
		setAttachments,
		isSubmitted: isFeedbackSubmitted,
		isExternalForm = false,
	} = props;

	const { isMobile, isDesktop } = useDeviceDimensions();

	const onClose = () => {
		formik.resetForm();
		props.setIsSubmitted(false);
	};

	const handleGoBack = () => {
		props.toggle();
	};

	const ExternalFormHeading = (
		<FlexContainer
			direction={FlexDirection.column}
			alignItems={FlexAlignItems.center}
			bgColor={Colors.transparent}
			border={{ bottom: 1, color: Colors.grey200 }}
		>
			<Text
				fontSize={FontSize.text_xl}
				color={Colors.grey900}
				textAlign={TextAlign.center}
				translation="give_us_feedback"
			/>
			<Text
				fontSize={FontSize.text_sm}
				color={Colors.grey500}
				fontWeight={FontWeight.regular}
				textAlign={TextAlign.center}
				translation="give_feedback_subtitle"
				display="block"
				margin={{ bottom: 8 }}
			/>
		</FlexContainer>
	);

	const FormBody = (
		<Body
			descriptionId={props.descriptionId}
			formik={formik}
			attachments={attachments}
			setAttachments={setAttachments}
			isValid={formik.isValid && formik.dirty}
			isExternalForm={isExternalForm ?? false}
		/>
	);

	const GoBackButton = isMobile && !isExternalForm && (
		<LinkButton
			onClick={handleGoBack}
			leadingIcon={ArrowSmLeftIcon}
			translation="back_text"
			colorTheme={ColorFamily.grey}
		/>
	);

	return (
		<Container
			padding={{ all: 16 }}
			bgColor={isDesktop && !props.isTransparent ? Colors.grey50 : Colors.transparent}
			height="max-content"
			border={{ radius: 8 }}
			{...(isMobile
				? { width: isExternalForm ? 350 : '100%' }
				: { width: props?.width ? props.width : 344 })}
			{...(!isExternalForm && {
				maxHeight: isDesktop ? 'min(calc(100vh - 120px), 772px)' : 'max-content',
				overflow: Overflow.auto,
			})}
		>
			{isFeedbackSubmitted ? (
				<FlexContainer
					direction={FlexDirection.column}
					justify={FlexJustify.center}
					alignItems={FlexAlignItems.center}
					bgColor={Colors.transparent}
					margin={{ bottom: 16 }}
				>
					<FeedbackSubmitted />
					<Text translation="feedback_submitted" textAlign={TextAlign.center} />
					<Text
						translation="feedback_submitted_subtext"
						textAlign={TextAlign.center}
						fontSize={FontSize.text_sm}
						fontWeight={FontWeight.regular}
						margin={{ top: 8, bottom: 8 }}
					/>
					<Button variant="primary" size="sm" onClick={onClose} translation="submit_another" />
				</FlexContainer>
			) : (
				<>
					{GoBackButton}
					{isExternalForm ? (
						// portal external feedback form heading
						ExternalFormHeading
					) : (
						// portal feedback form heading
						<Container bgColor={Colors.transparent} {...(!isDesktop && { margin: { top: 16 } })}>
							<Text
								translation="give_us_feedback"
								fontSize={FontSize.text_md}
								lineHeight="24px"
								margin={{ bottom: 8 }}
								htmlTagName="div"
							/>
							<Text
								translation="give_feedback_subtitle"
								fontSize={FontSize.text_sm}
								lineHeight="20px"
								fontWeight={FontWeight.regular}
							/>
						</Container>
					)}
					<FormContainer formik={formik}>{FormBody}</FormContainer>
				</>
			)}
			{!isExternalForm && props.portalSettings.theme.showZedaLogo && <ZedaBranding />}
		</Container>
	);
};

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withRouter(FeedbackForm));
