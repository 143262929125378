import styled from '@emotion/styled';
import { ArrowSmLeftIcon } from 'zeda-icons/v3';

import Input from '../Input';

import { RichCalendarOptions, RichOptions } from './calendarTypes';

import Container from 'components/Container/default';
import FlexContainer from 'components/Container/FlexContainer';
import strings from 'constants/translation';
import {
	getCurrentMonth,
	getCurrentQuarter,
	getCurrentWeek,
	getNextQuarter,
	getNextToNextQuarter,
	getSixMonthsFromCurrentDate,
	getTwoWeeksFromCurrentDate,
} from 'helpers/date';
import { Colors, FontSize, FontWeight } from 'theme';
import { fontWeight } from 'theme/typography';

export const CalendarContainer = styled(Container)`
	.react-calendar {
		border: none !important;
		width: 100% !important;
	}
	.react-calendar__navigation {
		margin-bottom: 4px;
		position: relative !important;
		align-items: center;
		height: 24px;
	}
	.react-calendar__navigation__arrow {
		background-color: transparent !important;
		border: 1px solid ${Colors.grey300};
		padding: 0 !important;
		height: fit-content;
		width: fit-content;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;

		> svg {
			> path {
				stroke: ${Colors.grey700};
			}
		}
	}
	.react-calendar__navigation__arrow[disabled] {
		background-color: transparent !important;
		> svg {
			> path {
				stroke: transparent;
			}
		}
	}
	.react-calendar__navigation__label {
		font-weight: ${FontWeight.semiBold};
		font-size: ${FontSize.text_md};
		line-height: 16px;
		height: 24px;
		color: ${Colors.grey900};
		position: absolute;
		left: 0;
		&:hover,
		:active,
		:focus {
			background-color: transparent !important;
		}
	}

	.react-calendar__navigation button {
		min-width: fit-content;
	}

	.react-calendar__navigation__next-button,
	.react-calendar__navigation__prev-button {
		position: absolute;
		transform: rotate(90deg);
		width: 24px;
		height: 24px;
	}

	.react-calendar__navigation__next-button {
		right: 10px;
	}

	.react-calendar__navigation__prev-button {
		right: 48px;
	}

	.react-calendar__tile--now {
		border-radius: 50%;
		background-color: ${Colors.transparent};

		> abbr {
			display: flex;
			height: 40px;
			width: 40px;
			border-radius: 50%;
			border: 2px solid ${Colors.primary700};
			justify-content: center;
			align-items: center;
		}

		:hover {
			border-radius: 50%;
			background-color: ${Colors.primary700} !important;
			> abbr {
				color: ${Colors.white} !important;
			}
		}
	}

	.react-calendar__month-view__days {
		cursor: default;
		row-gap: 4px;
		.react-calendar__month-view__days__day--neighboringMonth {
			> abbr {
				color: ${Colors.grey300} !important;
			}
		}

		.react-calendar__month-view__days__day {
			flex: 0 0 1 !important;
			padding: 0px;
			height: 40px;
			width: 40px !important;
			background-color: white;
			opacity: 0.8;

			> abbr {
				font-weight: ${FontWeight.regular};
				color: ${Colors.grey900};
				font-size: ${FontSize.text_xs};
			}
			&:hover {
				background-color: ${Colors.grey50};
				border-radius: 50%;
			}
			&[disabled] {
				background-color: ${Colors.grey25};
				&:nth-of-type(7n + 1) {
					border-top-left-radius: 50%;
					border-bottom-left-radius: 50%;
				}
				&:nth-of-type(7n) {
					border-top-right-radius: 50%;
					border-bottom-right-radius: 50%;
				}
				> abbr {
					color: ${Colors.grey300};
				}
			}

			&.react-calendar__tile--hasActive {
				background-color: ${Colors.primary600};
				border-radius: 50%;
				> abbr {
					color: ${Colors.white};
				}
			}

			&.react-calendar__tile--active {
				background-color: ${Colors.primary600};
				> abbr {
					color: ${Colors.white};
				}
				&:hover {
					background-color: ${Colors.primary600};
					> abbr {
						color: ${Colors.white};
					}
				}
			}
			&.react-calendar__tile--range {
				border-radius: 0px;
				background-color: ${Colors.grey100};
				:nth-of-type(7n) {
					border-top-right-radius: 50%;
					border-bottom-right-radius: 50%;
				}
				:nth-of-type(7n + 1) {
					border-top-left-radius: 50%;
					border-bottom-left-radius: 50%;
				}
				> abbr {
					color: ${Colors.grey900};
					display: flex;
					height: 40px;
					width: 40px;
					border-radius: 50%;
					justify-content: center;
					align-items: center;
				}
				:hover {
					background-color: ${Colors.grey100};

					> abbr {
						background-color: ${Colors.grey200};
						color: ${Colors.grey900};
					}
				}
				&.react-calendar__tile--rangeStart {
					border-top-left-radius: 50%;
					border-bottom-left-radius: 50%;
					background-color: ${Colors.grey100};
					height: 40px;
					width: 40px !important;

					> abbr {
						color: ${Colors.white};
						background-color: ${Colors.primary600};
						display: flex;
						height: 40px;
						width: 40px;
						border-radius: 50%;
						justify-content: center;
						align-items: center;
					}

					&:hover {
						> abbr {
							background-color: ${Colors.primary900};
							color: ${Colors.white};
						}
					}
				}
				&.react-calendar__tile--rangeEnd {
					border-top-right-radius: 50%;
					border-bottom-right-radius: 50%;
					background-color: ${Colors.grey100};
					height: 40px;
					width: 40px !important;
					> abbr {
						color: ${Colors.white};
						background-color: ${Colors.primary600};
						display: flex;
						height: 40px;
						width: 40px;
						border-radius: 50%;
						justify-content: center;
						align-items: center;
					}

					&:hover {
						> abbr {
							background-color: ${Colors.primary900};
							color: ${Colors.white};
						}
					}
				}
				&.react-calendar__tile--now {
					background-color: ${Colors.grey100} !important;

					> abbr {
						display: flex;
						height: 40px;
						width: 40px;
						border-radius: 50%;
						justify-content: center;
						align-items: center;
					}

					:hover {
						> abbr {
							background-color: ${Colors.grey200};
							color: ${Colors.grey900} !important;
						}
					}
				}
			}
		}
	}
	.react-calendar__month-view__weekdays {
		height: 40px;
		align-items: center;
		.react-calendar__month-view__weekdays__weekday {
			font-weight: ${FontWeight.semiBold};
			color: ${Colors.grey700};
			font-size: ${FontSize.text_sm};
			text-transform: none;
			opacity: 0.8;

			> abbr {
				text-decoration: none;
			}
		}
	}
`;

export const RangeContainer = styled(FlexContainer)`
	display: flex;
	justify-content: space-between;
	column-gap: 48px;
	margin: 0;
	cursor: auto;
	height: 88px;
	padding: 0;
`;

export const StyledItem = styled(FlexContainer)<{ isSelected: boolean }>`
	box-sizing: border-box;
	:hover {
		:not(:disabled) {
			text-decoration-line: none;
		}
		background-color: ${({ isSelected }) =>
			isSelected ? Colors.grey100 : Colors.grey50} !important;
	}
`;

export const StyledArrowIcon = styled(ArrowSmLeftIcon)`
	transform: rotate(180deg);
	position: absolute;
	top: 65%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(180deg);
`;

export const StyledInput = styled(Input)`
	font-size: 16px;
	font-weight: ${fontWeight.regular};

	> input {
		width: 100%;
	}
`;

export const StyledFlexContainer = styled(FlexContainer)`
	box-sizing: border-box;
`;

export const richCalendarOptions: RichCalendarOptions = {
	[RichOptions.THIS_WEEK]: {
		label: strings.this_week,
		value: getCurrentWeek(),
	},
	[RichOptions.TWO_WEEKS_FROM_NOW]: {
		label: strings.two_weeks_from_current_date,
		value: getTwoWeeksFromCurrentDate(),
	},
	[RichOptions.THIS_MONTH]: {
		label: strings.this_month,
		value: getCurrentMonth(),
	},
	[RichOptions.THIS_QUARTER]: {
		label: strings.this_quarter,
		value: getCurrentQuarter(),
	},
	[RichOptions.NEXT_QUARTER]: {
		label: strings.next_quarter,
		value: getNextQuarter(),
	},
	[RichOptions.NEXT_TO_NEXT_QUARTER]: {
		label: strings.next_to_next_quarter,
		value: getNextToNextQuarter(),
	},
	[RichOptions.SIX_MONTHS_FROM_NOW]: {
		label: strings.six_months_from_current_date,
		value: getSixMonthsFromCurrentDate(),
	},
};
