import styled from '@emotion/styled';
import React from 'react';
import { LinkProps } from 'react-router-dom';

import { displayInPx, getColorByThemeAndGradient, getColorTextByTheme } from 'helpers/theme';
import { ColorFamily, TextAlign, FontWeight, FontSize, Colors } from 'theme';
import { FlexAlignSelf, FlexJustify } from 'theme/layout';
import { Border, Flex, Grid, Margin, Padding, Position } from 'theme/mixins';
import { BorderModel, Box, PositionModel } from 'theme/types';
import { ColorKeys, FontSizeKeys, FontWeightkeys, Translation } from 'types';

export type ButtonVariants = 'primary' | 'secondary' | 'tertiary';

export type ButtonSizes = 'xxs' | 'xs' | 'sm' | 'md' | 'lg';

export const buttonHeights: { [key in ButtonSizes]: number } = {
	xxs: 16,
	xs: 24,
	sm: 32,
	md: 40,
	lg: 48,
};

export const iconSizes: { [key in ButtonSizes]: number } = {
	xxs: 12,
	xs: 16,
	sm: 20,
	md: 24,
	lg: 28,
};

export const buttonBorderRadius: { [key in ButtonSizes]: number } = {
	xxs: 4,
	xs: 4,
	sm: 6,
	md: 8,
	lg: 10,
};

export const buttonBlockPadding: { [key in ButtonSizes]: number } = {
	xxs: 1,
	xs: 3,
	sm: 5,
	md: 7,
	lg: 9,
};

export interface ButtonBaseProps {
	/**
	 * Margin for button
	 */
	margin?: Partial<Box>;
	/**
	 * Additional CSS class
	 */
	className?: string;
	/**
	 * To disable the button
	 */
	disabled?: boolean;
	/**
	 * Element to be rendered inside button
	 */
	children?: React.ReactNode;
	/**
	 * Button click event
	 */
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Unique id for the button
	 */
	id?: string;
	/**
	 * Alignment of text within button
	 */
	textAlign?: TextAlign;
	/**
	 * HTML name attribute
	 */
	name?: string;
	/**
	 * Width of the Button
	 */
	width?: string | number;
	/**
	 * Minimum width of button
	 */
	minWidth?: number | string;
	/**
	 * Set how button is positioned in DOM
	 */
	position?: PositionModel;
	/**
	 * Flex basis - Only applied if parent is a flex container
	 */
	flexBasis?: string | number;
	/**
	 * Flex grow - Only applied if parent is a flex container
	 */
	flexGrow?: number;
	/**
	 * Flex shrink - Only applied if parent is a flex container
	 */
	flexShrink?: number;
	/**
	 * Flex align self - Only applied if parent is a flex container
	 */
	alignSelf?: FlexAlignSelf;
	/**
	 * Flex justify self - Only applied if parent is a flex container
	 */
	justifySelf?: FlexJustify;
	/**
	 * Grid row start position - Only applied if parent is a grid container
	 */
	gridRowStart?: number;
	/**
	 * Grid row end position - Only applied if parent is a grid container
	 */
	gridRowEnd?: number;
	/**
	 * Grid column start position - Only applied if parent is a grid container
	 */
	gridColumnStart?: number;
	/**
	 * Grid column end position - Only applied if parent is a grid container
	 */
	gridColumnEnd?: number;
	/**
	 * Html element to render
	 */
	htmlElement?:
		| 'a'
		| 'button'
		| React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>;
	/**
	 * Border for button
	 */
	border?: BorderModel;
}
export interface ButtonProps extends ButtonBaseProps {
	/**
	 * Variant of button
	 */
	variant: ButtonVariants;
	/**
	 * Text to be rendered inside button
	 */
	translation?: Translation;
	/**
	 * Color theme for the button
	 */
	colorTheme?: ColorFamily;
	/**
	 * Button size
	 */
	size?: ButtonSizes;
	/**
	 * Leading icon
	 */
	leadingIcon?: React.ComponentType<IconPropType>;
	/**
	 * Trailing icon
	 */
	trailingIcon?: React.ComponentType<IconPropType>;
}

export interface DefaultButtonProps extends ButtonBaseProps {
	/**
	 * Type of button
	 */
	type: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
	/**
	 * Background color for the button
	 */
	bgColor?: ColorKeys;
	/**
	 * Height of the Button
	 */
	height?: string | number;
	/**
	 * Minimum height of the Button
	 */
	minHeight?: string | number;
	/**
	 * Padding for button
	 */
	padding?: Partial<Box>;
	/**
	 * Font size for button children
	 */
	fontSize?: FontSizeKeys;
	/**
	 * Font weight for button children
	 */
	fontWeight?: FontWeightkeys;
	/**
	 * Color for button children
	 */
	color?: ColorKeys;
	/**
	 * Title for text
	 */
	title?: string;
}

export interface LinkButtonProps extends Omit<ButtonProps, 'variant'> {
	/**
	 * Should redirect to external webpage
	 */
	isExternal?: boolean;
	/**
	 * Target URL for redirection
	 */
	url?: string;
	/**
	 * Target for opening URL
	 */
	target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target'];
}

export interface IconButtonProps
	extends Omit<ButtonProps, 'translation' | 'trailingIcon' | 'leadingIcon'> {
	/**
	 * Icon to be displayed
	 */
	icon: React.ComponentType<IconPropType>;
}

export const DefaultButton = styled.button<DefaultButtonProps>`
	outline: none;
	border: 1px solid transparent;
	${({ color, theme }) => color && `color: ${theme.colors[color]};`}
	${({ fontWeight }) => fontWeight && `font-weight: ${FontWeight[fontWeight]};`}
	${({ fontSize }) => fontSize && `font-size: ${FontSize[fontSize]};`}
	${({ bgColor, theme }) => bgColor && `background-color: ${theme.colors[bgColor]};`}
	${({ height }) => height && `height: ${displayInPx(height)};`}
	${({ width }) => width && `width: ${displayInPx(width)};`}
	${({ minWidth }) => minWidth && `min-width: ${displayInPx(minWidth)};`}
	${({ minHeight }) => minHeight && `min-height: ${displayInPx(minHeight)};`}
	${Border}
	${Margin}
	${Padding}
	${Position}
	${Flex}
	${Grid}
`;

export const getButtonStyles = (
	variant: ButtonVariants,
	theme: ColorFamily,
	colorsList = Colors
): string => {
	switch (variant) {
		case 'primary':
			return `
				border: 1px solid transparent;
				background-color: ${getColorByThemeAndGradient(theme, 600, colorsList)};

				:hover {
					background-color: ${getColorByThemeAndGradient(theme, 900, colorsList)};
				}

				:focus {
					background-color: ${getColorByThemeAndGradient(theme, 900, colorsList)};
					box-shadow: 0 0 0 4px ${getColorByThemeAndGradient(theme, 50, colorsList)};
				}

				:disabled {
					background-color: ${getColorByThemeAndGradient(theme, 200, colorsList)};
					pointer-events: none;
				}

				.btn-text {
					color: ${getColorTextByTheme(theme, colorsList)};
				}

				.btn-icon {
					fill: ${getColorTextByTheme(theme, colorsList)};
				}
			`;
		case 'secondary':
			return `
				border: 1px solid ${getColorByThemeAndGradient(theme, 300, colorsList)};
				background-color: ${colorsList.white};

				.btn-text {
					color: ${getColorByThemeAndGradient(theme, 700, colorsList)};
				}

				.btn-icon {
					fill: ${getColorByThemeAndGradient(theme, 700, colorsList)};
				}

				:hover {
					background-color: ${getColorByThemeAndGradient(theme, 50, colorsList)};

					.btn-text {
						color: ${getColorByThemeAndGradient(theme, 900, colorsList)};
					}

					.btn-icon {
						fill: ${getColorByThemeAndGradient(theme, 900, colorsList)};
					}
				}

				:focus {
					background-color: ${getColorByThemeAndGradient(theme, 50, colorsList)};
					box-shadow: 0 0 0 4px ${getColorByThemeAndGradient(theme, 100, colorsList)};

					.btn-text {
						color: ${getColorByThemeAndGradient(theme, 700)};
					}

					.btn-icon {
						fill: ${getColorByThemeAndGradient(theme, 700, colorsList)};
					}
				}


				:disabled {
					pointer-events: none;
					.btn-text {
						color: ${getColorByThemeAndGradient(theme, 300, colorsList)};
					}

					.btn-icon {
						fill: ${getColorByThemeAndGradient(theme, 300, colorsList)};
					}
				}
			`;
		case 'tertiary':
			return `
				border: 1px solid transparent;
				background-color: ${colorsList.white};

				.btn-text {
					color: ${getColorByThemeAndGradient(theme, 700, colorsList)};
				}
				
				.btn-icon {
					fill: ${getColorByThemeAndGradient(theme, 700, colorsList)};
				}

				:hover {
					background-color: ${getColorByThemeAndGradient(theme, 50, colorsList)};

					.btn-text {
						color: ${getColorByThemeAndGradient(theme, 900, colorsList)};
					}

					.btn-icon {
						fill: ${getColorByThemeAndGradient(theme, 900, colorsList)};
					}
				}

				:focus {
					.btn-text {
						color: ${getColorByThemeAndGradient(theme, 700, colorsList)};
					}

					.btn-icon {
						fill: ${getColorByThemeAndGradient(theme, 700, colorsList)};
					}
				}


				:disabled {
					pointer-events: none;
					.btn-text {
						color: ${getColorByThemeAndGradient(theme, 300, colorsList)};
					}

					.btn-icon {
						fill: ${getColorByThemeAndGradient(theme, 300, colorsList)};
					}
				}
			`;
		default:
			return '';
	}
};
