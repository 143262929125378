import { getIn } from 'formik';
import React, { useContext, useRef, useState } from 'react';
import { ChevronDownIcon } from 'zeda-icons/v3';

import GridContainer from '../Container/GridContainer';
import SearchInput from '../Input/SearchInput';

import FlexContainer from 'components/Container/FlexContainer';
import { StyledDropdownMenu } from 'components/Dropdown';
import SelectDropdownItem from 'components/Dropdown/DropdownItem';
import {
	BaseMultiSelectProps,
	DefaultDropdownToggleStylingProps,
	MultiSelectDropdownToggle,
	StyledDropdown,
} from 'components/MultiSelect/MultiSelect';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip/default';
import { FormikContext } from 'helpers/contexts';
import { getRandomInt } from 'helpers/utils';
import useToggle from 'hooks/useToggle';
import { Colors, FontSize, FontWeight, Overflow } from 'theme';
import { FlexAlignItems, FlexJustify, Position, Space } from 'theme/layout';

// const DEFAULT_WIDTH = 320;

const DefaultMultiSelect = (props: BaseMultiSelectProps) => {
	const [openMenu, toggleMenu] = useToggle(false);
	const [randomId] = useState<number>(getRandomInt(100));
	const [search, setSearch] = useState<string>('');

	const clearSearch = () => setSearch('');

	const valueContainerRef = useRef<HTMLInputElement | null>(null);

	const {
		children,
		disable,
		className,
		placeholder,
		values = [],
		onChange,
		options,
		label,
		toggleButton,
		container,
		width,
		margin,
		position = { value: Position.relative },
		flexGrow,
		flexShrink,
		flexBasis,
		gridRowStart,
		gridColumnStart,
		gridColumnEnd,
		gridRowEnd,
		showSelectedBackground = true,
		...rest
	} = props;
	const { formik }: { formik: any } = useContext(FormikContext);
	const isInvalid =
		!!rest.name && getIn(formik?.errors, rest.name) && getIn(formik?.touched, rest.name);

	const selectedCountToShow = (values?.length ?? 0) > 2 ? (values?.length ?? 0) - 2 : 0;

	const selectedOptionsToShow = options.filter((option) =>
		values?.some((value) => option.value === value)
	);
	const hiddenOptions = selectedOptionsToShow.splice(
		selectedOptionsToShow.length - selectedCountToShow,
		selectedCountToShow
	);

	const selectedValues = selectedOptionsToShow.map((selectedOption) => (
		<FlexContainer
			alignItems={FlexAlignItems.center}
			{...(showSelectedBackground && {
				maxWidth: 115,
				border: { radius: 4 },
				bgColor: Colors.grey100,
				padding: { top: 0, bottom: 0, right: 4, left: 4 },
			})}
		>
			{selectedOption?.element ?? (
				<Text
					{...(rest.color && { color: Colors[rest.color] })}
					{...(rest.fontSize && { fontSize: FontSize[rest.fontSize] })}
					{...(rest.fontWeight && { fontWeight: FontWeight[rest.fontWeight] })}
				>
					selectedOption?.label
				</Text>
			)}
		</FlexContainer>
	));

	const valueElement = (
		<>
			{selectedValues}
			{!!selectedCountToShow && (
				<FlexContainer
					alignItems={FlexAlignItems.center}
					height={20}
					padding={{ top: 0, bottom: 0, left: 4, right: 4 }}
					border={{ radius: 4 }}
					bgColor={Colors.grey100}
				>
					<Text
						id={`multiSelect_${randomId}`}
						color={Colors.grey900}
						fontSize={FontSize.text_md}
						fontWeight={FontWeight.regular}
					>
						+ {selectedCountToShow}
					</Text>
					<Tooltip target={`multiSelect_${randomId}`}>
						<>
							{hiddenOptions?.map((option) => (
								<Text display="block" color={Colors.white} fontSize={FontSize.text_md}>
									{option.label}
								</Text>
							))}
						</>
					</Tooltip>
				</FlexContainer>
			)}
		</>
	);

	const defaultToggleButton = (
		<MultiSelectDropdownToggle
			bgColor={Colors.transparent}
			alignItems={FlexAlignItems.center}
			className="toggle"
			disable={disable}
			openMenu={openMenu}
			isInvalid={isInvalid}
			{...rest}
			border={isInvalid ? { ...rest.border, color: Colors.error600 } : rest.border}
		>
			<FlexContainer
				ref={valueContainerRef}
				bgColor={Colors.transparent}
				alignItems={FlexAlignItems.center}
				columnGap={10}
				overflow={Overflow.hidden}
			>
				{values?.length ? valueElement : placeholder ?? 'Multi Select'}
			</FlexContainer>
			{!disable && (
				<FlexContainer
					bgColor={Colors.transparent}
					justify={FlexJustify.center}
					alignItems={FlexAlignItems.center}
					margin={{ left: Space.auto }}
				>
					<ChevronDownIcon className="chevron" color={Colors.grey500} fontSize={20} />
				</FlexContainer>
			)}
		</MultiSelectDropdownToggle>
	);

	const hintText = rest.hintText && (
		<Text color={Colors.grey500} fontSize={FontSize.text_xs}>
			{rest.hintText}
		</Text>
	);
	const errorText = rest.name && (
		<Text
			color={Colors.error500}
			fontSize={FontSize.text_xs}
			fontWeight={FontWeight.regular}
			margin={{ top: 4 }}
		>
			{getIn(formik?.errors, rest.name)}
		</Text>
	);

	return (
		<GridContainer
			margin={margin}
			position={position}
			flexGrow={flexGrow}
			flexShrink={flexShrink}
			flexBasis={flexBasis}
			gridRowStart={gridRowStart}
			gridColumnStart={gridColumnStart}
			gridColumnEnd={gridColumnEnd}
			gridRowEnd={gridRowEnd}
			width={width}
			bgColor={Colors.transparent}
			className={className}
		>
			{label && (
				<Text
					className="label"
					fontSize={FontSize.text_sm}
					fontWeight={FontWeight.semiBold}
					color={Colors.grey700}
				>
					{label}
				</Text>
			)}
			<StyledDropdown
				className="dropdown"
				disabled={disable}
				isOpen={openMenu}
				toggle={() => {
					toggleMenu();
					clearSearch();
				}}
				container={container}
			>
				{toggleButton ?? defaultToggleButton}
				{openMenu && options.length && (
					<StyledDropdownMenu className="menu">
						{options?.length > 10 && (
							<SearchInput
								position={{ value: Position.sticky, top: 0 }}
								margin={{ all: 4 }}
								clear={clearSearch}
								value={search}
								onChange={(e) => setSearch(e.target.value)}
							/>
						)}
						{options
							.filter((option) => !search || option.label.includes(search))
							.map((option) => (
								<SelectDropdownItem
									key={`key_${option.value}`}
									width="100%"
									tickOnSelect={false}
									showCheckbox
									{...option.itemProps}
									onClick={(e) => {
										if (values?.some((value) => value === option.value)) {
											onChange?.(values?.filter((value) => value !== option.value) ?? [], e);
											return;
										}
										onChange?.([...(values ?? []), option.value], e);
									}}
									active={values?.some((value) => value === option.value)}
									disabled={option?.disabled}
								>
									{option?.element ?? option?.label}
								</SelectDropdownItem>
							))}
						{children}
					</StyledDropdownMenu>
				)}
			</StyledDropdown>
			{isInvalid ? errorText : hintText}
		</GridContainer>
	);
};

DefaultMultiSelect.displayName = 'DefaultSelect';
DefaultMultiSelect.defaultProps = {
	...DefaultDropdownToggleStylingProps,
	tickOnSelect: true,
};
export default React.memo(DefaultMultiSelect);
