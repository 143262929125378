export enum Space {
	none = 0,
	xxxs = 1,
	xxms = 3,
	xxss = 4,
	xxs = 5,
	xmss = 7,
	xms = 8,
	xs = 10,
	xss = 12,
	s = 15,
	m = 20,
	xmm = 24,
	xm = 30,
	xxm = 40,
	l = 50,
	xl = 60,
	xml = 70,
	xxl = 80,
	xxml = 90,
	xxxl = 100,
	auto = 'auto',
}

export enum Position {
	absolute = 'absolute',
	relative = 'relative',
	static = 'static',
	fixed = 'fixed',
	sticky = 'sticky',
}

export enum BorderWidth {
	none = 0,
	thin = 1,
	thick = 2,
	heavy = 3,
}

export enum FlexAlignItems {
	stretch = 'stretch',
	flexStart = 'flex-start',
	flexEnd = 'flex-end',
	center = 'center',
	baseline = 'baseline',
}

export enum FlexJustify {
	auto = 'auto',
	stretch = 'stretch',
	flexStart = 'flex-start',
	flexEnd = 'flex-end',
	center = 'center',
	baseline = 'baseline',
	spaceEvenly = 'space-evenly',
	spaceBetween = 'space-between',
	spaceAround = 'space-around',
}

export enum FlexAlignSelf {
	auto = 'auto',
	stretch = 'stretch',
	flexStart = 'flex-start',
	flexEnd = 'flex-end',
	center = 'center',
	baseline = 'baseline',
}

export enum FlexWrap {
	nowrap = 'nowrap',
	wrap = 'wrap',
	wrapReverse = 'wrap-reverse',
}

export enum FlexDirection {
	column = 'column',
	columnReverse = 'column-reverse',
	row = 'row',
	rowReverse = 'row-reverse',
}

export enum zIndex {
	s = 1,
	m = 2,
	l = 3,
	xl = 20,
	xxxl = 100,
	leftSideNavBar = 1010,
}

export enum BoxShadow {
	xs = '0px 1px 2px rgba(16, 24, 40, 0.05)',
	sm = '0px 1px 2px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
	md = '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
	lg = '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
	xl = '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
	xxl = '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
	xxxl = '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
	focus = '0 0 0 0px #DCE1F9',
}
