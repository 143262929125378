import { getIn } from 'formik';
import React, { useContext, useRef, useState } from 'react';

import { Input as InputComp, InputProps, InputWrapper, StyledFlexContainer } from './Input';

import Text from 'components/Text';
import { FormikContext } from 'helpers/contexts';
import { Colors, FontSize, FontWeight } from 'theme';
import { FlexAlignItems, FlexJustify, Position } from 'theme/layout';

// import { checkValidations } from './Validations';

const Input: React.FC<InputProps> = (props: InputProps) => {
	const {
		className = '',
		label,
		display,
		bgColor,
		name,
		onBlur,
		isInlineView = false,
		type = 'text',
		width,
		minWidth,
		height,
		margin,
		border,
		padding,
		position,
		gridRowStart,
		gridRowEnd,
		gridColumnStart,
		gridColumnEnd,
		flexGrow,
		flexShrink,
		flexBasis,
		placeholder,
		value,
		hintText,
		inputFieldRef,
		// validations,
		...rest
	} = props;

	// const [isValid, errorMessage]: [boolean, string] = checkValidations(
	// 	validations ?? [],
	// 	value ?? ''
	// );

	const { formik }: any = useContext(FormikContext);
	const [isInlineViewState, setIsInlineViewState] = useState(isInlineView);
	const isInputError = name && getIn(formik?.errors, name) && getIn(formik?.touched, name);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const handleInlineViewClick = (e: React.MouseEvent<HTMLInputElement>) => {
		props.onClick?.(e);
		if (props.disabled) {
			return;
		}
		(inputFieldRef ?? inputRef).current?.focus();
		setIsInlineViewState(false);
	};

	const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		onBlur?.(event);
		if (isInlineView) {
			setIsInlineViewState(true);
		}
	};

	const inputComp = (
		<InputComp
			className="input-class"
			type={type}
			name={name}
			value={value}
			onBlur={handleBlur}
			ref={inputFieldRef ?? inputRef}
			placeholder={placeholder}
			{...rest}
		/>
	);

	return (
		<>
			{label && (
				<Text
					fontSize={FontSize.text_sm}
					fontWeight={FontWeight.semiBold}
					color={Colors.grey700}
					margin={{ bottom: 0 }}
					className="label-class"
				>
					{label}
				</Text>
			)}
			<InputWrapper
				className={className}
				bgColor={bgColor}
				disabled={props.disabled}
				isInvalid={isInputError}
				isFilled={false}
				isInlineView={isInlineViewState}
				width={width}
				{...{
					minWidth,
					display,
					margin,
					padding,
					border,
					height,
					position,
					flexGrow,
					flexShrink,
					flexBasis,
					gridRowStart,
					gridRowEnd,
					gridColumnStart,
					gridColumnEnd,
				}}
			>
				{props.prefixComponent ? props.prefixComponent : null}
				{inputComp}
				{props.suffixComponent ? props.suffixComponent : null}
				{isInlineViewState && (
					<StyledFlexContainer
						cursor={props.disabled ? 'auto' : 'pointer'}
						border={{ all: 1, radius: 8, color: Colors.transparent }}
						padding={{ top: 10, right: 14, bottom: 10, left: 14 }}
						bgColor={bgColor ?? Colors.white}
						onClick={handleInlineViewClick}
						alignItems={FlexAlignItems.center}
						justify={FlexJustify.spaceBetween}
						className="inline-input-container"
						position={{ value: Position.absolute, top: 0, bottom: 0, left: 0, right: 0 }}
					>
						{props.prefixComponent ? props.prefixComponent : null}
						<Text
							ellipsis
							color={props.value === '' ? Colors.grey500 : Colors.grey900}
							fontSize={FontSize.text_md}
							fontWeight={FontWeight.semiBold}
							flexGrow={1}
						>
							{props.value === '' ? placeholder : props.value}
						</Text>
						{props.suffixComponent ? props.suffixComponent : null}
					</StyledFlexContainer>
				)}
				{!isInputError && hintText && (
					<Text
						htmlTagName="div"
						color={Colors.grey500}
						fontSize={FontSize.text_xs}
						fontWeight={FontWeight.regular}
						position={{ value: Position.absolute, top: '100%', left: 0 }}
						margin={{ top: 4 }}
					>
						{hintText}
					</Text>
				)}
				{isInputError && (
					<Text
						htmlTagName="div"
						className="input-error"
						color={Colors.error500}
						fontSize={FontSize.text_xs}
						fontWeight={FontWeight.regular}
						position={{ value: Position.absolute, top: '100%', left: 0 }}
						margin={{ top: 4 }}
					>
						{name && getIn(formik?.errors, name)}
					</Text>
				)}
			</InputWrapper>
		</>
	);
};

export default Input;
