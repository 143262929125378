import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { TrashIcon, MemoIcon } from 'zeda-icons/v3';

import FlexContainer from 'components/Container/FlexContainer';
import Image from 'components/Image';
import Tooltip from 'components/Tooltip/default';
import { convertHexToRGBA } from 'helpers/theme';
import { useDeviceDimensions } from 'hooks';
import { FlexAlignItems, FlexJustify, Position } from 'theme/layout';

const StyledFlexContainer = styled(FlexContainer)`
	.overlay {
		display: none;
	}

	&:hover .overlay {
		display: flex !important;
	}
`;

interface AttachmentItemProps {
	id: string | number;
	onDelete?: (e: React.MouseEvent<HTMLElement>, id: string | number) => void;
	url: string;
	canDelete?: boolean;
	onClick?: (url: string, id: string | number) => void;
	fileType: string;
	name: string;
	tooltipId: string | number;
}

const AttachmentItem = (props: AttachmentItemProps) => {
	const { url, id, tooltipId } = props;
	const SUPPORTED_IMAGE_TYPES = /image\/(png|jpe?g|gif)/;
	const isPreviewSupported = SUPPORTED_IMAGE_TYPES.test(props.fileType);
	const { colors } = useTheme();

	const { isMobile } = useDeviceDimensions();

	const handleAttachmentClick = () => {
		if (isMobile) {
			window.open(url, '_blank');
			return;
		}
		props.onClick?.(url, id);
	};

	return (
		<StyledFlexContainer
			border={{ all: 1, color: colors.grey200, radius: 4 }}
			width={64}
			height={64}
			bgColor={isPreviewSupported ? colors.white : colors.grey100}
			onClick={handleAttachmentClick}
			cursor="pointer"
			padding={{ all: 2 }}
			margin={{ right: 8 }}
			position={{ value: Position.relative }}
			justify={FlexJustify.center}
			alignItems={FlexAlignItems.center}
			id={`attachment_item_${tooltipId}`}
		>
			{isPreviewSupported ? (
				<Image alt="image-alt" border={{ radius: 4 }} height="100%" width="100%" src={url} />
			) : (
				<MemoIcon color={colors.grey500} />
			)}
			{props.canDelete && (
				<FlexContainer
					className="overlay"
					position={{ value: Position.absolute, top: 0, bottom: 0, left: 0, right: 0 }}
					bgColor={convertHexToRGBA(colors.primary600, 0.8)}
					alignItems={FlexAlignItems.center}
					justify={FlexJustify.center}
					border={{ radius: 4 }}
				>
					<TrashIcon
						color={colors.white}
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							e.stopPropagation();
							props.onDelete?.(e, id);
						}}
					/>
				</FlexContainer>
			)}
			<Tooltip target={`attachment_item_${tooltipId}`}>{props.name}</Tooltip>
		</StyledFlexContainer>
	);
};

export default AttachmentItem;
