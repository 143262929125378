import styled from '@emotion/styled';
import React from 'react';

import { Colors, FontSize, TextAlign } from 'theme';
import { FlexAlignItems, FlexAlignSelf, FlexJustify } from 'theme/layout';
import { Margin, Padding, Border, Grid, Flex, Position } from 'theme/mixins';
import { BorderModel, Box, GridModel, PositionModel } from 'theme/types';

export interface DefaultTagProps extends GridModel {
	width?: number | string;
	height?: number;
	minHeight?: number;
	minWidth?: number;
	maxHeight?: number;
	maxWidth?: number;
	children: React.ReactNode;
	fontWeight?: number;
	fontSize?: FontSize;
	className?: string;
	padding?: Partial<Box>;
	position?: PositionModel;
	margin?: Box;
	border?: BorderModel;
	bgColor?: Colors;
	textAlign?: TextAlign;
	flexGrow?: number;
	flexShrink?: number;
	flexBasis?: string | number;
	justifySelf?: FlexJustify;
	alignSelf?: FlexAlignSelf;
	alignItems?: FlexAlignItems;
	justify?: FlexJustify;
}

const DefaultTag = styled.div<DefaultTagProps>`
	outline: none;
	display: flex;
	align-items: center;
	width: ${({ width }) => (width && typeof width === 'number' ? `${width}px` : width)};
	height: ${({ height }) => height && `${height}px`};
	text-align: ${({ textAlign = 'initial' }) => textAlign};
	background-color: ${({ bgColor = Colors.white }) => bgColor};
	${({ height }) => height && `height: ${height}px`};
	${({ width }) => width && `width: ${width}px`};
	${({ minHeight }) => minHeight && `min-height: ${minHeight}px`};
	${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px`};
	${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};
	${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
	${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
	${({ color }) => color && `color: ${color}`};
	${Flex}
	${Grid}
	${Padding}
	${Margin}
	${Border}
	${Position}
`;

DefaultTag.defaultProps = {
	padding: { top: 2, right: 2, bottom: 2, left: 2 },
	margin: { top: 4, right: 4, bottom: 4, left: 4 },
	bgColor: Colors.white,
	alignItems: FlexAlignItems.center,
};

DefaultTag.displayName = 'DefaultTag';

export default DefaultTag;
