import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Dispatch } from 'redux';

import { VerifyCognitoToken } from 'actions/UserAction';
import { envConfig } from 'constants/constants';
import { getPreviousURL, removePreviousURL, setUserAccessToken } from 'helpers/storage';
import { getPortalSettings } from 'selectors/AppSelector';
import { RootState } from 'types/state';

type Props = ReduxProps;

const AuthCallback: React.FC<Props> = (props) => {
	const [searchParams] = useSearchParams();

	const removeDynamicPartFromURL = (url: string, boardUniqueName: string) => {
		// Create a regular expression pattern to match the dynamic part
		const pattern = new RegExp(`/${boardUniqueName}`, 'i');

		// Replace the boardUniqueName with an empty string
		return url.replace(pattern, '');
	};

	const verifyCognitoLogin = async () => {
		const code = searchParams.get('code');
		if (!code) return;

		const state = searchParams.get('state');
		const decodedData = atob(state!);
		const parsedDecodedData = JSON.parse(decodedData);

		const body = {
			auth_code: code,
			workspaceId: parsedDecodedData.workspaceId,
			boardUniqueName: parsedDecodedData.boardUniqueName,
		};

		const { data, error } = await props.verifyToken(body);

		if (error) return;

		const { token, url } = data;

		/**
		 * User is redirected back to auth/callback with token and url in the queryParams
		 * This is so that token could be set in the localStorage of the custom domain
		 */

		const isCustomDomain = !url.includes(envConfig.portal_url);
		const boardUniqueName = isCustomDomain ? url.split('/')[3] : url.split('/')[4];
		const portalUrl = new URL(url);
		const newUrl = removeDynamicPartFromURL(url, boardUniqueName);

		window.location.href = `${portalUrl.origin}/auth/callback?token=${token}&url=${newUrl}&boardUniqueName=${boardUniqueName}`;
	};

	/**
	 * Set user token to custom domain
	 */

	const handleCustomDomainSSOLogin = () => {
		const token = searchParams.get('token');
		const url = searchParams.get('url');
		let boardUniqueName = searchParams.get('boardUniqueName');

		if (!url || !token) return false;
		let newUrl = url;
		const isCustomDomain = !url.includes(envConfig.portal_url);

		if (!isCustomDomain) return false;

		if (!boardUniqueName) {
			[, , , boardUniqueName] = url.split('/');
			newUrl = removeDynamicPartFromURL(url, boardUniqueName);
		}

		window.location.href = `${newUrl}/auth/callback?token=${token}&boardUniqueName=${boardUniqueName}`;
		return true;
	};

	const setUserToken = () => {
		const token = searchParams.get('token');
		const url = searchParams.get('url') ?? '';
		const boardUniqueName = searchParams.get('boardUniqueName') ?? '';

		// In case of SSO, it never goes to verifyCognito and hence boardUniqueName is not found.
		// board name is a part of url that comes from SSO

		const newRedirectUrl =
			url && boardUniqueName ? `${url}/${boardUniqueName}` : url || `/${boardUniqueName}`;

		if (!token) return;

		setUserAccessToken(token);
		const prevUrl = getPreviousURL();
		const redirectUrl = prevUrl ? `${newRedirectUrl ?? ''}/${prevUrl}` : newRedirectUrl ?? '/';
		removePreviousURL();
		window.location.href = redirectUrl;
	};

	useEffect(() => {
		verifyCognitoLogin();
		const isCustomDomainSSOLogin = handleCustomDomainSSOLogin();
		if (isCustomDomainSSOLogin) return;

		setUserToken();
	}, [searchParams]);

	return null;
};

const mapStateToProps = (state: RootState) => ({
	settings: getPortalSettings(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	// eslint-disable-next-line camelcase
	verifyToken: (body: { auth_code: string; workspaceId: string; boardUniqueName: string }) =>
		dispatch(VerifyCognitoToken.request(body)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AuthCallback);
