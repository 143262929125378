import styled from '@emotion/styled';
import React from 'react';

import { IconButtonProps, ButtonProps, buttonBlockPadding } from './Button';

import Button from '.';

export const IconButtonComp = styled(Button)<ButtonProps>`
	${({ size = 'md' }) => `padding: ${buttonBlockPadding[size]}px;`}
	& > * {
		vertical-align: initial;
	}
`;

const IconButton: React.FC<IconButtonProps> = (props: IconButtonProps) => {
	const { icon, ...rest } = props;
	return <IconButtonComp leadingIcon={icon} {...rest} />;
};

export default IconButton;
