import axios from 'axios';

import { getUserAccessToken } from './storage';

const defaultHeaders = {
	Accept: 'application/json',
	'Access-Control-Allow-Origin': '*',
};

const headers = { ...defaultHeaders };

const getApiUrl = () => {
	return process.env.NODE_ENV === 'development'
		? `${process.env.REACT_APP_SERVER_URL}/v1/portal`
		: '/v1/portal';
};
const request = axios.create({
	baseURL: getApiUrl(),
	headers,
	withCredentials: true,
});

request.interceptors.request.use(
	(config) => {
		const token = getUserAccessToken();
		const isFormData = config.data instanceof FormData;
		return {
			...config,
			...(token && {
				headers: {
					...config.headers,
					Authorization: `Bearer ${token}`,
					...(!isFormData && { 'Content-Type': 'application/json' }),
				},
			}),
		};
	},

	(error) => {
		return Promise.reject(error);
	}
);

request.interceptors.response.use(
	(response) => response,
	(error) => Promise.reject(error)
);

export default request;
