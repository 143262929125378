import { Screens } from 'types';

export const LOADING_STATUS = {
	INIT: 1,
	INACTIVE: 0,
};

export const ATTACHMENT_LIMIT = 2000000;

export const emailRegex = /.+@.+\.[A-Za-z]+$/;

export const SCREEN_DIMENSIONS: { [key in Screens]: { min: number; max: number } } = {
	[Screens.DESKTOP]: { min: 768, max: 4000 },
	[Screens.TABLET]: { min: 430, max: 768 },
	[Screens.MOBILE]: { min: 240, max: 430 },
};

export const EMPTY_STATE_DIMENSIONS = {
	[Screens.DESKTOP]: { imageHeight: 300, imageWidth: 400 },
	[Screens.TABLET]: { imageHeight: 270, imageWidth: 360 },
	[Screens.MOBILE]: { imageHeight: 240, imageWidth: 320 },
};

export enum ErrorState {
	PRIVATE_PORTAL_NO_ACCESS = 'PRIVATE_PORTAL_NO_ACCESS',
	PRIVATE_PORTAL_LOGIN = 'PRIVATE_PORTAL_LOGIN',
	INVALID_LINK = 'INVALID_LINK',
	DEFAULT = 'default',
}

export const config = {
	development: {
		dashboard_url: 'http://localhost:3000',
		portal_url: 'localhost:3001',
	},
	test: {
		dashboard_url: 'https://testing.zeda.io',
		portal_url: 'portal-testing.zeda.io',
	},
	production: {
		dashboard_url: 'https://app.zeda.io',
		portal_url: 'portal.zeda.io',
	},
};

export const envConfig =
	config[(process.env.REACT_APP_ENVIRONMENT as keyof typeof config) || 'production'];

export enum feedbackStateKeys {
	open = 'OPEN',
	resolved = 'RESOLVED',
}
