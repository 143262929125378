import styled from '@emotion/styled';
import React from 'react';

import translations from 'constants/translation';
import { Colors, FontSize, FontWeight, TextAlign } from 'theme';
import { FlexAlignSelf, FlexJustify } from 'theme/layout';
import { Margin, Padding, Border, Grid, Flex, Position } from 'theme/mixins';
import { Box, BorderModel, PositionModel, GridModel } from 'theme/types';
import { TextTransform } from 'theme/typography';
import { Translation } from 'types';

export interface TextProps extends GridModel {
	htmlTagName?: string;
	className?: string;
	isMandatoryLabel?: boolean;
	translation?: Translation;
	children?: React.ReactNode;
	title?: string;
	height?: number | string;
	maxHeight?: number;
	minHeight?: number;
	width?: number | string;
	maxWidth?: number;
	minWidth?: number;
	fontSize?: FontSize;
	fontWeight?: FontWeight;
	lineHeight?: number | string;
	verticalAlign?: string;
	color?: Colors;
	ellipsis?: boolean;
	border?: BorderModel;
	padding?: Partial<Box>;
	position?: PositionModel;
	flexGrow?: number;
	flexShrink?: number;
	alignSelf?: FlexAlignSelf;
	justifySelf?: FlexJustify;
	flexBasis?: string | number;
	margin?: Partial<Box>;
	textAlign?: TextAlign;
	cursor?: string;
	bgColor?: Colors;
	display?: string;
	textTransform?: TextTransform;
	id?: string;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const TextComponent = (props: TextProps) => {
	const { htmlTagName = 'span', translation, children, ...rest } = props;

	// added this to add break lines in strings to have multiple paragraphs as text component
	if (translation) {
		return React.createElement(htmlTagName, {
			...rest,
			dangerouslySetInnerHTML: { __html: translations[translation] },
		});
	}

	return React.createElement(htmlTagName, { ...rest }, children);
};

const Text = styled(TextComponent)<TextProps>`
	${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
	${({ minHeight }) => minHeight && `min-height: ${minHeight}px`};
	${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px`};
	width: ${({ width }) => (width && typeof width === 'number' ? `${width}px` : width)};
	height: ${({ height }) => (height && typeof height === 'number' ? `${height}px` : height)};
	${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
	${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
	${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
	${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
	${({ display }) => display && `display: ${display}`};
	background-color: ${({ bgColor = Colors.transparent }) => bgColor};
	color: ${({ color }) => color ?? Colors.grey900};
	${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
	cursor: ${({ cursor }) => !!cursor && cursor};
	${({ verticalAlign }) => verticalAlign && `vertical-align: ${verticalAlign}`};
	${({ ellipsis }) =>
		ellipsis &&
		`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: inline-block;
`}
	${({ isMandatoryLabel }) =>
		isMandatoryLabel &&
		`
	&::before {
		content: "*";
		color: ${Colors.error600}
	}
`}
	${Margin}
	${Padding}
	${Border}
	${Position}
	${Grid}
	${Flex}
`;

export default Text;
