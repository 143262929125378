import { ReduxAsyncAction } from '../helpers/action';
import request from '../helpers/request';
import { ReduxActionWithData } from '../types';
import { CategoryType, LabelType, ReleaseNote, ReleaseNoteLike } from '../types/releaseNote';

import { ANALYTICS_EVENTS } from 'helpers/analytics';

/**
 * Fetch release note initialize API
 */
export const FetchReleaseNotesConfig = new ReduxAsyncAction('FETCH_RELEASE_NOTES_CONFIG');
FetchReleaseNotesConfig.registerRequest(async (workspaceId: string, boardUniqueName: string) => {
	const { data } = await request.get('release-notes/initialize', {
		params: { workspaceId, boardUniqueName },
	});
	return { ...data.data };
});
export const FETCH_RELEASE_NOTES_CONFIG = FetchReleaseNotesConfig.constants;
export type FetchReleaseNotesConfigType = ReduxActionWithData<
	'FETCH_RELEASE_NOTES_CONFIG',
	{ categories: CategoryType[]; labels: LabelType[] }
>;

/**
 * Fetch all release note && can add query if required
 */
export const FetchAllReleaseNotes = new ReduxAsyncAction('FETCH_ALL_RELEASE_NOTES');
FetchAllReleaseNotes.registerRequest(
	async (body: { query: string; workspaceId: string; boardUniqueName: string }) => {
		const { data } = await request.get(`/release-notes/all?${body.query}`, {
			params: { workspaceId: body.workspaceId, boardUniqueName: body.boardUniqueName },
		});
		return data;
	}
);
export const FETCH_ALL_RELEASE_NOTES = FetchAllReleaseNotes.constants;
export type FetchAllReleaseNotesType = ReduxActionWithData<
	'FETCH_ALL_RELEASE_NOTES',
	{ data: ReleaseNote[] }
>;

/**
 * Fetch release note details
 */
export const FetchReleaseNoteDetails = new ReduxAsyncAction('FETCH_RELEASE_NOTE_DETAILS');
FetchReleaseNoteDetails.registerRequest(
	async (id: string, workspaceId: string, boardUniqueName: string) => {
		const { data } = await request.get(`/release-notes/${id}`, {
			params: { workspaceId, boardUniqueName },
		});
		return data;
	}
);
export const FETCH_RELEASE_NOTE_DETAILS = FetchReleaseNoteDetails.constants;
export type FetchReleaseNoteDetailsType = ReduxActionWithData<
	'FETCH_RELEASE_NOTE_DETAILS',
	{ data: ReleaseNote }
>;

/**
 * Like a release note for logged-in user
 */
export const PostReleaseNoteLike = new ReduxAsyncAction('POST_RELEASE_NOTE_LIKE');
PostReleaseNoteLike.registerRequest(
	async (releaseNoteId: string, workspaceId: string, boardUniqueName: string) => {
		const { data } = await request.post('/reactions/post', {
			entityId: releaseNoteId,
			entityType: 'RELEASE_NOTE',
			reactionType: 'LIKE',
			workspaceId,
			boardUniqueName,
		});

		(window as WindowExtended).zedaAnalytics.track(ANALYTICS_EVENTS.LIKE_RELEASE_NOTES, {
			releaseNoteId,
		});

		return { ...data.data, releaseNoteId };
	}
);
export const POST_RELEASE_NOTE_LIKE = PostReleaseNoteLike.constants;
export type PostReleaseNoteLikeType = ReduxActionWithData<
	'POST_RELEASE_NOTE_LIKE',
	ReleaseNoteLike
>;

/**
 * Remove like for a release note in case of logged-in user
 */
export const RemoveReleaseNoteLike = new ReduxAsyncAction('REMOVE_RELEASE_NOTE_LIKE');
RemoveReleaseNoteLike.registerRequest(
	async (releaseNoteId: string, workspaceId: string, boardUniqueName: string) => {
		const { data } = await request.post('/reactions/remove', {
			entityId: releaseNoteId,
			entityType: 'RELEASE_NOTE',
			reactionType: 'LIKE',
			workspaceId,
			boardUniqueName,
		});
		return { ...data.data, releaseNoteId };
	}
);
export const REMOVE_RELEASE_NOTE_LIKE = RemoveReleaseNoteLike.constants;
export type RemoveReleaseNoteLikeType = ReduxActionWithData<
	'REMOVE_RELEASE_NOTE_LIKE',
	ReleaseNoteLike
>;
