import { getIn } from 'formik';
import React, { useContext, useState } from 'react';
import { ChevronDownIcon, XmarkIcon } from 'zeda-icons/v3';

import GridContainer from '../Container/GridContainer';
import SearchInput from '../Input/SearchInput';

import FlexContainer from 'components/Container/FlexContainer';
import { StyledDropdownMenu } from 'components/Dropdown';
import SelectDropdownItem from 'components/Dropdown/DropdownItem';
import {
	BaseSelectProps,
	DefaultDropdownToggleStylingProps,
	SelectDropdownToggle,
	StyledDropdown,
} from 'components/Select/Select';
import Text from 'components/Text';
import { FormikContext } from 'helpers/contexts';
import useToggle from 'hooks/useToggle';
import { Colors, FontSize, FontWeight } from 'theme';
import { FlexAlignItems, FlexJustify, Position, Space } from 'theme/layout';

const DefaultSelect = (props: BaseSelectProps) => {
	const [openMenu, toggleMenu] = useToggle(false);
	const [search, setSearch] = useState<string>('');

	const clearSearch = () => setSearch('');

	const {
		children,
		disable,
		className,
		placeholder,
		value,
		onClear,
		onChange,
		tickOnSelect,
		options,
		label,
		toggleButton,
		container,
		width,
		margin,
		position = { value: Position.relative },
		flexGrow,
		flexShrink,
		flexBasis,
		gridRowStart,
		gridColumnStart,
		gridColumnEnd,
		gridRowEnd,
		...rest
	} = props;
	const { formik }: { formik: any } = useContext(FormikContext);
	const isInvalid =
		!!rest.name && getIn(formik?.errors, rest.name) && getIn(formik?.touched, rest.name);

	const selectedOption = options.find((option) => option.value === value);

	const hintText = rest.hintText && (
		<Text color={Colors.grey500} fontSize={FontSize.text_xs}>
			{rest.hintText}
		</Text>
	);

	const errorText = rest.name && (
		<Text
			color={Colors.error500}
			fontSize={FontSize.text_xs}
			className="input-error"
			fontWeight={FontWeight.regular}
			margin={{ top: 4 }}
		>
			{getIn(formik?.errors, rest.name)}
		</Text>
	);

	const defaultToggleButton = (
		<SelectDropdownToggle
			tabIndex={0}
			bgColor={Colors.white}
			alignItems={FlexAlignItems.center}
			className="toggle"
			disable={disable}
			openMenu={openMenu}
			isInvalid={isInvalid}
			{...rest}
			border={isInvalid ? { ...rest.border, color: Colors.error600 } : rest.border}
		>
			{selectedOption
				? selectedOption?.element ?? (
						<Text
							{...(rest.color && { color: Colors[rest.color] })}
							{...(rest.fontSize && { fontSize: FontSize[rest.fontSize] })}
							{...(rest.fontWeight && { fontWeight: FontWeight[rest.fontWeight] })}
						>
							{selectedOption?.label}
						</Text>
				  )
				: placeholder ?? 'Select'}
			{!disable && (
				<FlexContainer
					bgColor={Colors.transparent}
					justify={FlexJustify.center}
					alignItems={FlexAlignItems.center}
					margin={{ left: Space.auto }}
					columnGap={10}
				>
					{value && onClear && (
						<XmarkIcon
							className="close"
							color={Colors.grey900}
							fontSize={16}
							onClick={(e: Event) => {
								e.preventDefault();
								e.stopPropagation();
								onClear();
							}}
						/>
					)}
					<ChevronDownIcon className="chevron" color={Colors.grey900} fontSize={20} />
				</FlexContainer>
			)}
		</SelectDropdownToggle>
	);

	return (
		<GridContainer
			margin={margin}
			position={position}
			flexGrow={flexGrow}
			flexShrink={flexShrink}
			flexBasis={flexBasis}
			gridRowStart={gridRowStart}
			gridColumnStart={gridColumnStart}
			gridColumnEnd={gridColumnEnd}
			gridRowEnd={gridRowEnd}
			width={width}
			bgColor={Colors.transparent}
			className={className}
		>
			{label && (
				<Text
					className="label"
					fontSize={FontSize.text_sm}
					fontWeight={FontWeight.semiBold}
					color={Colors.grey700}
				>
					{label}
				</Text>
			)}
			<StyledDropdown
				className="dropdown"
				disabled={disable}
				isOpen={openMenu}
				toggle={() => {
					toggleMenu();
					clearSearch();
				}}
				container={container}
			>
				{toggleButton ?? defaultToggleButton}
				{openMenu && options.length && (
					<StyledDropdownMenu className="menu">
						{options?.length > 10 && (
							<SearchInput
								position={{ value: Position.sticky, top: 0 }}
								margin={{ all: 4 }}
								clear={clearSearch}
								value={search}
								onChange={(e) => setSearch(e.target.value)}
							/>
						)}
						{options
							.filter((option) => !search || option.label.includes(search))
							.map((option) => (
								<SelectDropdownItem
									key={`key_${option.value}`}
									width="100%"
									tickOnSelect={tickOnSelect}
									{...option.itemProps}
									onClick={(e) => onChange?.(option.value, e)}
									active={value === option.value}
									disabled={option?.disabled}
								>
									{option?.element ?? option?.label}
								</SelectDropdownItem>
							))}
						{children}
					</StyledDropdownMenu>
				)}
			</StyledDropdown>
			{isInvalid ? errorText : hintText}
		</GridContainer>
	);
};

DefaultSelect.displayName = 'DefaultSelect';
DefaultSelect.defaultProps = {
	...DefaultDropdownToggleStylingProps,
	tickOnSelect: true,
};
export default React.memo(DefaultSelect);
