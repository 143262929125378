import React from 'react';

import { DefaultButtonProps, DefaultButton as DefaultButtonComp } from './Button';

export const DefaultButton: React.FC<DefaultButtonProps> = (props: DefaultButtonProps) => {
	const { type = 'button', htmlElement, ...rest } = props;
	return (
		<DefaultButtonComp {...rest} as={htmlElement} type={type}>
			{props.children}
		</DefaultButtonComp>
	);
};

DefaultButton.defaultProps = {
	type: 'button',
};
