import styled from '@emotion/styled';

import Container, { ContainerProps } from './default';

import { FlexAlignItems, FlexDirection, FlexJustify, FlexWrap } from 'theme/layout';

export interface FlexProps extends ContainerProps {
	alignContent?: FlexAlignItems;
	alignItems?: FlexAlignItems;
	justify?: FlexJustify;
	justifyItems?: FlexJustify;
	wrap?: FlexWrap;
	direction?: FlexDirection;
	rowGap?: number;
	columnGap?: number;
}

const FlexContainer = styled(Container)<FlexProps>`
	display: flex;
	${({ alignContent }) => alignContent && `align-content: ${alignContent};`}
	${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
	${({ direction }) => direction && `flex-direction: ${direction};`}
	${({ justify }) => justify && `justify-content: ${justify};`}
	${({ justifyItems }) => justifyItems && `justify-items: ${justifyItems};`}
	${({ direction }) => direction && `flex-direction: ${direction};`}
	row-gap: ${({ rowGap }) => rowGap && `${rowGap}px`};
	column-gap: ${({ columnGap }) => columnGap && `${columnGap}px`};
	flex-wrap: ${({ wrap }) => wrap};
	list-style: none;
`;

export default FlexContainer;
