import { envConfig } from 'constants/constants';
import store from 'reducers';
import { RouteConstants } from 'routes/config';
import { PortalSections, Sections } from 'types/app';

const mapping = {
	[Sections.FEEDBACK]: RouteConstants.FEEDBACK_LIST.replace('/*', ''),
	[Sections.ROADMAP]: RouteConstants.FEEDBACK_ROADMAP,
	[Sections.RELEASE_NOTES]: RouteConstants.RELEASE_NOTE_LIST,
};

export const getUniqueWorkspaceName = () => {
	const reduxState = store.getState();
	return reduxState.appReducer.workspaceInfo?.uniquePortalName;
};

export const getCustomDomainUrl = () => {
	const reduxState = store.getState();
	return reduxState.appReducer.workspaceInfo?.portalCustomURL;
};

export const getBoardUniqueName = () => {
	const reduxState = store.getState();
	return reduxState.appReducer.configuration?.boardUniqueName;
};

export const generatePath = (paths: string[]) => {
	const isCustomDomain = window.location.host !== envConfig.portal_url;
	const uniqueName = getUniqueWorkspaceName();
	const customDomainUrl = getCustomDomainUrl();
	const boardUniqueName = getBoardUniqueName();
	const path = paths.join('');
	return customDomainUrl && isCustomDomain
		? `/${boardUniqueName}${path}`
		: `/${uniqueName}/${boardUniqueName}${path}`;
};

export const formatURL = (url: string, boardUniqueName?: string, workspaceName?: string) => {
	if (!workspaceName) return `/${boardUniqueName}${url}`;
	return `/${workspaceName}/${boardUniqueName}${url}`;
};

export const hasAccessToRoute = (
	sections: (PortalSections & { key: string })[],
	keys: Sections[]
) => {
	if (!sections) return false;

	if (!keys) return true;

	return sections.some((section) => keys?.includes(section.key as Sections));
};

export const getRedirectedRoute = (
	sections: (PortalSections & { key: string })[],
	boardUniqueName: string,
	workspaceName?: string
) => {
	if (sections.length <= 0) return formatURL('/no-sections', boardUniqueName, workspaceName);
	const firstSection = sections[0];
	return formatURL(mapping[firstSection.key as Sections], boardUniqueName, workspaceName);
};

export const openInNewTab = async (url: string) => {
	const a = document.createElement('a');
	a.href = url;
	a.setAttribute('target', '_blank');
	a.click();
};
