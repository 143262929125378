import { ExternalFormDataType } from './FeedbackAction';

import { ReduxAsyncAction } from 'helpers/action';
import request from 'helpers/request';
import { ReduxActionWithData } from 'types';
import { PortalConfig, WorkspaceInfoType } from 'types/app';

/**
 * API to fetch portal configurations
 */
export const FetchPortalConfig = new ReduxAsyncAction('FETCH_PORTAL_CONFIG');
FetchPortalConfig.registerRequest(
	async (body: { uniqueName?: string; host: string; boardUniqueName?: string }) => {
		const { uniqueName, ...rest } = body;
		const { data } = await request.get('/', {
			params: { ...rest, ...(uniqueName && { name: uniqueName }) },
		});
		return { ...data };
	}
);
export const FETCH_PORTAL_CONFIG = FetchPortalConfig.constants;
export type FetchPortalConfigType = ReduxActionWithData<'FETCH_PORTAL_CONFIG', PortalConfig>;

/**
 * API to fetch workspace details
 */
export const FetchWorkspaceDetails = new ReduxAsyncAction('FETCH_WORKSPACE_DETAILS');
FetchWorkspaceDetails.registerRequest(async (id: string) => {
	const { data } = await request.get(`/workspace/${id}`);
	return { ...data.data };
});
export const FETCH_WORKSPACE_DETAILS = FetchWorkspaceDetails.constants;
export type FetchWorkspaceDetailsType = ReduxActionWithData<
	'FETCH_WORKSPACE_DETAILS',
	WorkspaceInfoType
>;

export type AppActions = FetchPortalConfigType | FetchWorkspaceDetailsType | ExternalFormDataType;
