import { feedbackStateKeys } from 'constants/constants';

export interface FeedbackState {
	loadingStatus: number | string;
	list: {
		feedbacks: FeedbackItem[];
		currentPage: number;
		totalPages: number;
	};
	types: ModuleItem[];
	status: (ModuleItem & { state: feedbackStateKeys })[];
	visibleCustomFields: CustomField[];
	boardGroup: BoardItem[];
	feedbackDetails: Partial<FeedbackDetail>;
}

export type BoardItem = {
	feedbackList: FeedbackItem[];
	id: string;
	pageNumber: number;
	totalCount: number;
};

export interface ModuleItem {
	key?: string;
	color?: string;
	id: string;
	index: number;
	label: string;
	user?: {
		feedbacks: FeedbackItem[];
		currentPage: number;
		totalPages: number;
	};
}

export enum FeedbackVisibility {
	PRIVATE = 'PRIVATE',
	PUBLIC = 'PUBLIC',
}

export enum FeedbackSources {
	DASHBOARD = 'DASHBOARD',
	WIDGET = 'WIDGET',
	FORM = 'FORM',
	EXTERNAL_PORTAL = 'EXTERNAL_PORTAL',
	SLACK = 'SLACK',
}

export interface FileType {
	id: string;
	fileName: string;
	size: number;
	url: string;
	fileType: string;
}

export interface FeedbackItem {
	id: string;
	title: string;
	description: string;
	isMerged: boolean;
	isArchived: boolean;
	parentRequestId: string;
	source: FeedbackSources;
	createdBy: string | null;
	createdByExternalUser: boolean;
	name: string | null;
	email: string | null;
	visibility: FeedbackVisibility;
	createdAt: string;
	updatedAt: string;
	workspaceId: string;
	assignee: string | null;
	typeId: string | null;
	statusId: string | null;
	upvoteCount: number;
	isUpvoted: boolean;
	userName: string | null;
	commentsCount: number;
	requestTypeId: string;
	requestTypeLabel: string;
	researchId: string | null;
}

export interface MappedRequestItem extends FeedbackItem {
	status?: ModuleItem;
	type?: ModuleItem;
}

export interface FeedbackDetail extends FeedbackItem {
	upvotedBy: { name: string; image: string }[];
	commentCount: number;
	files: FileType[];
	customFields: CustomFieldValue[];
	mergedRequests: [];
	comments: CommentInstance[];
	loading?: number;
	submittedBy: SubmitBy;
}

export interface MappedFeedbackDetail extends FeedbackDetail {
	status?: ModuleItem;
	type?: ModuleItem;
}

export interface CommentInstance {
	id: string;
	parentCommentId: string;
	text: string;
	mentions: string[];
	isWorkspaceComment: boolean;
	createdAt: string;
	updatedAt: string;
	visibility: string;
	source: string;
	sourceId: string;
	isPinned: boolean;
	portalUserId: string;
	reaction: string;
	requestId: string;
	dashboardUserId: string;
	researchId: string;
	happyCount: number;
	neutralCount: number;
	likeCount: number;
	heartCount: number;
	feedbackId: string;
	commentedBy: CommentBy;
	files: FileType[];
	isVisibility: string;
}

export interface CommentBy {
	id: string;
	name: string;
	image: string;
	isDashboardUser: boolean;
}

export interface SubmitBy {
	id: string;
	name: string;
	email: string;
	isActive: boolean;
	isEmailVerified: boolean;
	isAppUser: boolean;
	fromApp: boolean;
	profileImage: string;
	createdAt: string;
	updatedAt: string;
}

export enum CustomFieldType {
	LIST = 'list',
	MULTI_SELECT = 'multi_select',
	NUMERIC = 'numeric',
	TEXT = 'string',
	DATE = 'date',
}

export type optionType = {
	value: string;
	assignedValue: string;
};

export interface CustomFieldValue {
	typeId: string;
	value: string;
}

export interface CustomFieldFormType {
	name: string;
	type: CustomFieldType;
	typeId: string;
	options: string[];
	isRequired: boolean;
	value: string | string[];
	configurations?: {
		visibility?: string[];
	};
}

export interface CustomField {
	id: string;
	typeId?: string;
	name: string;
	displayName: string;
	// eslint-disable-next-line camelcase
	display_name: string;
	type: CustomFieldType;
	options?: optionType[] | { min: number; max: number };
	entityType: string;
	entityId: string;
	scope: string;
	createdAt: string;
	updatedAt: string;
	isActive: boolean;
	isRequired: boolean;
	isValueAssigned?: boolean;
	workspaceId: string;
	configurations?: {
		visibility?: string[];
	};
}

export interface CustomFieldPayload {
	typeId: string;
	value: string | number | string[];
}

export interface AddRequestPayload {
	title: string;
	type: string;
	description: string;
	customFields: CustomFieldPayload[];
	attachments: File[];
	source: string;
	workspaceId: string;
	name?: string;
	email?: string;
	isLoggedIn?: boolean;
	boardUniqueName: string;
	portalUniqueName: string;
}

export interface AddFeedbackCommentPayload {
	text: string;
	feedbackId: string;
	files: File[];
	workspaceId: string;
	parentCommentId?: string;
	email?: string;
	name?: string;
	boardUniqueName: string;
	portalUniqueName: string;
}

export interface UpvotePayload {
	feedbackId: string;
	upvote: boolean;
	source: 'LIST' | 'BOARD' | 'DETAILS';
	boardUniqueName: string;
	portalUniqueName: string;
}

export interface Attachments {
	name: string;
	type: string;
	size: number;
	url: any;
}
