import styled from '@emotion/styled';
import { DropdownMenu, Dropdown, DropdownToggle as ReactstrapDropdownToggle } from 'reactstrap';

import { Colors } from 'theme';
import { BoxShadow } from 'theme/layout';

export { DropdownMenu } from 'reactstrap';

export const DropdownToggle = styled(ReactstrapDropdownToggle)`
	&.btn.focus,
	&.btn:focus {
		box-shadow: none;
	}
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
	background: ${Colors.white};
	border: 1px solid ${Colors.grey100};
	box-shadow: ${BoxShadow.lg};
	width: 100%;
	min-width: 150px;
	margin-top: 8px;

	border-radius: 8px;
	> * {
		display: block;
	}
	max-height: 300px;
	overflow-y: auto;
	::-webkit-scrollbar {
		width: 8px;
	}
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: ${Colors.grey200};
		border-radius: 8px;
	}
`;
export type DirectionType = 'up' | 'down' | 'left' | 'right';

export default Dropdown;
