/* eslint-disable camelcase */
export enum fontSize {
	text_xs = '12px',
	text_sm = '14px',
	text_md = '16px',
	text_lg = '18px',
	text_xl = '20px',
	display_xs = '24px',
	display_sm = '30px',
	display_md = '36px',
	display_lg = '48px',
	display_xl = '60px',
	display_2xl = '74px',
}

export enum fontWeight {
	regular = 400,
	semiBold = 500,
	bold = 700,
	black = 900,
}

export enum TextAlign {
	left = 'left',
	right = 'right',
	center = 'center',
	justify = 'justify',
}

export enum Overflow {
	auto = 'auto',
	scroll = 'scroll',
	hidden = 'hidden',
	visible = 'visible',
}

export enum TextTransform {
	lowercase = 'lowercase',
	uppercase = 'uppercase',
	capitalize = 'capitalize',
}
