import Cookies from 'js-cookie';

export const getUserAccessToken = () => Cookies.get('portalAccessToken') ?? null;

export const setUserAccessToken = (token: string) =>
	Cookies.set('portalAccessToken', token, { sameSite: 'none', secure: true });

export const removeCookie = (key: string) => Cookies.remove(key);

export const setWorkspaceId = (id: string) => localStorage.setItem('workspaceId', id);

export const getWorkspaceId = () => localStorage.getItem('workspaceId');

export const setPreviousURL = (url: string) => {
	localStorage.removeItem('previous-url');
	if (url) localStorage.setItem('previous-url', url);
};

export const getPreviousURL = () => localStorage.getItem('previous-url');

export const removePreviousURL = () => localStorage.removeItem('previous-url');
