import { useTheme } from '@emotion/react';
import React, { useContext } from 'react';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { XmarkIcon } from 'zeda-icons/v3';

import { HeaderRouterConfig } from './Header';

import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import FlexContainer from 'components/Container/FlexContainer';
import Sidebar from 'components/Sidebar';
import Text from 'components/Text';
import { envConfig } from 'constants/constants';
import { ANALYTICS_EVENTS } from 'helpers/analytics';
import { GlobalAppContext } from 'helpers/contexts';
import { formatURL, openInNewTab } from 'helpers/routes';
import { ColorFamily, FontSize, FontWeight } from 'theme';
import { FlexAlignItems, FlexDirection, FlexJustify } from 'theme/layout';
import { Sections } from 'types/app';

interface SectionType {
	displayName: string;
	canView: boolean;
	key: string;
}

interface Props {
	sections: SectionType[];
	featureRoadmapId?: string;
	isSidebarOpen?: boolean;
	toggleSidebar?: () => void;
}

const PUBLIC_ROADMAP_URL = `${envConfig.dashboard_url}/public/roadmap/`;

const NavSectionComp: React.FC<Props> = (props) => {
	const { isSidebarOpen, toggleSidebar } = props;

	const navigate = useNavigate();
	const location = useLocation();
	const { workspaceName, boardName } = useParams();
	const { isMobile, isDesktop } = useContext(GlobalAppContext);
	const { colors } = useTheme();

	const goToPublicRoadmap = () => {
		(window as WindowExtended).zedaAnalytics.track(ANALYTICS_EVENTS.CLICK_PUBLIC_FEATURE_ROADMAP);
		openInNewTab(`${PUBLIC_ROADMAP_URL}${props.featureRoadmapId}`);
	};

	const NavItems = props.sections.map((section) => {
		const routeConfig = HeaderRouterConfig[section.key as Sections];
		const isActivePath = !!matchPath(
			{ path: formatURL(`${routeConfig.link}/*`, boardName, workspaceName) },
			location.pathname
		);
		const handleItemClick = () => {
			navigate(formatURL(routeConfig.link, boardName, workspaceName));
			if (isMobile) props.toggleSidebar?.();
		};

		return (
			<Button
				key={section.key}
				onClick={handleItemClick}
				variant="tertiary"
				size="sm"
				colorTheme={isActivePath ? ColorFamily.primary : ColorFamily.grey}
				leadingIcon={routeConfig.icon}
			>
				<Text
					margin={{ left: 8 }}
					fontSize={FontSize.text_sm}
					color={isActivePath ? colors.primary700 : colors.grey700}
					fontWeight={FontWeight.semiBold}
				>
					{section.displayName}
				</Text>
			</Button>
		);
	});

	const NavSection = (
		<FlexContainer
			justify={FlexJustify.spaceBetween}
			alignItems={isMobile ? FlexAlignItems.flexStart : FlexAlignItems.center}
			margin={{ top: 8 }}
		>
			<FlexContainer
				as="nav"
				direction={isMobile ? FlexDirection.column : FlexDirection.row}
				{...(isMobile ? { rowGap: 16 } : { columnGap: 24 })}
			>
				{NavItems}
			</FlexContainer>
			{isDesktop && props.featureRoadmapId && (
				<Button
					onClick={goToPublicRoadmap}
					variant="tertiary"
					size="sm"
					colorTheme={ColorFamily.primary}
					translation="public_roadmap_url_cta"
				/>
			)}
		</FlexContainer>
	);

	if (!isMobile) return NavSection;

	return isSidebarOpen && toggleSidebar ? (
		<Sidebar
			isOpen={isSidebarOpen}
			toggle={toggleSidebar}
			width={0.7 * window.innerWidth}
			direction="left-right"
			padding={{ all: 16 }}
		>
			<>
				<IconButton
					size="sm"
					variant="tertiary"
					colorTheme={ColorFamily.grey}
					icon={XmarkIcon}
					onClick={toggleSidebar}
				/>
				{NavSection}
			</>
		</Sidebar>
	) : null;
};

export default NavSectionComp;
