import styled from '@emotion/styled';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { Share2Icon } from 'zeda-icons/v3';

import { GetFeedbackFormData, PostFeedbackForm } from 'actions/FeedbackAction';
import { ReactComponent as FeedbackSubmitted } from 'assets/images/FeedbackSubmitted.svg';
import Button from 'components/Button';
import LinkButton from 'components/Button/LinkButton';
import Container from 'components/Container/default';
import FlexContainer from 'components/Container/FlexContainer';
import Image from 'components/Image';
import Text from 'components/Text';
import ZedaBranding from 'components/ZedaBranding';
import translation from 'constants/translation';
import InvalidLink from 'containers/emptystates/InvalidLink';
import { noop } from 'helpers/utils';
import { useDeviceDimensions } from 'hooks';
import FormContainer from 'Screens/Feedback/ListView/FormContainer';
import {
	formikValidationSchemaWithNameEmail,
	FormikValueType,
} from 'Screens/Feedback/ListView/FormContainer/FeedbackForm';
import { getPortalSettings, getWorkspaceDetails, getWorkspaceImage } from 'selectors/AppSelector';
import { getExternalFeedbackFormCustomFields } from 'selectors/FeedbackSelectors';
import { FontSize, TextAlign } from 'theme';
import Colors, { ColorFamily } from 'theme/colors';
import { FlexAlignItems, FlexAlignSelf, FlexDirection, FlexJustify, Space } from 'theme/layout';
import { WorkspaceInfoType } from 'types/app';
import { AddRequestPayload } from 'types/feedbacks';
import { RootState } from 'types/state';

const StyledImage = styled(Image)`
	object-fit: contain;
`;

const FeedbackForm = (props: ReduxProps) => {
	const formId = window.location.pathname.split('/').at(-1);
	const [descriptionId, setDescriptionId] = useState<string>(Math.random().toString());
	const [attachments, setAttachments] = useState<File[]>([]);
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [isInvalidForm, setIsInvalidForm] = useState<boolean>(false);
	const [isCopying, setIsCopying] = useState<boolean>(false);
	const { isMobile } = useDeviceDimensions();

	const fetchFeedbackFormConfig = async () => {
		if (!formId) return;

		const { error } = await props.getInitialData(formId);

		if (error) {
			setIsInvalidForm(true);
		}
	};

	useEffect(() => {
		document.title = props.workspaceInfo?.name ?? 'Customer Portal';

		// Updates favIcon of the page
		const linkElement = document.querySelector('link[rel=icon]');
		if (linkElement) {
			(linkElement as HTMLAnchorElement).href = props.portalConfig?.theme.favIcon ?? '';
		}

		// Update meta description
		const descriptionElement = document.querySelector('meta[name=description]');
		if (descriptionElement) {
			(descriptionElement as HTMLMetaElement).content =
				translation.feedback_form_meta_description.replace('%1', props.workspaceInfo?.name);
		}
	}, [props.workspaceInfo]);

	useEffect(() => {
		fetchFeedbackFormConfig();
	}, [formId]);

	const handleSubmit = (
		values: FormikValueType,
		setSubmitting: (isSubmitting: boolean) => void
	) => {
		const { customFields, summary, ...rest } = values;

		const mappedCustomFields = customFields.map((field) => ({
			value: field.value,
			typeId: field.typeId,
		}));

		props.submitRequest({
			...rest,
			description: summary,
			customFields: mappedCustomFields,
			attachments,
			source: 'FEEDBACK_FORM',
			name: rest.userName,
			email: rest.email,
			workspaceId: props.workspaceInfo?.id,
			isLoggedIn: false,
		});

		setSubmitting(false);
		setIsSubmitted(true);
	};

	const formik = useFormik<FormikValueType>({
		initialValues: {
			title: '',
			summary: '',
			type: '',
			customFields: props.customFields,
			userName: '',
			email: '',
		},
		enableReinitialize: true,
		validationSchema: formikValidationSchemaWithNameEmail,
		onSubmit: (values, { setSubmitting }) => {
			handleSubmit(values, setSubmitting);
			formik.resetForm();
			setDescriptionId(Math.random().toString());
			setAttachments([]);
		},
	});

	const handleCopyLink = () => {
		setIsCopying(true);
		navigator.clipboard.writeText(window.location.href);
		setTimeout(() => setIsCopying(false), 500);
	};

	if (isInvalidForm) return <InvalidLink />;

	const Header = (
		<FlexContainer
			height="100%"
			maxWidth={700}
			alignItems={FlexAlignItems.center}
			margin={{ left: Space.auto, right: Space.auto }}
		>
			<StyledImage
				width={32}
				height={32}
				src={props.workspaceImage}
				title={props.workspaceInfo?.name}
				alt={props.workspaceInfo?.name ?? translation.customer_portal_logo}
			/>
			<Text margin={{ left: 8 }}>{props.workspaceInfo?.name}</Text>
			<Button
				size="sm"
				leadingIcon={Share2Icon}
				variant="secondary"
				onClick={handleCopyLink}
				margin={{ left: Space.auto }}
				colorTheme={ColorFamily.grey}
				{...(isCopying && {
					colorTheme: ColorFamily.success,
					translation: 'copied',
				})}
			/>
		</FlexContainer>
	);

	const FormSubmittedState = (
		<FlexContainer alignItems={FlexAlignItems.center} width="100%" direction={FlexDirection.column}>
			<FeedbackSubmitted height={300} width={300} />
			<Text
				color={Colors.grey900}
				fontSize={FontSize.text_xl}
				textAlign={TextAlign.center}
				margin={{ top: 16, bottom: 4 }}
				translation="feedback_submitted"
			/>
			<Text
				maxWidth={400}
				color={Colors.grey500}
				fontSize={FontSize.text_xs}
				textAlign={TextAlign.center}
				translation="feedback_submitted_subtext"
			/>
			<LinkButton
				margin={{ top: 16 }}
				translation="submit_another"
				alignSelf={FlexAlignSelf.center}
				onClick={() => setIsSubmitted(false)}
			/>
			<ZedaBranding margin={{ top: 100 }} />
		</FlexContainer>
	);

	return (
		<Container minHeight="100vh" bgColor={Colors.white} padding={{ bottom: 16 }}>
			<Container padding={{ all: 16 }} height={56} border={{ bottom: 1, color: Colors.grey200 }}>
				{Header}
			</Container>
			<FlexContainer
				height="100%"
				maxWidth={600}
				bgColor={Colors.white}
				width="calc(100vw - 32px)"
				justify={FlexJustify.center}
				direction={FlexDirection.column}
				margin={{ left: Space.auto, right: Space.auto, top: 40 }}
				alignItems={FlexAlignItems.center}
				{...(!isMobile && {
					border: { all: 1, color: Colors.grey200, radius: 8 },
					padding: { all: 16 },
				})}
			>
				{isSubmitted ? (
					FormSubmittedState
				) : (
					<FormContainer
						width="100%"
						toggle={noop}
						isTransparent
						formik={formik}
						isExternalForm
						isSubmitted={false}
						attachments={attachments}
						descriptionId={descriptionId}
						setAttachments={setAttachments}
						setIsSubmitted={setIsSubmitted}
					/>
				)}
			</FlexContainer>
		</Container>
	);
};

const mapStateToProps = (state: RootState, props: any) => ({
	customFields: getExternalFeedbackFormCustomFields(state),
	workspaceInfo: getWorkspaceDetails(state) as WorkspaceInfoType,
	portalConfig: getPortalSettings(state),
	userInfo: state.userReducer.userInfo,
	workspaceImage: getWorkspaceImage(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	submitRequest: (body: Omit<AddRequestPayload, 'boardUniqueName' | 'portalUniqueName'>) =>
		dispatch(PostFeedbackForm.request(body)),
	getInitialData: (formId: string) => dispatch(GetFeedbackFormData.request(formId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(FeedbackForm);
