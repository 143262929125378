import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import {
	TriangleExclamationIcon,
	CircleCheckIcon,
	CircleExclamationIcon,
	CircleInformationIcon,
} from 'zeda-icons/v3';

import Toast from './Toast';

import { convertHexToRGBA } from 'helpers/theme';
import { Colors, FontSize } from 'theme';

export enum TOAST_TYPES {
	SUCCESS = 'success',
	INFO = 'info',
	ERROR = 'error',
	WARNING = 'warning',
}

interface ToastConfigProps {
	bgColor: Colors;
	iconBorder: Colors;
	iconBg: Colors;
	method: ToastType;
	defaultTitle: string;
	icon: JSX.Element;
}

interface ConfigProps {
	[key: string]: ToastConfigProps;
}

type ToastType = 'warn' | 'info' | 'success' | 'error';

export const ToastConfig: ConfigProps = {
	[TOAST_TYPES.SUCCESS]: {
		bgColor: Colors.successSecondary,
		iconBorder: convertHexToRGBA(Colors.success600, 0.3) as Colors,
		iconBg: Colors.success600,
		method: 'success',
		defaultTitle: 'Success!',
		icon: <CircleCheckIcon fontSize={FontSize.text_sm} color={Colors.white} />,
	},
	[TOAST_TYPES.ERROR]: {
		bgColor: Colors.errorSecondary,
		iconBorder: convertHexToRGBA(Colors.error600, 0.3) as Colors,
		iconBg: Colors.error600,
		method: 'error',
		defaultTitle: 'Error!',
		icon: <TriangleExclamationIcon fontSize={FontSize.text_md} color={Colors.white} />,
	},
	[TOAST_TYPES.WARNING]: {
		bgColor: Colors.warningSecondary,
		iconBorder: convertHexToRGBA(Colors.warning400, 0.3) as Colors,
		iconBg: Colors.warning400,
		method: 'warn',
		defaultTitle: 'Warning!',
		icon: <CircleExclamationIcon fontSize={FontSize.text_md} color={Colors.white} />,
	},
	[TOAST_TYPES.INFO]: {
		bgColor: Colors.infoSecondary,
		iconBorder: convertHexToRGBA(Colors.info600, 0.3) as Colors,
		iconBg: Colors.info600,
		method: 'info',
		defaultTitle: 'Info!',
		icon: <CircleInformationIcon fontSize={FontSize.text_md} color={Colors.white} />,
	},
};

export const showToaster = (
	value: string = TOAST_TYPES.SUCCESS,
	title: string,
	subTitle?: string | null,
	options: ToastOptions = {}
) => {
	const ToastType = ToastConfig[value];

	toast[ToastType.method](
		<Toast
			text={title}
			subtext={subTitle}
			bgColor={ToastType.bgColor}
			iconBorder={ToastType.iconBorder}
			iconBg={ToastType.iconBg}
		>
			{ToastType.icon}
		</Toast>,
		{ ...options }
	);
};
