import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Carousel, CarouselItem } from 'reactstrap';
import { XmarkIcon, ChevronRightIcon } from 'zeda-icons/v3';

import { ReactComponent as NoPreview } from 'assets/images/NoPreview.svg';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import FlexContainer from 'components/Container/FlexContainer';
import GridContainer from 'components/Container/GridContainer';
import Image from 'components/Image';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { downloadFile } from 'helpers/utils';
import { useDeviceDimensions } from 'hooks';
import { ColorFamily, Colors, FontSize, FontWeight, TextAlign } from 'theme';
import { FlexAlignItems, FlexDirection, FlexJustify, Position, Space } from 'theme/layout';
import { FileType } from 'types/feedbacks';

interface PreviewProps {
	data: FileType[] | File[];
	activeIndex?: number;
	toggle: () => void;
	isOpen: boolean;
	isLocalFile?: boolean;
}

const contentVisibleAreaHeight = Math.min(0.7 * window.innerHeight, 580) - (40 + 32 + 56);

const StyledLeftIcon = styled(ChevronRightIcon)`
	transform: rotate(180deg);
`;

const StyledGridContainer = styled(GridContainer)`
	.carousel-inner {
		text-align: center;
		background-color: ${Colors.bluelight200};
		height: 100%;
		border-radius: 8px;
	}
	.carousel-item {
		height: 100%;
	}
	.carousel.slide {
		overflow: hidden;
	}
`;

const StyledImage = styled(Image)`
	max-width: 100%;
	max-height: 100%;
`;

const StyledModal = styled(Modal)`
	background-color: ${Colors.white} !important;
	padding: 16px 24px 24px;
`;

const StyledButton = styled(Button)`
	span {
		margin: auto;
	}
`;

const ImageCarousel = styled(Carousel)`
	.carousel-inner {
		background-color: ${Colors.bluegrey50};
	}
`;

const SUPPORTED_IMAGE_TYPES = /image\/(png|jpe?g)/;
const SUPPORTED_VIDEO_TYPES = /video\/(mp4|webm)/;
const NoPreviewContent = (
	<FlexContainer
		textAlign={TextAlign.center}
		bgColor={Colors.transparent}
		height="100%"
		direction={FlexDirection.column}
		justify={FlexJustify.center}
		alignItems={FlexAlignItems.center}
	>
		<NoPreview />
		<Text
			display="block"
			translation="no_preview"
			margin={{ top: 32 }}
			color={Colors.grey500}
			fontSize={FontSize.text_xl}
			fontWeight={FontWeight.bold}
		/>
	</FlexContainer>
);

const PreviewModal: React.FC<PreviewProps> = (props) => {
	const { data, isLocalFile = false } = props;
	const itemCount = data?.length;
	const { isMobile } = useDeviceDimensions();

	const [activeIndex, setActiveIndex] = useState(props.activeIndex ?? 0);
	useEffect(() => {
		setActiveIndex(props.activeIndex ?? 0);
	}, [props.activeIndex]);

	const activeFileName = isLocalFile
		? (data?.[activeIndex] as File)?.name
		: (data?.[activeIndex] as FileType)?.fileName;

	const activeFileUrl =
		isLocalFile && data?.[activeIndex]
			? URL.createObjectURL(data?.[activeIndex] as File)
			: (data?.[activeIndex] as FileType)?.url;

	const handleNext = () => {
		setActiveIndex((index) => (index === itemCount - 1 ? 0 : index + 1));
	};

	const handlePrev = () => {
		setActiveIndex((index) => (index === 0 ? itemCount - 1 : index - 1));
	};

	const handleDownloadAttachment = () => {
		downloadFile(activeFileUrl, activeFileName);
	};

	const CarouselItems = data?.map((file: FileType | File) => {
		const fileName = isLocalFile ? (file as File).name : (file as FileType).fileName;
		const fileType = isLocalFile ? (file as File).type : (file as FileType).fileType;

		if (SUPPORTED_IMAGE_TYPES.test(fileType)) {
			return (
				<CarouselItem key={fileName}>
					<StyledImage
						alt={fileName}
						width="auto"
						height="auto"
						loading="eager"
						src={isLocalFile ? URL.createObjectURL(file as File) : (file as FileType).url}
					/>
				</CarouselItem>
			);
		}

		if (SUPPORTED_VIDEO_TYPES.test(fileType)) {
			return (
				<CarouselItem>
					<video width={720} height={contentVisibleAreaHeight - 32} controls>
						<source
							type={fileType}
							src={isLocalFile ? URL.createObjectURL(file as File) : (file as FileType).url}
						/>
					</video>
				</CarouselItem>
			);
		}

		// To Discuss
		return <CarouselItem key={fileName}>{NoPreviewContent}</CarouselItem>;
	});

	return (
		<StyledModal
			isOpen={props.isOpen}
			toggle={props.toggle}
			width={!isMobile ? 900 : 327}
			height={572}
			minHeight={572}
			maxHeight={774}
			padding={{ all: 24 }}
			border={{ radius: 12 }}
		>
			<StyledGridContainer
				rowGap={16}
				border={{ radius: 8 }}
				height="100%"
				gridTemplateConfig={{ row: ['auto', '1fr', 'auto'] }}
			>
				<FlexContainer columnGap={8} alignItems={FlexAlignItems.center}>
					<Text
						margin={{ right: Space.auto, left: Space.auto }}
						fontSize={FontSize.text_lg}
						fontWeight={FontWeight.semiBold}
						color={Colors.grey900}
						lineHeight="28px"
					>
						{activeFileName}
					</Text>
					<IconButton
						icon={XmarkIcon}
						onClick={props.toggle}
						variant="tertiary"
						colorTheme={ColorFamily.grey}
					/>
				</FlexContainer>
				<ImageCarousel
					interval={false}
					next={handleNext}
					previous={handlePrev}
					activeIndex={activeIndex}
				>
					{CarouselItems}
					<IconButton
						icon={StyledLeftIcon}
						size="md"
						onClick={handlePrev}
						variant="tertiary"
						colorTheme={ColorFamily.grey}
						border={{ radius: 20 }}
						position={{ value: Position.fixed, top: '50%', bottom: 0, left: 5 }}
					/>
					<IconButton
						icon={ChevronRightIcon}
						size="md"
						onClick={handleNext}
						variant="tertiary"
						colorTheme={ColorFamily.grey}
						border={{ radius: 20 }}
						position={{ value: Position.fixed, top: '50%', bottom: 0, right: 5 }}
					/>
				</ImageCarousel>
				<StyledButton
					variant="primary"
					size="lg"
					translation="download"
					width={194}
					margin={{ left: Space.auto, right: Space.auto, top: 16 }}
					onClick={handleDownloadAttachment}
				/>
			</StyledGridContainer>
		</StyledModal>
	);
};

export default PreviewModal;
