import styled from '@emotion/styled';
import React from 'react';
import { ArrowRightFromBracketIcon, ArrowUpRightIcon } from 'zeda-icons/v3';

import Avatar, { AvatarSize } from 'components/Avatar';
import Container from 'components/Container/default';
import GridContainer from 'components/Container/GridContainer';
import Dropdown, { StyledDropdownMenu, DropdownToggle } from 'components/Dropdown';
import { StyledDefaultDropdownItem } from 'components/Dropdown/DropdownItem/default';
import { DefaultDropdownStylingProps } from 'components/Dropdown/DropdownItem/DropdownItem';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip/default';
import { getAvatarImageUrl } from 'helpers/utils';
import { useToggle } from 'hooks';
import { Colors, FontSize, FontWeight } from 'theme';
import { Space } from 'theme/layout';
import { UserInfo } from 'types/user';

const ProfileDropdown = styled(Dropdown)`
	margin-left: ${Space.auto};
	position: relative;

	.dropdown-menu {
		right: 12px !important;
		left: unset !important;
		top: 44px !important;
		transform: none !important;
		z-index: 100;
		width: 195px;
	}
`;

const StyledToggle = styled(DropdownToggle)`
	outline: none;
	background: transparent;
	border: none;
	cursor: pointer;

	:hover,
	:focus,
	:active {
		background: none !important;
		border: none !important;
		box-shadow: none !important;
	}
`;

const DropdownItem = styled(StyledDefaultDropdownItem)`
	display: flex;
	align-items: center;
`;

const UserInfoDropdownItem = styled(DropdownItem)`
	:hover {
		background-color: white !important;
	}
	cursor: default !important;
	padding: 0 !important;
`;

const CustomText = styled(Text)`
	display: block !important;
`;

interface ProfileProps {
	userInfo: UserInfo;
	onLogout: () => void;
	redirectToDashboard: () => void;
}

const Profile: React.FC<ProfileProps> = (props) => {
	const [showOptions, setShowOptions] = useToggle(false);

	const handleToggle = () => {
		setShowOptions();
	};

	return (
		<ProfileDropdown isOpen={showOptions} toggle={handleToggle}>
			<StyledToggle>
				<Avatar
					src={props.userInfo.image ?? getAvatarImageUrl({ name: props.userInfo.name })}
					size={AvatarSize.s}
				/>
			</StyledToggle>
			{showOptions && (
				<StyledDropdownMenu>
					<UserInfoDropdownItem height={60} width={192}>
						<GridContainer
							gridTemplateConfig={{ column: ['auto', '1fr'] }}
							columnGap={8}
							padding={{ all: 8 }}
							bgColor={Colors.grey100}
							border={{ radius: 8 }}
							margin={{ left: 4 }}
						>
							<Avatar
								src={props.userInfo.image ?? getAvatarImageUrl({ name: props.userInfo.name })}
								size={AvatarSize.s}
							/>
							<Container>
								<CustomText
									color={Colors.grey900}
									fontSize={FontSize.text_sm}
									fontWeight={FontWeight.semiBold}
									ellipsis
									width={129}
									id="user_name"
								>
									{props.userInfo.name}
								</CustomText>
								<Tooltip target="user_name">{props.userInfo.name}</Tooltip>
								<Text
									color={Colors.grey600}
									fontSize={FontSize.text_xs}
									fontWeight={FontWeight.regular}
									ellipsis
									width={129}
									id="user_email"
								>
									{props.userInfo.email}
								</Text>
								<Tooltip target="user_email">{props.userInfo.email}</Tooltip>
							</Container>
						</GridContainer>
					</UserInfoDropdownItem>
					{props.userInfo.isAppUser && (
						<DropdownItem
							{...DefaultDropdownStylingProps}
							width={192}
							onClick={props.redirectToDashboard}
						>
							<ArrowUpRightIcon color={Colors.grey900} fontSize={FontSize.text_xs} />
							<Text
								fontSize={FontSize.text_md}
								color={Colors.grey900}
								fontWeight={FontWeight.regular}
								margin={{ left: 8 }}
								translation="back_to_dashboard_cta"
							/>
						</DropdownItem>
					)}
					<DropdownItem {...DefaultDropdownStylingProps} width={192} onClick={props.onLogout}>
						<ArrowRightFromBracketIcon color={Colors.grey900} fontSize={FontSize.text_xs} />
						<Text
							fontSize={FontSize.text_md}
							color={Colors.grey900}
							fontWeight={FontWeight.regular}
							margin={{ left: 8 }}
							translation="logout_cta"
						/>
					</DropdownItem>
				</StyledDropdownMenu>
			)}
		</ProfileDropdown>
	);
};

export default Profile;
