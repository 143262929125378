import { BubbleMenuOptions, ShortcutOptions } from 'zeda-editor';

export enum EditorType {
	CARD = 'card',
	RICH_CARD = 'rich_card',
}

const { bold, italic, underLine, bulletList, numberedList: orderedList } = BubbleMenuOptions;
const { heading1, heading2, heading3, bulletedList, numberedList, image } = ShortcutOptions;

export const EDITOR_TYPE_INFO = {
	[EditorType.CARD]: {
		enabledShortcuts: [],
		bubbleMenu: [bold, italic, underLine, bulletList, orderedList],
	},
	[EditorType.RICH_CARD]: {
		enabledShortcuts: [heading1, heading2, heading3, bulletedList, numberedList, image],
		bubbleMenu: [bold, italic, underLine, bulletList, orderedList],
	},
};
