import { reduce, pick } from 'lodash';
import { Dispatch } from 'redux';

import { LOADING_STATUS } from 'constants/constants';
import { ReduxAction, ReduxActionWithData } from 'types';

type ActionConstants = 'request' | 'response' | 'loading' | 'error';

export const generateConstants = (
	str: string,
	extraMethods: string[] = []
): { [key in ActionConstants]?: string } => {
	const actions = ['request', 'response', 'loading', 'error', ...extraMethods];
	return reduce(
		actions,
		(acc, action) => {
			if (action) {
				return { ...acc, [action]: `${str.toUpperCase()}_${action.toUpperCase()}` };
			}
			return acc;
		},
		{}
	);
};

export class ReduxAsyncAction {
	constants;

	request: any;

	dispatch: any;

	constructor(actionNamespace: string, extraActions?: string[]) {
		this.constants = generateConstants(actionNamespace, extraActions);
	}

	registerRequest(promiseClosure: any) {
		this.request = (...args: any) => {
			return (dispatch: Dispatch) => {
				dispatch({ type: this.constants.request, ...args });
				this.dispatch = dispatch;

				return promiseClosure
					.apply(this, args)
					.then((data: any) => {
						dispatch({ type: this.constants.response, data });
						return { data };
					})
					.catch((error: any) => {
						dispatch({
							type: this.constants.error,
							error: pick(error, ['data', 'status', 'statusText']) || error,
						});
						return { error };
					});
			};
		};
	}
}

export const reducerWrapper = <State, Actions>(
	initState: State,
	handler: (state: State, action: Actions) => State,
	options?: {
		[key in ActionConstants]?: Array<{ [constant in ActionConstants]?: string }>;
	}
) => {
	return (state = initState, action: ReduxAction<string> | ReduxActionWithData<string, any>) => {
		// actions included in the request object in options will have corresponding loading status action dispatched
		if (options?.request?.length) {
			const actionWithLoader = options.request.find(
				(loaderAction) => loaderAction.request === action.type || loaderAction.error === action.type
			);

			if (actionWithLoader && action.type === actionWithLoader.request) {
				return { ...state, loadingStatus: actionWithLoader.loading };
			}

			if (actionWithLoader && action.type === actionWithLoader.error) {
				return { ...state, loadingStatus: LOADING_STATUS.INACTIVE };
			}
		}

		return handler(state, action as unknown as Actions);
	};
};
