import styled from '@emotion/styled';
import React from 'react';
import { DropdownItem } from 'reactstrap';

import { DropdownItemBaseProps } from './DropdownItem';

import { Colors, FontSize, FontWeight } from 'theme';
import { Border, Flex, Grid, Margin, Padding, Position } from 'theme/mixins';

const StyledDropdownItem = styled(DropdownItem)<DropdownItemBaseProps>`
	background-color: ${({ bgColor = Colors.transparent }) => bgColor};
	width: ${({ width }) => (width && typeof width === 'number' ? `${width}px` : width)};
	min-width: ${({ minWidth }) =>
		minWidth && typeof minWidth === 'number' ? `${minWidth}px` : minWidth};
	max-width: ${({ maxWidth }) =>
		maxWidth && typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
	min-width: ${({ minWidth }) =>
		minWidth && typeof minWidth === 'number' ? `${minWidth}px` : minWidth};
	height: ${({ height }) => (height && typeof height === 'number' ? `${height}px` : height)};
	max-height: ${({ maxHeight }) =>
		maxHeight && typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight};
	min-height: ${({ minHeight }) =>
		minHeight && typeof minHeight === 'number' ? `${minHeight}px` : minHeight};
	text-align: ${({ textAlign }) => textAlign};
	cursor: ${({ cursor, disabled }) => (disabled ? 'default' : cursor)};
	${({ color, theme }) => color && `color: ${theme.colors[color]}`};
	${({ fontSize }) => fontSize && `font-size: ${FontSize[fontSize]}`};
	${({ fontWeight }) => fontWeight && `font-weight: ${FontWeight[fontWeight]}`};
	${Flex}
	${Grid}
	${Padding}
	${Margin}
	${Border}
	${Position}

	&.dropdown-item.active, &.dropdown-item:active {
		color: inherit;
		background-color: inherit;
	}
`;

export const DefaultDropdownItem = (props: DropdownItemBaseProps) => {
	return <StyledDropdownItem {...props} />;
};

DefaultDropdownItem.displayName = 'DefaultDropdownItem';

export const StyledDefaultDropdownItem = styled(DefaultDropdownItem)`
	${({ disabled }) =>
		!disabled &&
		`:hover {
			background-color: ${Colors.grey50} !important;
		} `}
`;
