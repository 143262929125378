import {
	FetchUserInfoType,
	FETCH_USER_INFO,
	LOGOUT,
	UpdateUserInfoType,
	UserActions,
} from 'actions/UserAction';
import { reducerWrapper } from 'helpers/action';
import { UserState } from 'types/user';

const USER_INIT_STATE: UserState = {
	userInfo: null,
};

const Handler = (state = USER_INIT_STATE, action: UserActions): UserState => {
	switch (action.type) {
		case FETCH_USER_INFO.response: {
			const { data } = action as FetchUserInfoType;
			return { ...state, userInfo: data };
		}
		case 'UPDATE_USER': {
			const { data } = action as UpdateUserInfoType;
			return { ...state, userInfo: data };
		}
		case LOGOUT.response:
			return { ...state, userInfo: null };
		default:
			return state;
	}
};

const UserReducer = reducerWrapper<UserState, UserActions>(USER_INIT_STATE, Handler);

export default UserReducer;
