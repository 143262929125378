import { WorkspaceInfoType } from 'types/app';
import { UserInfo } from 'types/user';

export enum ANALYTICS_EVENTS {
	CREATE_FEEDBACK = 'create_feedback',
	UPVOTE_FEEDBACK = 'upvotes_customer_portal',
	COMMENT_FEEDBACK = 'comments_customer_portal',
	LIKE_RELEASE_NOTES = 'likes_customer_portal',
	CLICK_PUBLIC_FEATURE_ROADMAP = 'click_public_roadmap_customer_portal',
}

class AnalyticsHelper {
	static user: UserInfo;

	static workspace: WorkspaceInfoType;

	static windowObj = window as WindowExtended;

	static identify(user: UserInfo, workspace: WorkspaceInfoType) {
		this.user = user;
		this.workspace = workspace;

		const traits = {
			platform: 'customer_portal',
			workspaceId: workspace.id,
			workspaceName: workspace.name,
			...(user && {
				userName: user.name,
				userId: user.id,
			}),
		};

		// For logged in users
		if (user) {
			this.windowObj?.analytics?.identify?.(user.id, traits);
			return;
		}

		// For users who aren't logged in
		this.windowObj?.analytics?.identify?.(traits);
	}

	static track(event: ANALYTICS_EVENTS, options = {}) {
		this.windowObj?.analytics?.track?.(event, options);
	}
}

export default AnalyticsHelper;
