import styled from '@emotion/styled';

import FlexContainer, { FlexProps } from './FlexContainer';

import { GridTemplate } from 'theme/mixins';

export interface GridProps extends FlexProps {
	gridTemplateConfig?: {
		row?: string[];
		column?: string[];
	};
}

const GridContainer = styled(FlexContainer)<GridProps>`
	display: grid;
	list-style: none;
	${GridTemplate}
`;

export default GridContainer;
