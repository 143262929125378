import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import Container from 'components/Container/default';
import GridContainer from 'components/Container/GridContainer';
import Header from 'containers/Header';
import { HEADER_HEIGHT, HEADER_INLINE_SPACING } from 'containers/Header/Header';
import { GlobalAppContext } from 'helpers/contexts';

const Layout = () => {
	const { currentScreen, isDesktop, isMobile } = useContext(GlobalAppContext);

	return (
		<>
			<Header />
			<GridContainer
				className="app-content"
				margin={{
					top: HEADER_HEIGHT[currentScreen],
					left: HEADER_INLINE_SPACING[currentScreen],
					right: HEADER_INLINE_SPACING[currentScreen],
				}}
				gridTemplateConfig={{ column: Array.from({ length: 12 }, () => '1fr') }}
				padding={{ top: isDesktop ? 24 : 16 }}
				{...(isDesktop && { maxWidth: 1280 })}
			>
				<Container
					gridColumnStart={isMobile ? 1 : 2}
					gridColumnEnd={isMobile ? 13 : 12}
					{...(isMobile && { padding: { left: 16, right: 16 } })}
				>
					<Outlet />
				</Container>
			</GridContainer>
		</>
	);
};

export default Layout;
