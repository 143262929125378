import styled from '@emotion/styled';
import React from 'react';

import { DefaultTagProps } from './default';

import FlexContainer from 'components/Container/FlexContainer';
import Text from 'components/Text';
import { getColorByThemeAndGradient } from 'helpers/theme';
import { ColorFamily, FontSize, Colors } from 'theme';
import { Translation } from 'types';

export enum TagVariant {
	sm = 'sm',
	md = 'md',
	lg = 'lg',
}

export enum TagType {
	badge = 'badge',
	tag = 'tag',
}

type OnClose =
	| {
			/**
			 * Id to uniquely identify the Tag
			 */
			id: never;
			/**
			 * onClose if called when the close icon is called.
			 * @param id
			 * id refers to the id of the component
			 */
			onClose: never;
	  }
	| {
			/**
			 * Id to uniquely identify the Tag
			 */
			id: string;
			/**
			 * onClose if called when the close icon is called.
			 * @param id
			 * id refers to the id of the component
			 */
			onClose?: (id: string | number) => void;
	  };

export type TagProps = OnClose &
	Omit<DefaultTagProps, 'children'> & {
		/**
		 * Type of Tag | Badge
		 */
		type?: TagType;
		/**
		 * Variant of Tag sm | mg | lg to select the size
		 */
		variant?: TagVariant;
		/**
		 * Label refers to the text to be show to the component
		 */
		label: string | Translation;
		/**
		 * Color Family tos specify the color variant of label
		 */
		colorFamily?: ColorFamily;
		/**
		 * Prefix refers to the Icon | Avatar | Dot which can appear before the tag label
		 */
		prefix?: React.ReactElement;
		/**
		 * Suffix refers to the Icon which can appear after of the tag label
		 */
		suffix?: React.ElementType;
	};

export interface AvatarTagProps extends Omit<TagProps, 'prefix'> {
	/**
	 * src is the source of icon that is to sent to the avatar.
	 */
	src: string;
}

export interface DotTagProps extends Omit<TagProps, 'prefix'> {
	/**
	 * color refers to the color of the dot rendered before the label.
	 */
	color: string;
}

export interface IconTagProps extends Omit<TagProps, 'prefix'> {
	/**
	 * icon is a React element passed to the label to render before the label. Will have the color as
	 * per the color family.
	 */
	icon: React.FC<IconPropType>;
}

export const TagStyle = (hasPrefix: boolean, hasSuffix: boolean) => ({
	[TagVariant.sm]: {
		padding: { top: 2, bottom: 2, left: 8, right: 8 },
		gap: {
			left: hasPrefix ? 2 : 0,
			right: hasSuffix ? 2 : 0,
		},
		fontSize: FontSize.text_xs,
	},
	[TagVariant.md]: {
		padding: { top: 4, bottom: 4, left: 8, right: 8 },
		gap: {
			left: hasPrefix ? 2 : 0,
			right: hasSuffix ? 2 : 0,
		},
		fontSize: FontSize.text_sm,
	},
	[TagVariant.lg]: {
		padding: { top: 6, bottom: 6, left: 10, right: 10 },
		gap: {
			left: hasPrefix ? 4 : 0,
			right: hasSuffix ? 4 : 0,
		},
		fontSize: FontSize.text_sm,
	},
});

export const BadgeStyle = (hasPrefix: boolean, hasSuffix: boolean) => ({
	[TagVariant.sm]: {
		padding: { all: 2 },
		gap: {
			left: hasPrefix ? 6 : 6,
			right: hasSuffix ? 4 : 6,
		},
		fontSize: FontSize.text_xs,
	},
	[TagVariant.md]: {
		padding: { all: 2 },
		gap: {
			left: hasPrefix ? 6 : 8,
			right: hasSuffix ? 4 : 8,
		},
		fontSize: FontSize.text_sm,
	},
	[TagVariant.lg]: {
		padding: { all: 4 },
		gap: {
			left: hasPrefix ? 6 : 10,
			right: hasSuffix ? 4 : 10,
		},
		fontSize: FontSize.text_sm,
	},
});

export const getVariantStyle = (
	colorFamily: ColorFamily,
	hasPrefix: boolean,
	hasSuffix: boolean,
	variant: TagVariant,
	type: TagType
) => {
	const tagStyle = {
		border: { radius: 4, all: 1, color: getColorByThemeAndGradient(colorFamily, 200) },
		bgColor: getColorByThemeAndGradient(colorFamily, 100),
	};

	const badgeStyle = {
		border: { radius: 16 },
		bgColor: getColorByThemeAndGradient(colorFamily, 100),
	};

	return type === TagType.tag
		? { ...TagStyle(hasPrefix, hasSuffix)[variant], ...tagStyle }
		: { ...BadgeStyle(hasPrefix, hasSuffix)[variant], ...badgeStyle };
};

export const getAvatarGapForBadge = (variant: TagVariant) => {
	switch (variant) {
		case TagVariant.sm:
		case TagVariant.md:
			return 1;
		case TagVariant.lg:
			return 2;
		default:
			return 0;
	}
};

export const getIconSize = (variant: TagVariant) => {
	switch (variant) {
		case TagVariant.sm:
		case TagVariant.md:
			return 16;
		case TagVariant.lg:
			return 18;
		default:
			return 0;
	}
};

export const Label = styled(Text)`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const IconContainer = styled(FlexContainer)<{ colorFamily?: ColorFamily; size: number }>`
	${({ size }) => `
		height: ${size}px;
		width: ${size}px;
	`}
	:hover {
		${({ colorFamily }) => `
			background: ${colorFamily ? getColorByThemeAndGradient(colorFamily, 100) : Colors.transparent};
		`}
		border-radius: 4px;
	}
`;
