import { AnyAction } from 'redux';

import {
	FETCH_ALL_RELEASE_NOTES,
	FETCH_RELEASE_NOTE_DETAILS,
	FETCH_RELEASE_NOTES_CONFIG,
	FetchAllReleaseNotesType,
	FetchReleaseNoteDetailsType,
	FetchReleaseNotesConfigType,
	POST_RELEASE_NOTE_LIKE,
	PostReleaseNoteLikeType,
	REMOVE_RELEASE_NOTE_LIKE,
	RemoveReleaseNoteLikeType,
} from 'actions/ReleaseNoteActions';
import { reducerWrapper } from 'helpers/action';
import { ReleaseNoteState } from 'types/releaseNote';

export const RELEASE_NOTE_INIT_STATE: ReleaseNoteState = {
	labels: [],
	categories: [],
	isLoading: false,
	releaseNoteList: [],
	releaseNoteDetails: null,
};

const handler = (state = RELEASE_NOTE_INIT_STATE, action: AnyAction): ReleaseNoteState => {
	switch (action.type) {
		case FETCH_RELEASE_NOTES_CONFIG.response: {
			const { data } = action as FetchReleaseNotesConfigType;
			return {
				...state,
				categories: data.categories ?? [],
				labels: data.labels ?? [],
			};
		}
		case FETCH_ALL_RELEASE_NOTES.request: {
			return {
				...state,
				isLoading: true,
			};
		}
		case FETCH_ALL_RELEASE_NOTES.response: {
			const { data } = action as FetchAllReleaseNotesType;
			return {
				...state,
				isLoading: false,
				releaseNoteList: data.data,
			};
		}
		case FETCH_RELEASE_NOTE_DETAILS.request: {
			return {
				...state,
				isLoading: true,
			};
		}
		case FETCH_RELEASE_NOTE_DETAILS.response: {
			const { data } = action as FetchReleaseNoteDetailsType;
			return {
				...state,
				isLoading: false,
				releaseNoteDetails: data.data,
			};
		}
		case POST_RELEASE_NOTE_LIKE.response: {
			const { data } = action as PostReleaseNoteLikeType;

			const updatedReleaseNoteList = state.releaseNoteList?.map((releaseNote) => {
				if (releaseNote.id === data.releaseNoteId) {
					return { ...releaseNote, isReacted: true, likeCount: releaseNote.likeCount + 1 };
				}
				return releaseNote;
			});

			if (!state.releaseNoteDetails)
				return {
					...state,
					releaseNoteList: updatedReleaseNoteList,
				};

			const likeCount = state.releaseNoteDetails?.likeCount ?? 0;

			return {
				...state,
				releaseNoteList: updatedReleaseNoteList,
				releaseNoteDetails: {
					...state.releaseNoteDetails,
					isReacted: true,
					reactedBy: data.reactedBy ?? [],
					likeCount: likeCount + 1,
				},
			};
		}
		case REMOVE_RELEASE_NOTE_LIKE.response: {
			const { data } = action as RemoveReleaseNoteLikeType;

			const updatedReleaseNoteList = state.releaseNoteList?.map((releaseNote) => {
				if (releaseNote.id === data.releaseNoteId) {
					return {
						...releaseNote,
						isReacted: false,
						likeCount: Math.max(0, releaseNote.likeCount - 1),
					};
				}
				return releaseNote;
			});

			if (!state.releaseNoteDetails)
				return {
					...state,
					releaseNoteList: updatedReleaseNoteList,
				};

			const likeCount = state.releaseNoteDetails.likeCount ?? 0;

			return {
				...state,
				releaseNoteList: updatedReleaseNoteList,
				releaseNoteDetails: {
					...state.releaseNoteDetails,
					isReacted: false,
					reactedBy: data.reactedBy ?? [],
					likeCount: Math.max(0, likeCount - 1),
				},
			};
		}
		default:
			return state;
	}
};

const ReleaseNoteReducer = reducerWrapper<ReleaseNoteState, AnyAction>(
	RELEASE_NOTE_INIT_STATE,
	handler,
	{ request: [FETCH_RELEASE_NOTE_DETAILS] }
);

export default ReleaseNoteReducer;
