import cx from 'classnames';
import { getIn } from 'formik';
import React, { useContext, useState } from 'react';

import Calendar from '..';
import { LabelPosition, SelectBaseProps, ValueType } from '../calendarTypes';

import { CustomDropdown, CustomDropdownMenu, inlineStyle, Label } from './SelectCalendar';

import Text from 'components/Text';
import { FormikContext } from 'helpers/contexts';
import { Colors, FontSize, FontWeight } from 'theme';
import { Position } from 'theme/layout';

const Select = (props: SelectBaseProps) => {
	const {
		label,
		labelClass,
		options = [],
		bgColor,
		width,
		height = 46,
		toggleButton: ToggleButton,
		isInlineView = false,
		labelPosition = LabelPosition.top,
		margin,
		border,
		flexGrow,
		flexShrink,
		flexBasis,
		container = 'body',
		id,
		isRichCalendar = false,
		isRangeEnabled = false,
		zIndex = 0,
		...rest
	} = props;
	const { name = '', direction = 'down' } = props;
	const [isOpen, setIsOpen] = useState(false);
	const { formik }: { formik: any } = useContext(FormikContext);
	const isFilled = !!props.value;
	const isInvalid = name && getIn(formik?.errors, name) && getIn(formik?.touched, name);
	const borderBaseColor = border?.color ?? Colors.grey300;
	const isInlineLabel = labelPosition === LabelPosition.left;

	// NOTE: order in which border color value is set here is important to ensure desired behaviour
	let borderColor =
		isOpen || (isFilled && !isInlineView && !rest.disabled) ? Colors.primary600 : borderBaseColor;

	if (isInvalid) {
		borderColor = Colors.error600;
	}

	if (isInlineView) {
		borderColor = Colors.transparent;
	}

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const onChange = (
		value: ValueType,
		e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>
	) => {
		props.onChange?.(value, e);
		toggle();
	};

	return (
		<>
			{label && <Label className={cx(labelClass, { [inlineStyle]: isInlineLabel })}>{label}</Label>}
			<CustomDropdown
				id={id}
				className={cx(props.className, { [inlineStyle]: isInlineLabel })}
				{...{
					bgColor,
					isOpen,
					toggle,
					direction,
					isInvalid,
					borderColor,
					width,
					height,
					margin,
					border,
					flexGrow,
					flexShrink,
					flexBasis,
				}}
				disabled={props.disabled}
				isInline={isInlineLabel}
			>
				<ToggleButton
					{...rest}
					isOpen={isOpen}
					isInlineView={isInlineView}
					borderColor={borderColor}
				/>
				{isOpen && (
					<CustomDropdownMenu
						isSearch={options?.length > 10}
						container={container}
						isRichCalendar={isRichCalendar}
						isRangeEnabled={isRangeEnabled}
						zIndex={zIndex}
					>
						<Calendar {...props} onChange={onChange} onCancel={toggle} />
					</CustomDropdownMenu>
				)}
				{isInvalid && name && (
					<Text
						htmlTagName="div"
						className="input-error"
						color={Colors.error500}
						fontSize={FontSize.text_xs}
						fontWeight={FontWeight.regular}
						position={{ value: Position.absolute, top: '100%', left: 0 }}
						margin={{ top: 4 }}
					>
						{getIn(formik?.errors, name)}
					</Text>
				)}
			</CustomDropdown>
		</>
	);
};

export default React.memo(Select);
