import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { CheckIcon } from 'zeda-icons/v2';

import { ToggleButtonProps, CustomDropdownProps } from '../calendarTypes';

import Dropdown, { DropdownMenu, DropdownToggle } from 'components/Dropdown';
import { Colors, FontSize, FontWeight } from 'theme';
import { BoxShadow } from 'theme/layout';
import { Border, Margin, Padding, Flex } from 'theme/mixins';

export const Label = styled.label`
	font-size: ${FontSize.text_sm};
	font-weight: ${FontWeight.semiBold};
	color: ${Colors.grey700};
	margin-bottom: 0;
`;

const getHoverStyle = (isInvalid: boolean): string => {
	const color = isInvalid ? Colors.error600 : Colors.grey300;

	return `
	&:hover {
		border-color: ${color};
		.caret {
			color: ${color};
		}
	}
`;
};

export const CustomDropdown = styled(Dropdown)<CustomDropdownProps>`
	display: block;
	background-color: ${({ bgColor }) => bgColor ?? Colors.transparent};
	border: 1px solid ${({ borderColor }) => borderColor ?? Colors.grey300};
	border-radius: 8px;
	transition: border-color 0.2s;
	${({ margin }) => !margin && 'margin-top: 10px'};
	width: ${({ width }) => (width && typeof width === 'number' ? `${width}px` : width)};
	height: ${({ height }) => (height && typeof height === 'number' ? `${height}px` : height)};
	position: relative;

	${({ isInline }) =>
		isInline &&
		`
		margin-top: 0 !important;
		margin-left: 10px;
	`}

	${({ disabled, isInvalid }) => (disabled ? 'overflow: hidden;' : getHoverStyle(isInvalid))}

	:focus-within {
		border-color: ${Colors.primary600};
		box-shadow: ${BoxShadow.focus} !important;
	}

	${Margin}
	${Border}
	${Flex}
`;

export const StyledTickIcon = styled(CheckIcon)`
	margin-right: 0 !important;
	margin-left: 14px;
`;

export const ErrorText = styled.span`
	position: absolute;
	color: ${Colors.error600};
	display: block;
	font-weight: 600;
	font-size: ${FontSize.text_xs};
	margin-top: 4px;
`;

export const CustomDropdownMenu = styled(DropdownMenu)<{
	isSearch: boolean;
	isRichCalendar: boolean;
	isRangeEnabled: boolean;
	zIndex: number;
}>`
	padding: 0;
	max-height: 500px;
	overflow: auto;
	left: -8px !important;
	box-sizing: border-box !important;
	${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
	${({ isRichCalendar, isRangeEnabled }) => {
		if (isRichCalendar) {
			return 'width: 516px';
		}
		if (isRangeEnabled) {
			return 'width: 356px';
		}
		return 'width: 312px';
	}}
	${({ isSearch }) => isSearch && `min-width: 230px`};
`;

export const menuItemStyle = css`
	font-weight: 700;
	padding: 14px !important;
	display: flex !important;
	.option {
		flex: 1;
	}
	&:not(:last-child) {
		border-bottom: 1px solid ${Colors.grey200};
	}
`;

export const ToggleButton = styled(DropdownToggle)<ToggleButtonProps>`
	border: none;
	width: 100%;
	background-color: ${Colors.transparent};
	padding: 10px 14px;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 8px;
	box-shadow: none !important;
	height: 100% !important;
	background-color: ${Colors.white};

	&:disabled {
		background-color: ${({ isInlineView }) => (isInlineView ? Colors.transparent : Colors.grey100)};
	}
	${Padding}
`;

export const inlineStyle = css`
	display: inline-block !important;
	vertical-align: middle;
`;
