import { ParsedQuery } from 'query-string';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
	FetchAllFeedbacks,
	FetchMoreFeedbacks,
	GetInitialData,
	SubmitFeedback,
	VoteFeedback,
} from 'actions/FeedbackAction';
import { UserLogin } from 'actions/UserAction';
import { getConfigurations, getPortalSettings, getWorkspaceDetails } from 'selectors/AppSelector';
import {
	getSortedStatusList,
	getSortedTypeList,
	getRequestFormCustomFields,
	getTypeOptions,
	getFeedbackInfo,
	getAllFeedbacks,
} from 'selectors/FeedbackSelectors';
import { WorkspaceInfoType } from 'types/app';
import { AddRequestPayload, UpvotePayload } from 'types/feedbacks';
import { RootState } from 'types/state';

export const removeFilterParams = (parsedQueryParam: ParsedQuery) => {
	const newParsedQueryParam = parsedQueryParam;
	delete newParsedQueryParam.type;
	delete newParsedQueryParam.status;
	return newParsedQueryParam;
};

export const getConfigList = (configInfo: any[]) => {
	if (configInfo) {
		return Object.entries(configInfo)
			?.map((value) => ({ ...value[1], key: value[0] }))
			.sort((first, second) => first.index - second.index);
	}
	return [];
};

const mapStateToProps = (state: RootState) => ({
	feedbackInfo: getFeedbackInfo(state),
	feedbacks: getAllFeedbacks(state),
	customFields: getRequestFormCustomFields(state),
	workspaceInfo: getWorkspaceDetails(state) as WorkspaceInfoType,
	portalConfig: getPortalSettings(state),
	sortedTypeList: getSortedTypeList(state),
	typeList: getSortedTypeList(state),
	typeOptionsForSelect: getTypeOptions(state),
	sortedStatusList: getSortedStatusList(state),
	loadingStatus: state.feedbackReducer.loadingStatus,
	feedbackDetail: state.feedbackReducer.feedbackDetails,
	userInfo: state.userReducer.userInfo,
	portalSettings: getConfigurations(state),
	workspaceImage: state.appReducer.configuration?.theme.logo,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchFeedbacks: (
		pageCount: number,
		workspaceId: string,
		query: string,
		boardUniqueName: string
	) => dispatch(FetchAllFeedbacks.request(pageCount, workspaceId, query, boardUniqueName)),
	fetchMoreFeedbacks: (
		pageCount: number,
		workspaceId: string,
		query: string,
		boardUniqueName: string
	) => dispatch(FetchMoreFeedbacks.request(pageCount, workspaceId, query, boardUniqueName)),
	toggleFeedbackVote: (body: UpvotePayload) => dispatch(VoteFeedback.request(body)),
	submitRequest: (body: AddRequestPayload) => dispatch(SubmitFeedback.request(body)),
	getInitialData: (workspaceId: string, boardUniqueName: string) =>
		dispatch(GetInitialData.request(workspaceId, boardUniqueName)),
	login: (id: string, boardUniqueName: string) => dispatch(UserLogin.request(id, boardUniqueName)),
});

export default connect(mapStateToProps, mapDispatchToProps);
