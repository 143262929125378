import { createSelector } from 'reselect';

import { getAvatarImageUrl } from 'helpers/utils';
import { APP_INIT_STATE } from 'reducers/AppReducer';
import { PortalConfig } from 'types/app';
import { RootState } from 'types/state';

export const getAppState = ({ appReducer }: RootState) => appReducer ?? APP_INIT_STATE;

export const getPortalSettings = ({ appReducer }: RootState) => appReducer.configuration;

export const getWorkspaceDetails = ({ appReducer }: RootState) => appReducer.workspaceInfo;

export const getConfigurations = ({ appReducer }: RootState) =>
	appReducer.configuration ?? ({} as PortalConfig);

export const getWorkspaceUniqueName = ({ appReducer }: RootState) =>
	appReducer.workspaceInfo?.uniquePortalName;

export const getWorkspaceImage = ({ appReducer }: RootState, props: any) => {
	return (
		appReducer.configuration?.theme.logo ||
		getAvatarImageUrl({
			name: appReducer.workspaceInfo?.name || 'Customer Portal',
			colors: props.theme?.colors,
		})
	);
};

export const getCustomDomainUrl = ({ appReducer }: RootState) =>
	appReducer.workspaceInfo?.portalCustomURL;

export const getPortalSections = createSelector(getConfigurations, (config) => {
	const { sections } = config;
	return Object.entries(sections ?? {})
		.map(([key, value]) => ({ key, ...value }))
		.sort((a, b) => a.index - b.index);
});

export const getVisiblePortalSections = createSelector(getPortalSections, (sections) =>
	sections.filter((section) => section.canView)
);
