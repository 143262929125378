import { DateTime } from 'luxon';

export const DATE_FORMATS = {
	MMM_DD: 'LLL dd',
	MMM_DD_YYYY: 'DDD',
	MMMM_YYYY: 'MMMM, yyyy',
	YYYY_MM_DD: 'yyyy-MM-dd',
	MM_DD_YYYY_HIPHEN: 'MM-dd-yyyy',
	DD_MM_YY: 'dd/MM/yyyy',
	MMM_DD_YYY: 'LLL dd, yyyy',
};

export const DATE_LOCAL_FORMATS = {
	MMM_DD_YYYY: DateTime.DATE_MED,
	HH_MM_A: DateTime.TIME_SIMPLE,
	MMM_DD_YYYY_HH_MM_A: DateTime.DATETIME_MED,
	FULL_DATE_TIME_STRING: DateTime.DATETIME_FULL,
	MM_DD_YYYY_SLASH: DateTime.DATE_SHORT,
};

export const convertTimeToFormat = (time: string, format = DATE_FORMATS.MMM_DD_YYYY) => {
	const date = time ? DateTime.fromISO(time) : DateTime.now();
	return date.toFormat(format);
};

export const convertTimeToLocalString = (
	time: string | Date | undefined,
	format = DATE_LOCAL_FORMATS.MMM_DD_YYYY
) => {
	const date = time ? DateTime.fromISO(time) : DateTime.now();
	return date.toLocaleString(format);
};

export const convertJsDateToDateObj = (date: Date) => {
	return DateTime.fromJSDate(date);
};

export const compareDateRange = (range1: any[], range2: string | any[]) => {
	return (
		range1?.length === range2?.length &&
		!!range1?.every(
			(item: { getDate: () => any; getMonth: () => any }, index: any) =>
				item.getDate() === range2?.[index].getDate() &&
				item.getMonth() === range2?.[index].getMonth()
		)
	);
};

export const compareValue = (
	value1: Date | Date[] | null | undefined,
	value2: Date | Date[] | null | undefined
) => {
	if (value1 && value2 && value1 instanceof Date && value2 instanceof Date) {
		return compareDateRange([value1], [value2]);
	}
	if (value1 && value2 && value1 instanceof Array && value2 instanceof Array) {
		return compareDateRange(value1, value2);
	}
	return false;
};

export const getTodayDate = () => {
	return DateTime.now();
};

export const getCurrentWeek = (): [Date, Date] => {
	const startDate = DateTime.now().startOf('week');
	const endDate = DateTime.now().endOf('week');
	return [new Date(startDate), new Date(endDate)];
};

export const getTwoWeeksFromCurrentDate = (): [Date, Date] => {
	const startDate = getTodayDate();
	const endDate = startDate.plus({ days: 14 });
	return [new Date(startDate), new Date(endDate)];
};

export const getCurrentMonth = (): [Date, Date] => {
	const startDate = DateTime.now().startOf('month');
	const endDate = DateTime.now().endOf('month');
	return [new Date(startDate), new Date(endDate)];
};

export const getCurrentQuarter = (): [Date, Date] => {
	const startDate = DateTime.now().startOf('quarter');
	const endDate = DateTime.now().endOf('quarter');
	return [new Date(startDate), new Date(endDate)];
};

export const getNextQuarter = (): [Date, Date] => {
	const startDate = DateTime.now().startOf('quarter').plus({ quarters: 1 });
	const endDate = DateTime.now().endOf('quarter').plus({ quarters: 1 });
	return [new Date(startDate), new Date(endDate)];
};

export const getNextToNextQuarter = (): [Date, Date] => {
	const startDate = DateTime.now().startOf('quarter').plus({ quarters: 2 });
	const endDate = DateTime.now().endOf('quarter').plus({ quarters: 2 });
	return [new Date(startDate), new Date(endDate)];
};

export const getSixMonthsFromCurrentDate = (): [Date, Date] => {
	const startDate = DateTime.now();
	const endDate = DateTime.now().plus({ months: 6 });
	return [new Date(startDate), new Date(endDate)];
};
