import translation from 'constants/translation';
import { Colors, FontSize, FontWeight } from 'theme';

export type Translation = keyof typeof translation;

export enum Screens {
	DESKTOP = 'desktop',
	TABLET = 'tablet',
	MOBILE = 'mobile',
}

export interface FileType {
	id: string;
	fileName: string;
	size: number;
	url: string;
	fileType: string;
}

export type ColorKeys = keyof typeof Colors;
export type FontSizeKeys = keyof typeof FontSize;
export type FontWeightkeys = keyof typeof FontWeight;

// Reducer types

export interface ReduxAction<T> {
	type: T;
}

export interface ReduxActionWithData<T, D> {
	type: T;
	data: D;
}

export interface IconProps {
	fontSize?: FontSize | number;
	color?: Colors;
	height?: number | string;
	width?: number | string;
	className?: string;
}
