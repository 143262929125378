import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { UserAltIcon } from 'zeda-icons/v3';

import Container, { ContainerProps } from 'components/Container/default';
import FlexContainer from 'components/Container/FlexContainer';
import Tooltip from 'components/Tooltip/default';
import { ColorFamily } from 'theme';
import { FlexAlignItems, FlexJustify } from 'theme/layout';
import { Grid, Margin } from 'theme/mixins';
import { Box, GridModel } from 'theme/types';

export enum AvatarSize {
	xxs = 'xxs',
	xs = 'xs',
	s = 's',
	m = 'm',
	lg = 'lg',
}

enum Size {
	xxs = 16,
	xs = 24,
	s = 32,
	m = 40,
	lg = 48,
}

enum PlaceholderIconSize {
	xxs = 10,
	xs = 16,
	s = 18,
	m = 24,
	lg = 28,
}

const getConfig = (size: AvatarSize) => ({
	avatarSize: Size[size],
	placeholderSize: PlaceholderIconSize[size],
});

type TooltipProps =
	| {
			/**
			 * ID of avatar for rendering tooltip
			 */
			id: string;
			tooltip: string;
			/**
			 * tooltip message to show on hover of avatar
			 */
	  }
	| {
			/**
			 * ID of avatar for rendering tooltip
			 */
			id?: string;
			tooltip?: undefined;
	  };

export type AvatarProps = TooltipProps &
	GridModel & {
		/**
		 * Size of avatar to render
		 */
		size: AvatarSize;
		/**
		 * classname for the avatar
		 */
		className?: string;
		/**
		 * link for the image to render in avatar
		 */
		src?: string;
		/**
		 * alt tag for img tag
		 */
		alt?: string;
		/**
		 * placeholder for the Icon to render when image is not present.
		 */
		placeholder?: any;
		/**
		 * Color family responsible for the color of the avatar
		 */
		colorFamily?: ColorFamily;
		// find a better name.
		/**
		 * show bordered around the avatar 2px width
		 */
		showBorders?: boolean;
		/**
		 * margin around the avatar if needed.
		 */
		margin?: Box;
	};

type AvatarImageProps = Omit<AvatarProps, 'size' | 'id' | 'tooltip'> & {
	size: Size;
};

const AvatarImage = styled.img<AvatarImageProps>`
	border-radius: 50%;
	display: block;

	${({ size }) => `
		width: ${size}px;
		height: ${size}px;
	`}
	${Margin}
	${Grid}
`;

const AvatarContainer = styled(Container)<{
	size: Size;
	colorFamily: ColorFamily;
	showBorder: boolean;
	tooltip?: string;
}>`
	${({ size }) => `
		width: ${size}px;
		height: ${size}px;
	`}
	${({ colorFamily, showBorder, theme }) => `
		${showBorder && `border: 2px solid ${theme.colors[`${colorFamily}600`]}`};
		background-color: ${theme.colors[`${colorFamily}50`]};
	`}
	:hover {
		${({ tooltip }) => (tooltip ? `cursor:pointer;` : '')}
	}

	box-sizing: content-box;
	border-radius: 50%;
`;

const Avatar = (props: AvatarProps) => {
	const {
		size,
		className,
		placeholder,
		alt = 'avatar',
		showBorders = false,
		colorFamily = ColorFamily.primary,
		margin,
		...rest
	} = props;

	const { placeholderSize, avatarSize } = getConfig(size);
	const { colors } = useTheme();

	return (
		<>
			<AvatarContainer
				size={avatarSize}
				colorFamily={colorFamily}
				showBorder={showBorders}
				className={className}
				margin={margin}
				{...(props.id && { id: props.id })}
			>
				{props.src ? (
					<AvatarImage
						size={avatarSize}
						alt={alt}
						onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
							(e.target as HTMLImageElement).src = ''; // Add fallback image URL
						}}
						{...rest}
					/>
				) : (
					<FlexContainer
						height={avatarSize}
						width={avatarSize}
						bgColor={colors.transparent}
						alignItems={FlexAlignItems.center}
						justify={FlexJustify.center}
					>
						{placeholder || (
							<UserAltIcon color={colors[`${colorFamily}600`]} fontSize={placeholderSize} />
						)}
					</FlexContainer>
				)}
			</AvatarContainer>
			{!!props.id && !!props.tooltip && <Tooltip target={props.id}>{props.tooltip}</Tooltip>}
		</>
	);
};

const stackAvatars = (count: number) => {
	const styles = Array.from({ length: count }, (_, i) => {
		return `
			> img:nth-of-type(${i + 1}) {
				position: relative;
			}
		`;
	});
	return styles.join('');
};

export interface AvatarGroupProps extends ContainerProps {
	avatarList: AvatarProps[];
	stacked?: boolean;
}

interface AvatarGroupStyles extends Omit<AvatarGroupProps, 'avatarList'> {
	count: number;
}

const AvatarGroupContainer = styled(FlexContainer)<AvatarGroupStyles>`
	align-items: center;

	${({ stacked, count, theme }) => {
		const stackedStyles = stackAvatars(count);

		return (
			stacked &&
			`
			> div:not(:first-of-type) {
				margin-left: -12px;
			}
			div {
				background: ${theme.colors.transparent} !important;
			}
			img {
				border: 2px solid ${theme.colors.white};
			}
			${stackedStyles}
		`
		);
	}}
`;

export const AvatarGroup: React.FC<AvatarGroupProps> = ({
	stacked = true,
	avatarList = [],
	...rest
}) => {
	const count = avatarList.length;

	return (
		<AvatarGroupContainer count={count} stacked={stacked} {...rest}>
			{avatarList.map((item: AvatarProps) => (
				<Avatar {...item} />
			))}
		</AvatarGroupContainer>
	);
};

export default Avatar;
