import React from 'react';
import { CloseIcon } from 'zeda-icons/v2';

import Avatar, { AvatarSize } from '../Avatar';

import DefaultTag from './default';
import {
	TagProps,
	AvatarTagProps,
	DotTagProps,
	getAvatarGapForBadge,
	getIconSize,
	getVariantStyle,
	IconContainer,
	IconTagProps,
	Label,
	TagType,
	TagVariant,
} from './Tag';

import Container from 'components/Container/default';
import FlexContainer from 'components/Container/FlexContainer';
import { getColorByThemeAndGradient } from 'helpers/theme';
import { ColorFamily, Colors, TextAlign } from 'theme';
import { FlexAlignItems, FlexJustify, Space } from 'theme/layout';

const Tag = (props: TagProps) => {
	const {
		id,
		label,
		prefix,
		onClose,
		maxWidth = 200,
		suffix: SuffixIcon,
		type = TagType.tag,
		variant = TagVariant.sm,
		colorFamily = ColorFamily.grey,
		...rest
	} = props;

	const iconSize = getIconSize(variant);
	const avatarGapForBadge = getAvatarGapForBadge(variant);
	const variantStyle = getVariantStyle(
		colorFamily,
		!!prefix,
		!!SuffixIcon || !!onClose,
		variant,
		type
	);

	const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		if (!id || !onClose) return;

		onClose(id);
	};

	const prefixRenderer = prefix && (
		<FlexContainer
			bgColor={Colors.transparent}
			alignItems={FlexAlignItems.center}
			{...(type === TagType.badge && { margin: { left: avatarGapForBadge } })}
		>
			{prefix}
		</FlexContainer>
	);

	const suffixRenderer = SuffixIcon && (
		<FlexContainer bgColor={Colors.transparent} alignItems={FlexAlignItems.center}>
			<SuffixIcon color={getColorByThemeAndGradient(colorFamily, 500)} size={iconSize} />
		</FlexContainer>
	);

	const closeButtonRenderer = onClose && (
		<IconContainer
			cursor="pointer"
			size={iconSize}
			justify={FlexJustify.center}
			bgColor={Colors.transparent}
			alignItems={FlexAlignItems.center}
			onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleClose(e)}
			{...(SuffixIcon
				? { margin: { left: variantStyle.gap.left } }
				: { margin: { left: Space.auto } })}
			{...(type === TagType.tag && { colorFamily })}
		>
			<CloseIcon color={getColorByThemeAndGradient(colorFamily, 500)} />
		</IconContainer>
	);

	return (
		<DefaultTag
			id={id}
			width="fit-content"
			className="tag-container"
			alignItems={FlexAlignItems.center}
			maxWidth={maxWidth}
			{...rest}
			{...variantStyle}
		>
			{prefixRenderer}
			<Label
				textAlign={TextAlign.center}
				color={getColorByThemeAndGradient(colorFamily, 700)}
				margin={{ left: variantStyle.gap.left, right: variantStyle.gap.right }}
			>
				{label}
			</Label>
			{suffixRenderer}
			{closeButtonRenderer}
		</DefaultTag>
	);
};

export const AvatarTag = (props: AvatarTagProps) => {
	const { src, ...rest } = props;
	return <Tag prefix={<Avatar src={src} size={AvatarSize.xxs} />} {...rest} />;
};

export const DotTag = (props: DotTagProps) => {
	const { color, ...rest } = props;
	return (
		<Tag
			prefix={
				<Container
					width={8}
					height={8}
					bgColor={color}
					margin={{ all: 4 }}
					border={{ radius: 50 }}
				/>
			}
			{...rest}
		/>
	);
};

export const IconTag = (props: IconTagProps) => {
	const { icon: Icon, colorFamily = ColorFamily.primary, variant = TagVariant.sm, ...rest } = props;
	const iconSize = getIconSize(variant);

	return (
		<Tag
			variant={variant}
			colorFamily={colorFamily}
			prefix={
				<IconContainer
					size={iconSize}
					bgColor={Colors.transparent}
					justify={FlexJustify.center}
					alignItems={FlexAlignItems.center}
				>
					<Icon color={getColorByThemeAndGradient(colorFamily, 500)} />
				</IconContainer>
			}
			{...rest}
		/>
	);
};

export default Tag;
