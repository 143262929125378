import styled from '@emotion/styled';

import { isUndefined } from 'helpers/utils';
import { Colors, Overflow, TextAlign } from 'theme';
import { FlexAlignSelf, FlexJustify } from 'theme/layout';
import { Margin, Padding, Border, Grid, Flex, Position } from 'theme/mixins';
import { Box, BorderModel, GridModel, PositionModel } from 'theme/types';

export interface ContainerProps extends GridModel {
	width?: number | string;
	maxWidth?: number | string;
	minWidth?: number | string;
	height?: number | string;
	maxHeight?: number | string;
	minHeight?: number | string;
	lineHeight?: number;
	padding?: Partial<Box>;
	margin?: Box;
	border?: BorderModel;
	borderRadius?: number;
	position?: PositionModel;
	textAlign?: TextAlign;
	cursor?: string;
	bgColor?: Colors | string;
	inline?: boolean;
	className?: string;
	overflow?: Overflow;
	ref?: any;
	flexGrow?: number;
	flexShrink?: number;
	flexBasis?: string | number;
	alignSelf?: FlexAlignSelf;
	justifySelf?: FlexJustify;
	zIndex?: number;
	onClick?: React.EventHandler<React.MouseEvent<HTMLElement>>;
	opacity?: string;
}

const Container = styled.div<ContainerProps>`
	display: ${({ inline }) => inline && 'inline-block'};
	width: ${({ width }) => (width && typeof width === 'number' ? `${width}px` : width)};
	min-width: ${({ minWidth }) =>
		minWidth && typeof minWidth === 'number' ? `${minWidth}px` : minWidth};
	max-width: ${({ maxWidth }) =>
		maxWidth && typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
	min-width: ${({ minWidth }) =>
		minWidth && typeof minWidth === 'number' ? `${minWidth}px` : minWidth};
	height: ${({ height }) => (height && typeof height === 'number' ? `${height}px` : height)};
	max-height: ${({ maxHeight }) =>
		maxHeight && typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight};
	min-height: ${({ minHeight }) =>
		minHeight && typeof minHeight === 'number' ? `${minHeight}px` : minHeight};
	line-height: ${({ lineHeight }) => !isUndefined(lineHeight) && lineHeight};
	text-align: ${({ textAlign = 'initial' }) => textAlign};
	background-color: ${({ bgColor = Colors.transparent }) => bgColor};
	overflow: ${({ overflow }) => !isUndefined(overflow) && overflow};
	z-index: ${({ zIndex }) => !isUndefined(zIndex) && zIndex};
	cursor: ${({ cursor }) => !!cursor && cursor};
	opacity: ${({ opacity }) => !isUndefined(opacity) && opacity};
	${Flex}
	${Grid}
	${Padding}
	${Margin}
	${Border}
	${Position}
`;

export default Container;
