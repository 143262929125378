import styled from '@emotion/styled';
import React from 'react';
import ReactDOM from 'react-dom';

import { Colors } from 'theme';
import { Border, Margin, Padding } from 'theme/mixins';
import { BorderModel, Box } from 'theme/types';

export interface ModalInterface {
	isOpen: boolean;
	isCenter?: boolean;
	backdrop?: boolean;
	toggle: () => void;
	children?: JSX.Element;
	top?: string;
	left?: string;
	right?: string;
	className?: string;
	width?: number | string;
	height?: number | string;
	maxHeight?: number | string;
	minHeight?: number | string;
	maxWidth?: number | string;
	minWidth?: number | string;
	padding?: Partial<Box>;
	margin?: Box;
	border?: BorderModel;
	disableBackDropClick?: boolean;
}

export interface ContentBodyInterface {
	isCenter?: boolean;
	top?: string;
	left?: string;
	right?: string;
	width?: number | string;
	height?: number | string;
	maxHeight?: number | string;
	minHeight?: number | string;
	maxWidth?: number | string;
	minWidth?: number | string;
	padding?: Partial<Box>;
	margin?: Box;
	border?: BorderModel;
}

const BackDrop = styled.div<{ backdrop: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: ${({ backdrop }) => (backdrop ? '#00000032' : 'transparent')};
`;

const ContentBody = styled.div<ContentBodyInterface>`
	position: fixed;
	background: ${Colors.transparent};
	z-index: 1050;
	${({ width }) => width && `width: ${typeof width === 'number' ? `${width}px` : width};`}
	${({ height }) => height && `height: ${typeof height === 'number' ? `${height}px` : height};`}
	${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px;`}
	${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
	${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
	${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
	${({ isCenter = true, top, left, right }) =>
		isCenter
			? `
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	`
			: `
		${top ? `top: ${top}` : ''};
		${left ? `left: ${left}` : ''};
		${right ? `right: ${right}` : ''};
	`}
	${Margin}
	${Padding}
	${Border}
`;

const ModalDiv = styled.div`
	position: relative;
	z-index: 1050;
`;

const Modal: React.FC<ModalInterface> = (props) => {
	const bodyElement = document.body;
	const {
		isOpen,
		toggle,
		children,
		isCenter,
		top,
		left,
		right,
		backdrop = true,
		className = '',
		width,
		height,
		maxHeight,
		minHeight,
		maxWidth,
		minWidth,
		padding,
		margin,
		border,
		disableBackDropClick = false,
		...rest
	} = props;

	const backDropClick = () => {
		if (disableBackDropClick) {
			return;
		}
		toggle();
	};

	if (!isOpen) {
		return null;
	}
	return ReactDOM.createPortal(
		<ModalDiv onClick={backDropClick}>
			<ContentBody
				className={className}
				onClick={(e) => e.stopPropagation()}
				isCenter={isCenter}
				top={top}
				left={left}
				right={right}
				width={width}
				height={height}
				maxHeight={maxHeight}
				minHeight={minHeight}
				maxWidth={maxWidth}
				minWidth={minWidth}
				padding={padding}
				margin={margin}
				border={border}
				{...rest}
			>
				{children}
			</ContentBody>
			<BackDrop backdrop={backdrop} />
		</ModalDiv>,
		bodyElement
	);
};

export default Modal;
