import styled from '@emotion/styled';
import React from 'react';

import { displayInPx } from 'helpers/theme';
import { Border, Margin } from 'theme/mixins';
import { BorderModel, Box } from 'theme/types';

interface ImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
	width?: number | string;
	height?: number | string;
	src: string;
	id?: string;
	alt: string;
	minHeight?: number;
	border?: BorderModel;
	margin?: Box;
	loading?: 'eager' | 'lazy';
	onError?: () => void;
}

const StyledImg = styled.img<ImgProps>`
	${({ width }) => width && `width: ${displayInPx(width)}`};
	${({ height }) => height && `height: ${displayInPx(height)}`};
	${({ minHeight }) => minHeight && `min-height: ${displayInPx(minHeight)}`};
	${Margin}
	${Border}
`;

const Image: React.FC<ImgProps> = (props) => {
	const { loading = 'lazy', width = '100%', height = '100%', minHeight, ...rest } = props;
	return (
		<StyledImg loading={loading} width={width} height={height} minHeight={minHeight} {...rest} />
	);
};

export default Image;
