import React from 'react';

import { ContainerProps } from '../Container/default';
import GridContainer from '../Container/GridContainer';
import Text, { TextProps } from '../Text';

import { Colors, FontSize, FontWeight } from 'theme';
import { FlexAlignItems, FlexJustify } from 'theme/layout';
import { Translation } from 'types';

interface EmptyStateProps extends ContainerProps {
	label: Translation;
	labelProps?: TextProps;
	imageWidth?: number | string;
	imageHeight?: number | string;
	image: React.FC<React.SVGProps<SVGSVGElement>>;
	children?: React.ReactNode;
}

const EmptyState: React.FC<EmptyStateProps> = (props) => {
	const {
		imageWidth = '100%',
		imageHeight = '100%',
		label,
		labelProps,
		children,
		image: Image,
		...rest
	} = props;

	return (
		<GridContainer
			bgColor={Colors.transparent}
			alignItems={FlexAlignItems.center}
			justify={FlexJustify.center}
			{...rest}
		>
			<Image width={imageWidth} height={imageHeight} className="fallback-img" />
			<Text
				translation={label}
				color={Colors.grey900}
				fontWeight={FontWeight.bold}
				fontSize={FontSize.text_lg}
				justifySelf={FlexJustify.center}
				{...labelProps}
			/>
			{children}
		</GridContainer>
	);
};

export default EmptyState;
