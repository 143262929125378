import { AnyAction } from 'redux';

import {
	FETCH_ALL_FEEDBACKS,
	FETCH_BOARD_DATA,
	FETCH_INITIAL_BOARD,
	FETCH_FEEDBACK_DETAIL,
	FETCH_INITIAL_DATA,
	FETCH_MORE_FEEDBACKS,
	SUBMIT_FEEDBACK,
	VOTE_FEEDBACK,
	ADD_FEEDBACK_COMMENT,
	FETCH_FEEDBACK_FORM_DATA,
	RESET_BOARD_VIEW_DATA,
} from 'actions/FeedbackAction';
import { LOADING_STATUS } from 'constants/constants';
import { reducerWrapper } from 'helpers/action';
import { getDataWithUpdatedVoteCount } from 'helpers/feedback';
import { BoardItem, FeedbackDetail, FeedbackVisibility, FeedbackState } from 'types/feedbacks';

export const INIT_STATE: FeedbackState = {
	loadingStatus: LOADING_STATUS.INIT,
	list: {
		feedbacks: [],
		currentPage: 1,
		totalPages: 2,
	},
	types: [],
	status: [],
	visibleCustomFields: [],
	boardGroup: [],
	feedbackDetails: {},
};

function Handler(state = INIT_STATE, action: AnyAction): FeedbackState {
	const { data } = action;
	switch (action.type) {
		case FETCH_FEEDBACK_FORM_DATA.response: {
			const { data: result } = data;

			return {
				...state,
				types: result.type,
				visibleCustomFields: result.visibleCustomFields,
			};
		}

		case FETCH_INITIAL_DATA.response: {
			const { data: result } = data;

			return {
				...state,
				types: result.types,
				status: result.status,
				visibleCustomFields: result.visibleCustomFields,
			};
		}

		case FETCH_ALL_FEEDBACKS.response: {
			return {
				...state,
				list: {
					...state.list,
					feedbacks: data.feedbacks,
					currentPage: data.currentPage,
					totalPages: data.totalPages,
				},
				loadingStatus: LOADING_STATUS.INACTIVE,
			};
		}

		case FETCH_MORE_FEEDBACKS.response: {
			return {
				...state,
				list: {
					...state.list,
					feedbacks: [...state.list.feedbacks, ...data.feedbacks],
					currentPage: data.currentPage,
					totalPages: data.totalPages,
				},
			};
		}

		case SUBMIT_FEEDBACK.response: {
			const updatedFeedbacks =
				!data.isLoggedIn && data.feedback.visibility === FeedbackVisibility.PRIVATE
					? state.list.feedbacks
					: [...state.list.feedbacks, data.feedback];
			return {
				...state,
				list: {
					...state.list,
					feedbacks: updatedFeedbacks,
					currentPage: state.list.currentPage === 0 ? 1 : state.list.currentPage,
					totalPages: state.list.totalPages === 0 ? 1 : state.list.totalPages,
				},
			};
		}

		case FETCH_FEEDBACK_DETAIL.response:
			return {
				...state,
				feedbackDetails: { ...data.feedback, loading: LOADING_STATUS.INACTIVE },
			};

		case VOTE_FEEDBACK.response: {
			const { source, feedback: updatedFeedback } = data;

			/**
			 * Source determines which state value to update. Since there is no overlap between
			 * screens, it is okay if we update one state at a time.
			 */

			// update vote count for feedback when in list view
			if (source === 'LIST') {
				const feedbackIndex = state.list.feedbacks.findIndex(
					(feedback) => feedback.id === updatedFeedback.id
				);
				const updatedFeedbacks = [...state.list.feedbacks];

				updatedFeedbacks.splice(
					feedbackIndex,
					1,
					getDataWithUpdatedVoteCount(updatedFeedback, updatedFeedback, ['upvoteCount'])
				);

				return {
					...state,
					list: { ...state.list, feedbacks: updatedFeedbacks },
				};
			}

			// update vote count for feedback when in board view
			if (source === 'BOARD') {
				const updatedBoardState = state.boardGroup.map((group) =>
					group.id === (updatedFeedback.statusId ?? 'new')
						? {
								...group,
								feedbackList: group.feedbackList.map((feedback) =>
									updatedFeedback.id === feedback.id
										? getDataWithUpdatedVoteCount(feedback, updatedFeedback, [
												'upvoteCount',
												'isUpvoted',
										  ])
										: feedback
								),
						  }
						: group
				);

				return { ...state, boardGroup: updatedBoardState };
			}

			// update vote count for feedback when in details view
			if (state.feedbackDetails.id !== updatedFeedback.id) return state;

			const updatedFeedbackDetails = getDataWithUpdatedVoteCount(
				state.feedbackDetails as FeedbackDetail,
				updatedFeedback,
				['upvoteCount', 'isUpvoted', 'upvotedBy']
			);

			return { ...state, feedbackDetails: updatedFeedbackDetails };
		}

		case ADD_FEEDBACK_COMMENT.response: {
			if (!state.feedbackDetails.id) {
				return state;
			}

			return {
				...state,
				feedbackDetails: {
					...state.feedbackDetails,
					comments: [data.comment, ...(state.feedbackDetails.comments ?? [])],
				},
			};
		}

		case FETCH_INITIAL_BOARD.response: {
			return {
				...state,
				loadingStatus: LOADING_STATUS.INACTIVE,
				boardGroup: data.data.statusGroups?.map((item: any) => ({
					id: item.id ?? 'new',
					feedbackList: item.data,
					totalCount: item.count,
					pageNumber: 1,
				})),
			};
		}

		case FETCH_BOARD_DATA.response: {
			const { data: result, statusId } = data;
			return {
				...state,
				boardGroup: state.boardGroup?.map((item: BoardItem) => {
					if (statusId === null && item.id === 'new') {
						const feedbackList = result.statusGroups.find(
							(group: { id: string }) => group.id === null
						);
						return {
							...item,
							pageNumber: item.pageNumber + 1,
							feedbackList: [...item.feedbackList, ...feedbackList.data],
						};
					}
					if (item.id === result.statusGroups[0].id) {
						return {
							...item,
							pageNumber: item.pageNumber + 1,
							feedbackList: [...item.feedbackList, ...result.statusGroups[0].data],
						};
					}
					return item;
				}),
			};
		}

		case RESET_BOARD_VIEW_DATA.response: {
			return {
				...state,
				boardGroup: [],
			};
		}

		default:
			return state;
	}
}

const FeedbackReducer = reducerWrapper(INIT_STATE, Handler, {
	request: [FETCH_ALL_FEEDBACKS, FETCH_FEEDBACK_DETAIL, FETCH_INITIAL_BOARD],
});

export default FeedbackReducer;
