import { useTheme } from '@emotion/react';
import React from 'react';
import { CheckIcon, MinusIcon } from 'zeda-icons/v3';

import { CheckboxProps, StyledContainer } from './Checkbox';

import FlexContainer from 'components/Container/FlexContainer';
import { FlexAlignItems, FlexJustify } from 'theme/layout';

const Checkbox = (props: CheckboxProps) => {
	const { isSelected, isIndeterminate, isDisabled, size, isCircle, margin, onClick } = props;
	const { colors } = useTheme();

	const selectedBgColor = isCircle ? colors.primary600 : colors.primary25;
	const selectedIconColor = isCircle ? colors.white : colors.primary600;

	const Icon = !isIndeterminate ? (
		<CheckIcon
			color={isDisabled ? colors.grey200 : selectedIconColor}
			fontSize={size === 'sm' ? 12 : 14}
		/>
	) : (
		<MinusIcon
			color={isDisabled ? colors.grey200 : selectedIconColor}
			fontSize={size === 'sm' ? 12 : 14}
		/>
	);

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (isDisabled) {
			return;
		}
		onClick?.(event, !isSelected);
	};

	return (
		<StyledContainer
			tabIndex={0}
			bgColor={colors.transparent}
			isDisabled={isDisabled}
			isSelected={isSelected}
			isCircle={isCircle}
			size={size}
			height={size === 'sm' ? 16 : 20}
			width={size === 'sm' ? 16 : 20}
			alignItems={FlexAlignItems.center}
			justify={FlexJustify.center}
			margin={margin}
			onClick={handleClick}
		>
			<FlexContainer
				border={{ all: 1, color: isSelected ? colors.primary600 : colors.grey300 }}
				bgColor={isSelected ? selectedBgColor : colors.white}
				height={size === 'sm' ? 15 : 19}
				width={size === 'sm' ? 15 : 19}
				alignItems={FlexAlignItems.center}
				justify={FlexJustify.center}
			>
				{isSelected && Icon}
			</FlexContainer>
		</StyledContainer>
	);
};

Checkbox.defaultProps = {
	isSelected: false,
	isIndeterminate: false,
	isDisabled: false,
	size: 'sm',
	isCircle: false,
};
export default Checkbox;
