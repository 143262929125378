import { css } from '@emotion/css';
import styled from '@emotion/styled';
import React from 'react';
import { UncontrolledTooltip, UncontrolledTooltipProps } from 'reactstrap';

import Text from '../Text';

import { Colors, FontSize, FontWeight } from 'theme';

export interface TooltipBaseProps extends UncontrolledTooltipProps {
	children: React.ReactNode;
	target: string;
	className?: string;
	persistTooltip?: boolean;
}

const arrowClassName = (placement: UncontrolledTooltipProps['placement']) => css`
	:before {
		border-${placement}-color: ${Colors.grey900} !important;
	}
`;

const tooltipContentClass = css`
	padding: 12px 16px !important;
	background-color: ${Colors.grey900} !important;
	max-width: 240px !important;
	text-align: left !important;
`;

const StyledUncontrolledTooltip = styled(UncontrolledTooltip)<{ autohide: boolean }>`
	${({ autohide }) =>
		autohide &&
		`
	.tooltip {
		pointer-events:none; // Pointer events kept to none to avoid flickering of tooltip
	}
`}
	.tooltip.show {
		opacity: 1;
	}
`;

const Tooltip = (props: TooltipBaseProps) => {
	if (!props.target) return null;

	const {
		target,
		placement = 'bottom',
		persistTooltip = false,
		children,
		fade = false,
		...rest
	} = props;
	const tooltipContent =
		typeof children === 'string' ? (
			<Text color={Colors.white} fontSize={FontSize.text_sm} fontWeight={FontWeight.semiBold}>
				{children}
			</Text>
		) : (
			children
		);

	return (
		<StyledUncontrolledTooltip
			target={target}
			placement={placement}
			fade={fade}
			innerClassName={tooltipContentClass}
			arrowClassName={arrowClassName(placement)}
			autohide={!persistTooltip}
			{...rest}
		>
			{tooltipContent}
		</StyledUncontrolledTooltip>
	);
};
Tooltip.displayName = 'Tooltip';

export default Tooltip;
