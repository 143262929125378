import { ReduxAsyncAction } from 'helpers/action';
import request from 'helpers/request';
import { ReduxAction, ReduxActionWithData } from 'types';
import { UserInfo } from 'types/user';

/**
 * API to fetch user information
 */
export const FetchUserInfo = new ReduxAsyncAction('FETCH_USER_INFO');
FetchUserInfo.registerRequest(async (workspaceId: string, boardUniqueName: string) => {
	const { data } = await request.get(`/user/`, { params: { workspaceId, boardUniqueName } });
	return { token: data.result.token, ...data.result.user };
});
export const FETCH_USER_INFO = FetchUserInfo.constants;
export type FetchUserInfoType = ReduxActionWithData<'FETCH_USER_INFO', UserInfo>;

/**
 * Login API - Called on clicking login button
 */
export const UserLogin = new ReduxAsyncAction('LOGIN');
UserLogin.registerRequest(async (workspaceId: string, boardUniqueName: string) => {
	const { data } = await request.get('/user/auth/', { params: { workspaceId, boardUniqueName } });
	return data;
});
export const LOGIN = UserLogin.constants;
export type UserLoginType = ReduxAction<'LOGIN'>;

/**
 * Verify Cognito token
 */
export const VerifyCognitoToken = new ReduxAsyncAction('VERIFY_AUTH_TOKEN');
VerifyCognitoToken.registerRequest(
	// eslint-disable-next-line camelcase
	async (body: { auth_code: string; workspaceId: string; boardUniqueName: string }) => {
		const { data } = await request.post('/user/verify/auth/token', body);
		return data;
	}
);
export const VERIFY_AUTH_TOKEN = VerifyCognitoToken.constants;
export type VerifyCognitoTokenType = ReduxAction<'VERIFY_AUTH_TOKEN'>;

/**
 * Verify auto-login token
 */
export const VerifyAutoLoginToken = new ReduxAsyncAction('VERIFY_AUTO_LOGIN_TOKEN');
VerifyAutoLoginToken.registerRequest(
	async (body: { token: string; name: string; host: string; boardUniqueName: string }) => {
		const { data } = await request.post('/user/verify/auto-login/token', body);
		return data;
	}
);
export const VERIFY_AUTO_LOGIN_TOKEN = VerifyAutoLoginToken.constants;
export type VerifyAutoLoginTokenType = ReduxAction<'VERIFY_AUTO_LOGIN_TOKEN'>;

/**
 * Logout API
 */
export const Logout = new ReduxAsyncAction('LOGOUT');
Logout.registerRequest(async () => {
	const { data } = await request.post(`/user/logout/`);
	return data;
});
export const LOGOUT = Logout.constants;
export type LogoutActionType = ReduxAction<'LOGOUT'>;

export const UpdateUserInfo = (payload: UserInfo) => ({
	type: 'UPDATE_USER',
	data: payload,
});
export type UpdateUserInfoType = ReduxActionWithData<'UPDATE_USER', UserInfo>;

export type UserActions =
	| FetchUserInfoType
	| UserLoginType
	| VerifyCognitoTokenType
	| VerifyAutoLoginTokenType
	| LogoutActionType
	| UpdateUserInfoType;
