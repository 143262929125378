import styled from '@emotion/styled';
import React from 'react';

import FlexContainer from 'components/Container/FlexContainer';
import { Colors, FontSize, FontWeight } from 'theme/';
import { FlexJustify } from 'theme/layout';
import { Border, Flex, Grid, Margin, Padding, Position } from 'theme/mixins';
import { BorderModel, Box, GridModel, PositionModel } from 'theme/types';

interface InputWrapperProps extends GridModel {
	/**
	 * disabled state for input wrapper
	 */
	disabled?: boolean;
	/**
	 * background color for input wrapper
	 */
	bgColor?: Colors;
	/**
	 * width for input wrapper
	 */
	width?: number | string;
	/**
	 * min width for input wrapper
	 */
	minWidth?: number | string;
	/**
	 * height for input wrapper
	 */
	height?: number | string;
	/**
	 * Padding for input wrapper
	 */
	padding?: Partial<Box>;
	/**
	 * Margin for input wrapper
	 */
	margin?: Box;
	/**
	 * Border for input wrapper
	 */
	border?: BorderModel;
	/**
	 * hintText to be shown
	 */
	hintText?: string;
	/**
	 * Flex grow - Only applied if parent is a flex container
	 */
	flexGrow?: number;
	/**
	 * Flex shrink - Only applied if parent is a flex container
	 */
	flexShrink?: number;
	/**
	 * Flex basis - Only applied if parent is a flex container
	 */
	flexBasis?: string | number;
	/**
	 * Set how input wrapper is positioned in DOM
	 */
	position?: PositionModel;
	/**
	 * Check if it's supposed to be an inline input
	 */
	isInlineView?: boolean;
	/**
	 * display property for wrapper
	 */
	display?: 'inline-block' | 'flex' | 'none';

	multiple?: boolean;
	readOnly?: boolean;
}

export interface InputProps extends InputWrapperProps {
	/**
	 * Additional CSS class
	 */
	className?: string;
	/**
	 * placeholder for input
	 */
	placeholder?: string;
	/**
	 * value for input
	 */
	value?: string | ReadonlyArray<string> | number | undefined;
	/**
	 * if autofocus needs to be added
	 */
	autoFocus?: boolean;
	/**
	 * type of value that can be taken
	 */
	type?: React.HTMLInputTypeAttribute;
	/**
	 * label for input
	 */
	label?: string;
	/**
	 * name for the input
	 */
	name?: string;
	/**
	 * ref for input
	 */
	inputFieldRef?: any;
	/**
	 * component to be shown before the input component
	 */
	prefixComponent?: React.ReactNode;
	/**
	 * component to be shown after the input component
	 */
	suffixComponent?: React.ReactNode;
	/**
	 * width of the input
	 */
	width?: number | string;
	/**
	 * maxLength for the characters in input
	 */
	maxLength?: number;

	accept?: string | undefined;
	/**
	 * function which runs on changing value in input
	 */
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	/**
	 * function which runs on once clicking inside the input and then outside it.
	 */
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	/**
	 * function which runs on once clicking inside the input.
	 */
	onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
	/**
	 * function which runs on focus inside the input.
	 */
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	/**
	 * validations to check
	 */
	validations?: (
		| { re: RegExp; errorMessage: string }
		| { doesValidate: (condition: any, value: string | number) => boolean; errorMessage: string }
	)[];
}

export enum IconType {
	PREFIX = 'prefix',
	SUFFIX = 'suffix',
	BOTH = 'both',
}

interface SuffixIconInputProps extends Omit<InputProps, 'suffixComponent'> {
	/**
	 * Icon to show after label and its props
	 */
	suffixIconConfig: { icon: React.FC<IconPropType>; iconProps?: IconPropType };
}

interface PrefixIconInputProps extends Omit<InputProps, 'prefixComponent'> {
	/**
	 * Icon to show before label and its props
	 */
	prefixIconConfig: {
		icon: React.FC<IconPropType>;
		iconProps?: IconPropType;
	};
}

export type IconInputProps =
	| ({ type: IconType.PREFIX } & PrefixIconInputProps)
	| ({ type: IconType.SUFFIX } & SuffixIconInputProps)
	| ({ type: IconType.BOTH } & Omit<PrefixIconInputProps, 'suffixComponent'> &
			Omit<SuffixIconInputProps, 'prefixComponent'>);

export interface InputWithLimitProps extends InputProps {
	maxLength: number;
	type: 'text' | 'email' | 'password' | 'search' | 'tel' | 'url';
}

export interface SearchInputProps extends Omit<InputProps, 'prefixComponent, suffixComponent'> {
	clear: () => void;
	name?: string;
}

/* STYLED COMPONENTS */

const inputInteractions = (colors = Colors) => `
	:focus,
	:focus-within {
		border-color: ${colors.primary600};
		
		input:focus::placeholder {
			font-weight: ${FontWeight.regular};
		  }
	}
`;

const inputDisabledStyles = `
	border-color: ${Colors.grey300};
	background-color: ${Colors.grey50};
`;

const inputErrorInteractions = (disabled: boolean): string => `
	border-color: ${Colors.error600};

	:hover,
	:focus,
	:focus-within {
		${!disabled && `border-color: ${Colors.error600};`}
	}
`;

export const InputWrapper = styled.label<
	InputWrapperProps & { isFilled: boolean; isInvalid: boolean }
>`
	border: 1px solid;
	position: relative;
	border-radius: 8px;
	display: ${({ display }) => display ?? 'flex'};
	align-items: center;
	justify-content: ${FlexJustify.spaceBetween};
	background-color: ${({ bgColor }) => bgColor ?? Colors.white};
	transition: 0.2s;
	margin-top: 4px;
	border-color: ${Colors.grey300};
	${({ isInlineView }) => isInlineView && `border-color: ${Colors.transparent};`};
	${({ isInlineView, disabled, theme }) =>
		!isInlineView && disabled ? inputDisabledStyles : inputInteractions(theme.colors)}
	width: ${({ width }) => (width && typeof width === 'number' ? `${width}px` : width)};
	height: ${({ height }) => (height && typeof height === 'number' ? `${height}px` : height)};
	min-width: ${({ minWidth }) =>
		minWidth && typeof minWidth === 'number' ? `${minWidth}px` : minWidth};

	padding: 10px 14px;

	> svg {
		flex-shrink: 0;
	}
	${Padding}
	${Margin}
	${Border}
	${Flex}
	${Grid}
	${Position}
	${({ isInvalid, disabled }) => isInvalid && inputErrorInteractions(!!disabled)}
`;

export const Input = styled.input<InputProps>`
	border: none;
	background-color: transparent;
	padding: 0;
	line-height: 24px;
	color: ${({ color }) => color ?? Colors.grey900};
	font-size: ${FontSize.text_sm};
	font-weight: ${FontWeight.regular};
	flex-grow: 1;
	outline: none;
	::placeholder {
		color: ${Colors.grey500};
		font-size: ${FontSize.text_sm};
	}
	min-width: 0px;

	// below code is to hide arrows to increase and decrease value

	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	[type='number'] {
		-moz-appearance: textfield;
	}
`;

export const inlineStyle = `
	display: inline-block !important;
	vertical-align: middle;
`;

export const StyledFlexContainer = styled(FlexContainer)`
	&:hover {
		border-color: ${Colors.grey300};
	}
`;
