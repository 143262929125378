import styled from '@emotion/styled';
import React, { useContext } from 'react';

import { ReactComponent as EmptyResult } from 'assets/images/something_went_wrong.svg';
import FlexContainer from 'components/Container/FlexContainer';
import EmptyState from 'components/EmptyState';
import Text from 'components/Text';
import { GlobalAppContext } from 'helpers/contexts';
import { FontSize, TextAlign, Colors, FontWeight } from 'theme';
import { FlexJustify, FlexAlignItems, FlexDirection } from 'theme/layout';

const EmptyStateContainer = styled(EmptyState)`
	height: 100vh;
	width: 100vw;
	place-content: center;
	grid-auto-rows: max-content;
`;

const InvalidLink = () => {
	const { isMobile } = useContext(GlobalAppContext);

	return (
		<EmptyStateContainer
			image={EmptyResult}
			label="invalid_link"
			labelProps={{ fontSize: isMobile ? FontSize.text_md : FontSize.text_xl }}
		>
			<FlexContainer
				margin={{ top: 8 }}
				justify={FlexJustify.center}
				alignItems={FlexAlignItems.center}
				direction={isMobile ? FlexDirection.column : FlexDirection.row}
				padding={{ left: 24, right: 24 }}
				columnGap={4}
				textAlign={TextAlign.center}
			>
				<Text
					translation="invalid_link_subtitle"
					color={Colors.grey500}
					fontSize={FontSize.text_sm}
					fontWeight={FontWeight.semiBold}
				/>
			</FlexContainer>
		</EmptyStateContainer>
	);
};

export default InvalidLink;
