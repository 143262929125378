import React from 'react';
import { ArrowSmRightIcon, XmarkIcon } from 'zeda-icons/v3';

import IconButton from '../../Button/IconButton';
import { SelectBaseProps, ToggleButtonProps } from '../calendarTypes';

import RawSelect from './default';
import { ToggleButton } from './SelectCalendar';

import FlexContainer from 'components/Container/FlexContainer';
import Tag from 'components/Tag';
import { TagVariant } from 'components/Tag/Tag';
import Text from 'components/Text';
import { convertTimeToLocalString, DATE_LOCAL_FORMATS } from 'helpers/date';
import { ColorFamily, Colors, FontSize, FontWeight } from 'theme';
import { FlexAlignItems, Space } from 'theme/layout';

const SelectCalendar: React.FC<Omit<SelectBaseProps, 'toggleButton'>> = (props) => {
	const {
		placeholder = 'Select',
		fontSize = FontSize.text_md,
		value,
		canClear = false,
		onClear,
	} = props;

	const showCloseIcon = canClear && onClear;
	const getDateValues = Array.isArray(value) ? (
		<FlexContainer alignItems={FlexAlignItems.center} columnGap={4} width="100%">
			<Tag
				id="selectedStartDate"
				label={convertTimeToLocalString(value?.[0]?.toISOString(), DATE_LOCAL_FORMATS.MMM_DD_YYYY)}
				variant={TagVariant.sm}
			/>
			<ArrowSmRightIcon fontSize={FontSize.text_md} color={Colors.grey500} />
			<Tag
				id="selectedEndDate"
				label={convertTimeToLocalString(value?.[1]?.toISOString(), DATE_LOCAL_FORMATS.MMM_DD_YYYY)}
				variant={TagVariant.sm}
			/>
			{showCloseIcon && (
				<IconButton
					variant="secondary"
					size="xxs"
					colorTheme={ColorFamily.grey}
					onClick={(e) => {
						e.stopPropagation();
						onClear?.();
					}}
					margin={{ left: Space.auto }}
					icon={XmarkIcon}
				/>
			)}
		</FlexContainer>
	) : (
		<Tag
			id="selectedDate"
			label={convertTimeToLocalString(value?.toISOString(), DATE_LOCAL_FORMATS.MMM_DD_YYYY)}
		/>
	);

	const DropDownToggle = (toggleBtnProps: ToggleButtonProps) => {
		const isValueValid = Array.isArray(value) || value instanceof Date;

		return (
			<ToggleButton
				{...toggleBtnProps}
				tag="button"
				type="button"
				className="form-control"
				isFilled={!!value}
			>
				{isValueValid ? (
					getDateValues
				) : (
					<Text
						fontSize={fontSize}
						color={Colors.grey500}
						fontWeight={FontWeight.regular}
						flexGrow={1}
					>
						{placeholder}
					</Text>
				)}
			</ToggleButton>
		);
	};
	return <RawSelect {...props} toggleButton={DropDownToggle} />;
};
export default SelectCalendar;
