import {
	AppActions,
	FetchPortalConfigType,
	FetchWorkspaceDetailsType,
	FETCH_PORTAL_CONFIG,
	FETCH_WORKSPACE_DETAILS,
} from 'actions/AppActions';
import { ExternalFormDataType, FETCH_FEEDBACK_FORM_DATA } from 'actions/FeedbackAction';
import { LOADING_STATUS } from 'constants/constants';
import { reducerWrapper } from 'helpers/action';
import { AppState } from 'types/app';

export const APP_INIT_STATE: AppState = {
	configuration: null,
	workspaceInfo: null,
	loadingStatus: LOADING_STATUS.INIT,
};

const Handler = (state = APP_INIT_STATE, action: AppActions): AppState => {
	switch (action.type) {
		case FETCH_FEEDBACK_FORM_DATA.response: {
			const { data: result } = action as ExternalFormDataType;

			return { ...state, workspaceInfo: result.data.workspace };
		}
		case FETCH_PORTAL_CONFIG.response: {
			const { data } = action as FetchPortalConfigType;
			return { ...state, configuration: data, loadingStatus: LOADING_STATUS.INACTIVE };
		}
		case FETCH_WORKSPACE_DETAILS.response: {
			const { data } = action as FetchWorkspaceDetailsType;
			return { ...state, workspaceInfo: data };
		}
		default:
			return state;
	}
};

const AppReducer = reducerWrapper<AppState, AppActions>(APP_INIT_STATE, Handler, {
	request: [FETCH_PORTAL_CONFIG],
});

export default AppReducer;
