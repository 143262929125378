import { Colors } from 'theme';
import { Attachments } from 'types/feedbacks';

export const isUndefined = (val: any) => val === undefined;

export const noop = () => {};

export function getRandomInt(max: number) {
	return Math.floor(Math.random() * max);
}

export const downloadFile = async (url: string, filename = 'sample') => {
	const a = document.createElement('a');
	a.href = url;
	a.setAttribute('download', filename);
	a.click();
};
// delay function causes a delay in-case required
// usage is delay(time: number) time is in milliseconds
export const delay = (time = 0) =>
	new Promise((resolve) => {
		setTimeout(() => {
			resolve('');
		}, time);
	});

export const openInNewTab = async (url: string) => {
	const a = document.createElement('a');
	a.href = url;
	a.setAttribute('target', '_blank');
	a.click();
};

// background is Colors.primary50 and color is Colors.primary600
export const getAvatarImageUrl = ({
	name,
	rounded = true,
	size = 50,
	colors = Colors,
}: {
	name: string;
	rounded?: boolean;
	size?: number;
	colors?: typeof Colors;
}) => {
	const backgroundColor = colors.primary50.replace('#', '');
	const textColor = colors.primary600.replace('#', '');

	return `https://ui-avatars.com/api/
	?name=${name}&size=${size}&background=${backgroundColor}&color=${textColor}&rounded=${rounded}&bold=true`;
};

export const isZedaEditorEmpty = (text: string) => {
	return !(
		text?.match(/<img([\w\W]+?)>/g) ||
		text?.match(/<\/?(table|td|th|tr|tfoot|thead|tbody)+>?/) ||
		text?.replace(/<\/?[^>]+(>|$)/g, '').trim()?.length > 0
	);
};

export const zeroPadSingleDigit = (number: number): string => {
	if (number > 9 || number <= 0) return String(number);

	return String(number).padStart(2, '0');
};

export const convertToFile = async (fileObj: Attachments) => {
	const file = await fetch(fileObj.url);
	const blob = await file.blob();
	const newfile = new File([blob], `${fileObj.name}`, { type: fileObj.type });
	return newfile;
};

enum EscapeSequences {
	UNDERSCORE = '--underscore--',
	SPACE = '--space--',
	PERIOD = '--period--',
}

export const createAvatarToolTipId = (value: string) =>
	encodeURIComponent(
		value
			.replaceAll(' ', EscapeSequences.SPACE)
			.replaceAll('_', EscapeSequences.UNDERSCORE)
			.replaceAll('.', EscapeSequences.PERIOD)
	);

export const toBase64 = (files: File[]) => {
	return Promise.all(
		files.map(async (file) => {
			const url = await new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});
			return {
				name: file.name,
				type: file.type,
				size: file.size,
				url,
			};
		})
	);
};

const ALPHANUMERIC_CHARACTERS = /[^a-zA-Z0-9]/g;
export const removeSpecialCharacters = (text: string) => {
	const cleanText = text.replace(ALPHANUMERIC_CHARACTERS, '');
	return cleanText;
};

export const generateFileWithEncodedName = (file: File) => {
	return new File(
		[file],
		encodeURIComponent(file.name.replace(/[&/\\#+()'":*?%<>{}%!@$^ ]/g, '')),
		{ type: file.type }
	);
};
