import styled from '@emotion/styled';
import React from 'react';

import {
	buttonBlockPadding,
	buttonBorderRadius,
	buttonHeights,
	ButtonProps,
	getButtonStyles,
	iconSizes,
} from './Button';
import { DefaultButton } from './default';

import Text from 'components/Text';
import strings from 'constants/translation';
import { ColorFamily, FontSize, FontWeight } from 'theme';
import { FontSizeKeys } from 'types';

const StyledButton = styled(DefaultButton)<ButtonProps>`
	${({ size = 'md' }) => `padding: ${buttonBlockPadding[size]}px 12px`};
	display: inline-flex;
	align-items: center;
	cursor: pointer;

	${({ colorTheme, variant, theme }) =>
		colorTheme && getButtonStyles(variant, colorTheme, theme.colors)}
`;

/**
 * For a button component, let's keep the prop order as:
 * 0. key/className
 * 1. onClick - Main functionality of the button is the click event
 * 2. Variant
 * 3. Size
 * 4. Color/Theme
 * 5. Tranlation/Icon
 * 6. Other style props - (margin, padding)
 */

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
	const {
		size = 'md',
		variant = 'primary',
		colorTheme = ColorFamily.primary,
		className,
		htmlElement = 'button',
		leadingIcon: LeadingIcon,
		trailingIcon: TrailingIcon,
		border,
		translation,
		...rest
	} = props;

	const textContent = translation && size !== 'xxs' && (
		<Text
			htmlTagName="span"
			className="btn-text"
			fontSize={FontSize[`text_${size}` as FontSizeKeys]}
			fontWeight={FontWeight.semiBold}
			translation={translation}
			margin={{
				...(LeadingIcon && { left: 8 }),
				...(TrailingIcon && { right: 8 }),
			}}
		/>
	);

	const btnChildContent = props.children ?? textContent;

	return (
		<StyledButton
			className={className}
			border={{ radius: buttonBorderRadius[size], ...border }}
			type="button"
			height={buttonHeights[size]}
			variant={variant}
			colorTheme={colorTheme}
			htmlElement={htmlElement}
			size={size}
			{...(translation ? { title: strings[translation] } : {})}
			{...rest}
		>
			{LeadingIcon && (
				<LeadingIcon className="btn-icon btn-leading-icon" fontSize={iconSizes[size]} />
			)}
			{btnChildContent}
			{TrailingIcon && (
				<TrailingIcon className="btn-icon btn-trailing-icon" fontSize={iconSizes[size]} />
			)}
		</StyledButton>
	);
};

Button.defaultProps = {
	variant: 'primary',
	colorTheme: ColorFamily.primary,
	size: 'md',
};

export default Button;
