import { ThemeProvider } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import FeedbackForm from './Screens/FeedbackForm';

import { FetchPortalConfig, FetchWorkspaceDetails } from 'actions/AppActions';
import { FetchUserInfo } from 'actions/UserAction';
import Toast from 'components/Toast';
import { envConfig } from 'constants/constants';
import translation from 'constants/translation';
import InvalidLink from 'containers/emptystates/InvalidLink';
import PrivatePortalAccess from 'containers/emptystates/PrivatePortalAccess';
import SomethingWentWrong from 'containers/emptystates/SomethingWentWrong';
import ErrorBoundary from 'containers/ErrorBoundary';
import AnalyticsHelper from 'helpers/analytics';
import { GlobalAppContext } from 'helpers/contexts';
import { getUserAccessToken, setWorkspaceId } from 'helpers/storage';
import { generateColorPalette } from 'helpers/theme';
import { useDeviceDimensions } from 'hooks';
import Router from 'routes';
import { restrictedKeys, RouteConstants } from 'routes/config';
import { getUserInfo } from 'selectors/UserSelector';
import { Colors } from 'theme';
import { PortalConfig } from 'types/app';
import { RootState } from 'types/state';

(window as WindowExtended).zedaAnalytics = AnalyticsHelper;

function App(props: ReduxProps) {
	const dimensions = useDeviceDimensions();
	const [theme, setTheme] = useState({ colors: Colors });
	const [errorObj, setErrorObj] = useState<{ code: number } | null>(null);
	const isExternalForm = window.location.pathname.includes('req/form');
	const isAuthCallback = window.location.pathname.includes(RouteConstants.AUTH_CALLBACK);
	const isAutoLogin = window.location.pathname.includes(RouteConstants.AUTO_LOGIN);

	const setupPortal = (data: PortalConfig, workspaceName: string) => {
		setWorkspaceId(data.workspaceId);
		const portalThemeColor = data.theme.color;
		// Adds title for the page
		document.title = data.theme.tabLabel || workspaceName || translation.customer_portal;

		// Updates favIcon of the page
		const linkElement = document.querySelector('link[rel=icon]');
		if (!linkElement) return;
		(linkElement as HTMLAnchorElement).href = data.theme.favIcon;

		// Update meta description
		const descriptionElement = document.querySelector('meta[name=description]');
		if (descriptionElement) {
			(descriptionElement as HTMLMetaElement).content = translation.page_meta_description.replace(
				'%1',
				workspaceName
			);
		}

		if (!portalThemeColor) return;
		const colorPalette = generateColorPalette(portalThemeColor, 'primary');

		setTheme({ ...theme, colors: { ...Colors, ...colorPalette } });
	};

	const isCustomDomain = window.location.host !== envConfig.portal_url;
	const uniqueName = isCustomDomain ? '' : window.location.pathname.split('/')[1];
	const boardUniqueName = isCustomDomain
		? window.location.pathname.split('/')[1]
		: window.location.pathname.split('/')[2];
	const hasUniqueName = !restrictedKeys.includes(uniqueName);
	const hasBoardUniqueName = boardUniqueName && !restrictedKeys.includes(boardUniqueName);

	const fetchInitData = async () => {
		const { data: portalConfig, error } = await props.getPortalSettings({
			uniqueName: isCustomDomain || !hasUniqueName ? '' : uniqueName,
			host: window.location.host,
			boardUniqueName: !hasBoardUniqueName ? '' : boardUniqueName,
		});

		if (error) {
			setErrorObj({
				code: error?.response?.status ?? 500,
			});
			return;
		}

		// if someone goes to old portal url we redirect them to the default board for which we get the url from backend.

		if (portalConfig?.redirectUrl) {
			window.location.href = portalConfig.redirectUrl;
			return;
		}

		const { data: workspace } = await props.getWorkspaceInfo(portalConfig.workspaceId);
		setupPortal(portalConfig, workspace.name);
		setWorkspaceId(workspace.id);

		const accessToken = getUserAccessToken();
		if (accessToken && props.userInfo) return;

		const { data: userData } = await props.getUserInfo(workspace.id, portalConfig.boardUniqueName);

		(window as WindowExtended).zedaAnalytics.identify(userData, workspace);
	};

	useEffect(() => {
		if (isExternalForm || isAuthCallback || isAutoLogin) return;
		fetchInitData();
	}, []);

	// Error screen for forbidden links - For eg: Trying to access a private portal page
	if (errorObj?.code === 403) {
		return <PrivatePortalAccess />;
	}

	// Error screen for invalid links
	if (errorObj?.code === 400) {
		return <InvalidLink />;
	}

	// Something went wrong page
	if (errorObj?.code === 500) {
		return <SomethingWentWrong />;
	}

	return (
		<ErrorBoundary>
			<ThemeProvider theme={{ ...theme, setTheme }}>
				<GlobalAppContext.Provider value={dimensions}>
					<Toast />
					{isExternalForm ? (
						<FeedbackForm />
					) : (
						<Router
							isCustomDomain={isCustomDomain}
							uniqueName={isCustomDomain || !hasUniqueName ? '' : uniqueName}
							boardUniqueName={boardUniqueName}
						/>
					)}
				</GlobalAppContext.Provider>
			</ThemeProvider>
		</ErrorBoundary>
	);
}

const mapStateToProps = (state: RootState) => ({
	userInfo: getUserInfo(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getPortalSettings: (body: { uniqueName?: string; host: string; boardUniqueName?: string }) =>
		dispatch(FetchPortalConfig.request(body)),
	getWorkspaceInfo: (id: string) => dispatch(FetchWorkspaceDetails.request(id)),
	getUserInfo: (id: string, boardUniqueName: string) =>
		dispatch(FetchUserInfo.request(id, boardUniqueName)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(App);
