import React, { Component, ReactNode } from 'react';

import { ErrorState } from 'constants/constants';
import SomethingWentWrong from 'containers/emptystates/SomethingWentWrong';

interface Props {
	children: ReactNode;
}

interface State {
	hasError: boolean;
	type: ErrorState;
}

const ErrorScreens: Partial<{ [key in ErrorState]: React.ComponentType<any> }> = {
	[ErrorState.DEFAULT]: SomethingWentWrong,
};

class ErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false, type: ErrorState.DEFAULT };
	}

	static getDerivedStateFromError(error: any) {
		try {
			// For user defined Error screens
			const { type } = JSON.parse(error.message);
			return { hasError: true, type };
		} catch (err) {
			// For generic Error screens
			return { hasError: true, type: ErrorState.DEFAULT };
		}
	}

	public render() {
		const { hasError, type } = this.state;
		if (!hasError) return this.props.children;

		if (type && ErrorScreens[type]) {
			const ErrorComponent = ErrorScreens[type]!;
			return <ErrorComponent />;
		}

		return <SomethingWentWrong />;
	}
}

export default ErrorBoundary;
