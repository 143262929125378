import React from 'react';
import Calendar from 'react-calendar';
import { ChevronUpIcon, ChevronDownIcon } from 'zeda-icons/v3';

import Button from '../Button';
import IconButton from '../Button/IconButton';

import {
	CalendarContainer,
	RangeContainer,
	StyledArrowIcon,
	StyledInput,
	StyledFlexContainer,
} from './Calendar';
import { ActiveDate, BaseCalendarProps } from './calendarTypes';

import FlexContainer from 'components/Container/FlexContainer';
import Text from 'components/Text';
import strings from 'constants/translation';
import { convertJsDateToDateObj } from 'helpers/date';
import { noop } from 'helpers/utils';
import { ColorFamily, Colors, FontSize, FontWeight } from 'theme';
import { FlexDirection, FlexJustify, Position } from 'theme/layout';

const BaseCalendar = (props: BaseCalendarProps) => {
	const {
		view = 'month',
		value,
		isRangeEnabled = false,
		inputRef,
		className,
		locale,
		maxDate,
		minDate,
		maxWidth,
		onClickDay,
		activeDate,
		setActiveDate,
		// activeStartDate is used to navigate to 'Today'. The library navigates to the month of the activeStartDate when the prop is provided.
		activeStartDate,
		setActiveStartDate,
	} = props;

	const configuration = {
		view,
		value,
		locale,
		maxDate,
		minDate,
		inputRef,
		onClickDay,
	};

	const renderRangeDate = (
		placeHolder: string,
		date: Date | undefined,
		activeDateValue?: ActiveDate
	) => {
		return (
			<FlexContainer
				direction={FlexDirection.column}
				width={isRangeEnabled ? 146 : '100%'}
				padding={{ all: 8 }}
				rowGap={8}
				flexGrow={1}
			>
				<Text fontSize={FontSize.text_sm} fontWeight={FontWeight.semiBold}>
					{placeHolder}
				</Text>
				<StyledInput
					margin={{ all: 0 }}
					value={date ? convertJsDateToDateObj(date).toFormat('DD') : ''}
					placeholder={placeHolder}
					height={44}
					border={{
						all: 1,
						color: activeDate === activeDateValue ? Colors.primary600 : Colors.grey300,
					}}
					readOnly
					onChange={noop}
					onClick={() => setActiveDate(activeDateValue ?? 'startDate')}
				/>
			</FlexContainer>
		);
	};

	const navigateToToday = (currentDate: Date) => {
		setActiveStartDate(currentDate);
	};

	return (
		<CalendarContainer onClick={(e) => e.stopPropagation()} className={className} flexShrink={0}>
			{isRangeEnabled ? (
				<RangeContainer width={356} position={{ value: Position.relative }}>
					{renderRangeDate(strings.start_date, (value as Date[])?.[0], 'startDate')}
					<StyledArrowIcon color={Colors.grey500} fontSize={FontSize.display_xs} />
					{renderRangeDate(strings.end_date, (value as Date[])?.[1], 'endDate')}
				</RangeContainer>
			) : (
				<RangeContainer width={312}>
					{renderRangeDate(strings.select_date, value instanceof Date ? value : undefined)}
				</RangeContainer>
			)}
			<StyledFlexContainer
				direction={FlexDirection.column}
				border={{ top: 1, color: Colors.grey200 }}
				width={isRangeEnabled ? 356 : 312}
				maxWidth={maxWidth}
				justify={FlexJustify.center}
				padding={{
					top: 16,
					bottom: 16,
					left: isRangeEnabled ? 38 : 16,
					right: isRangeEnabled ? 38 : 16,
				}}
				position={{ value: Position.relative }}
			>
				<Calendar
					{...configuration}
					nextLabel={
						<IconButton
							size="xs"
							variant="secondary"
							icon={ChevronUpIcon}
							colorTheme={ColorFamily.grey}
							onClick={() => setActiveStartDate(undefined)}
						/>
					}
					prevLabel={
						<IconButton
							size="xs"
							variant="secondary"
							icon={ChevronDownIcon}
							colorTheme={ColorFamily.grey}
							onClick={() => setActiveStartDate(undefined)}
						/>
					}
					next2Label={null}
					prev2Label={null}
					showFixedNumberOfWeeks={false}
					activeStartDate={activeStartDate}
					formatShortWeekday={(_: any, date: Date) => {
						return date.toString().slice(0, 2);
					}}
				/>
				<Button
					position={{ value: Position.absolute, top: 16, right: isRangeEnabled ? 124 : 102 }}
					variant="secondary"
					size="xs"
					onClick={() => navigateToToday(new Date())}
					translation="today"
					colorTheme={ColorFamily.grey}
				/>
			</StyledFlexContainer>
		</CalendarContainer>
	);
};

export default BaseCalendar;
