import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useRef } from 'react';

import ContainerComp from 'components/Container/default';
import Modal, { ModalInterface } from 'components/Modal';

interface SidebarProps extends ModalInterface {
	children?: JSX.Element;
	direction: 'left-right' | 'right-left';
}

const getSlideAnimation = (width: number | string, position: 'left' | 'right') => keyframes`
	from {${position}: -${width}px}
	to {${position}: 0}
`;

const Container = styled(ContainerComp)<{
	width: number | string;
	height: number | string;
	startPos: 'left' | 'right';
}>`
	position: fixed;
	bottom: 0;
	top: 0;
	${({ startPos }) => startPos}: 0;
	height:${({ height }) => height});
	width: ${({ width }) => width};
	transition: all 0.5s;
	background-color: #ffffff;
	z-index: 1050;
	animation-name: ${({ width, startPos }) => getSlideAnimation(width, startPos)};
	animation-duration: 0.5s;
`;

const Sidebar: React.FC<SidebarProps> = (props) => {
	const {
		height = '100%',
		width = '100vw',
		children,
		direction = 'right-left',
		toggle,
		...rest
	} = props;
	const ref = useRef<HTMLDivElement>(null);

	const [startPos] = direction.split('-');

	const handleToggle = () => {
		if (ref?.current) {
			ref.current.style[startPos as 'right' | 'left'] = `-${width}px`;
		}
		setTimeout(toggle, 500);
	};

	return (
		<Modal
			height={height}
			width={width}
			toggle={handleToggle}
			top="0"
			isCenter={false}
			{...{ [startPos]: '0' }}
			{...rest}
		>
			<Container
				startPos={startPos as 'left' | 'right'}
				height={height}
				width={width}
				ref={ref}
				{...rest}
			>
				{children}
			</Container>
		</Modal>
	);
};

export default Sidebar;
