import styled from '@emotion/styled';
import React from 'react';
import ZedaEditorComp from 'zeda-editor';

import { EditorType, EDITOR_TYPE_INFO } from './config';

import request from 'helpers/request';

const CustomZedaEditor = styled(ZedaEditorComp)`
	.ProseMirror {
		font-family: 'Satoshi-Variable', sans-serif;
		font-feature-settings: 'tnum' on, 'lnum' on, 'ss03' on;
		outline: none !important;
	}
`;

interface ZedaEditorProps {
	id?: string;
	showBubbleMenu: boolean;
	type: EditorType;
	value: string;
	readOnly?: boolean;
	name?: string;
	placeholder?: string;
	autofocus?: boolean;
	onChange?: (value: string) => void;
	containerClass?: string;
	editorRef?: any;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	[key: string]: any;
}

const ZedaEditor: React.FC<ZedaEditorProps> = (props) => {
	const { type, showBubbleMenu = true, autofocus = false, ...rest } = props;
	const typeInfo = EDITOR_TYPE_INFO[type];
	const zedaEditorConfig = {
		showBubbleMenu,
		enabledShortcuts: typeInfo?.enabledShortcuts,
		enabledBubbleMenuOptions: typeInfo?.bubbleMenu,
	};

	const fetchMetadata = (url: string) => request.get(`/metadata/?url=${url}`);

	return (
		<CustomZedaEditor
			autofocus={autofocus}
			type={type}
			fetchMetadata={fetchMetadata}
			config={zedaEditorConfig}
			{...rest}
		/>
	);
};

export default ZedaEditor;
