import { FormikProps } from 'formik';
import React from 'react';

import { FormikValueType } from './FeedbackForm';

import SelectCalendar from 'components/Calendar/SelectCalendar';
import Container from 'components/Container/default';
import Input from 'components/Input';
import MultiSelect from 'components/MultiSelect';
import { BasicOption } from 'components/MultiSelect/MultiSelect';
import Select from 'components/Select';
import Text from 'components/Text';
import translation from 'constants/translation';
import { Colors, FontSize, FontWeight } from 'theme';
import { CustomFieldFormType, CustomFieldType } from 'types/feedbacks';

interface CustomFieldsListProps {
	customFieldsList: CustomFieldFormType[];
	formikContext: FormikProps<FormikValueType>;
}

interface CustomFieldInputProps {
	customField: CustomFieldFormType;
	formikContext: FormikProps<FormikValueType>;
	index: number;
}

const CustomFieldInput = (props: CustomFieldInputProps) => {
	const { customField, index, formikContext } = props;
	const inputType = customField.type === CustomFieldType.NUMERIC ? 'number' : 'text';

	const handleCustomFieldUpdate = (value: string | number | string[], id?: string) => {
		const updatedCustomFieldList = formikContext.values.customFields?.map((field) => {
			if (field.typeId === id) {
				return { ...field, value };
			}
			return field;
		});
		formikContext.setFieldValue('customFields', updatedCustomFieldList);
	};

	switch (customField.type) {
		case CustomFieldType.LIST: {
			const options = customField?.options?.map((option) => ({
				value: option,
				label: option,
			}));

			return (
				<Select
					placeholder={translation.select}
					name={`customFields[${index}].value`}
					disable={false}
					options={options}
					fontSize="text_md"
					value={customField.value as string}
					border={{ all: 1, radius: 8, color: Colors.grey300 }}
					onChange={(value: any) => handleCustomFieldUpdate(value, customField.typeId)}
					onBlur={formikContext.handleBlur}
					tickOnSelect
					width="100%"
					padding={{ right: 14, top: 10, left: 14, bottom: 10 }}
				/>
			);
		}

		case CustomFieldType.MULTI_SELECT: {
			const options: BasicOption[] = customField?.options?.map((option) => ({
				label: option,
				value: option,
				itemProps: { fontSize: 'text_sm' },
			}));
			return (
				<MultiSelect
					name={`customFields[${index}].value`}
					values={(customField.value as string[]) ?? []}
					options={options}
					height={44}
					width="100%"
					onChange={(value) => handleCustomFieldUpdate(value as string[], customField.typeId)}
					onBlur={formikContext.handleBlur}
					placeholder={translation.select}
					bgColor="white"
				/>
			);
		}

		case CustomFieldType.DATE: {
			const dateValue = customField.value ? new Date(customField.value as string) : null;

			return (
				<SelectCalendar
					name={`customFields[${index}].value`}
					isRangeEnabled={false}
					height={44}
					placeholder={translation.select_date}
					onBlur={formikContext.handleBlur}
					value={dateValue}
					margin={{ all: 0 }}
					onChange={(updatedDateValue) => {
						const updatedValue = (updatedDateValue as Date)?.toISOString() ?? '';
						handleCustomFieldUpdate(updatedValue, customField.typeId);
					}}
				/>
			);
		}

		case CustomFieldType.NUMERIC:
		case CustomFieldType.TEXT:
		default:
			return (
				<Input
					name={`customFields[${index}].value`}
					value={customField.value}
					type={inputType}
					onChange={formikContext.handleChange}
					onBlur={formikContext.handleBlur}
					placeholder={customField.type === CustomFieldType.NUMERIC ? 'Enter Number' : 'Enter Text'}
					maxLength={255}
				/>
			);
	}
};

const CustomFieldsList = (props: CustomFieldsListProps) => {
	const { customFieldsList, formikContext } = props;

	const customFields = customFieldsList?.map((customField, index) => (
		<Container
			key={customField.typeId}
			margin={{ top: 16, bottom: 16 }}
			bgColor={Colors.transparent}
		>
			<Text
				flexGrow={1}
				fontWeight={FontWeight.semiBold}
				fontSize={FontSize.text_sm}
				color={Colors.grey700}
				isMandatoryLabel={customField.isRequired}
			>
				{customField.name}
			</Text>
			<CustomFieldInput customField={customField} formikContext={formikContext} index={index} />
		</Container>
	));

	return <>{customFields}</>;
};

export default CustomFieldsList;
