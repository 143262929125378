import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import { Logout } from 'actions/UserAction';
import { ReactComponent as PrivatePortalAccessImage } from 'assets/images/no_access.svg';
import LinkButton from 'components/Button/LinkButton';
import FlexContainer from 'components/Container/FlexContainer';
import EmptyState from 'components/EmptyState';
import Text from 'components/Text';
import { EMPTY_STATE_DIMENSIONS } from 'constants/constants';
import { GlobalAppContext } from 'helpers/contexts';
import { removeCookie } from 'helpers/storage';
import { FontSize, TextAlign, Colors, FontWeight } from 'theme';
import { FlexJustify, FlexAlignItems, FlexDirection } from 'theme/layout';

const EmptyStateContainer = styled(EmptyState)`
	height: 100vh;
	width: 100vw;
	place-content: center;
	grid-auto-rows: max-content;
`;

const PrivatePortalAccess = (props: ReduxProps) => {
	const { isMobile, currentScreen } = useContext(GlobalAppContext);

	const handleLogout = async () => {
		await props.logout();
		removeCookie('portalAccessToken');
		window.location.reload();
	};

	return (
		<EmptyStateContainer
			image={PrivatePortalAccessImage}
			label="no_access_title"
			labelProps={{ fontSize: isMobile ? FontSize.text_md : FontSize.text_xl }}
			{...EMPTY_STATE_DIMENSIONS[currentScreen]}
		>
			<FlexContainer
				margin={{ top: 8 }}
				justify={FlexJustify.center}
				alignItems={FlexAlignItems.center}
				direction={FlexDirection.column}
				padding={{ left: 24, right: 24 }}
				columnGap={4}
				textAlign={TextAlign.center}
			>
				<Text
					translation="no_access_desc"
					color={Colors.grey500}
					fontSize={FontSize.text_sm}
					fontWeight={FontWeight.semiBold}
				/>
				<LinkButton onClick={handleLogout} size="sm" translation="login_different_user" />
			</FlexContainer>
		</EmptyStateContainer>
	);
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	logout: () => dispatch(Logout.request()),
});

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PrivatePortalAccess);
