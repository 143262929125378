import { FeedbackDetail, FeedbackItem } from 'types/feedbacks';

export const getDataWithUpdatedVoteCount = (
	prevFeedbackData: FeedbackItem | FeedbackDetail,
	updatedFeedbackData: FeedbackItem | FeedbackDetail,
	keys: string[]
) => {
	return {
		...prevFeedbackData,
		...(keys.includes('upvoteCount') && {
			upvoteCount: updatedFeedbackData.isUpvoted
				? updatedFeedbackData.upvoteCount + 1
				: Math.max(0, updatedFeedbackData.upvoteCount - 1),
		}),
		...(keys.includes('isUpvoted') && { isUpvoted: updatedFeedbackData.isUpvoted }),
		...(keys.includes('upvotedBy') && {
			upvotedBy: (updatedFeedbackData as FeedbackDetail).upvotedBy,
		}),
	};
};
