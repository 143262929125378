export default {
	activity: 'Activity',
	add_comment_placeholder: 'Leave a Comment',
	back: 'Back',
	back_text: 'Back',
	clear: 'Clear',
	apply: 'Apply',
	back_to_dashboard_cta: 'Go to dashboard',
	categories: 'Categories',
	continue_reading: 'Continue Reading',
	copied: 'Copied',
	copy_link: 'Copy Link',
	count: '20',
	customer_portal: 'Customer Portal',
	customer_portal_logo: 'Logo',
	download: 'Download',
	email_text: 'Email',
	invalid_email: 'Invalid Email',
	feedback_submitted: 'Feedback submitted successfully',
	feedback_submitted_subtext:
		'Your feedback is being considered. We will let you know when we begin working on it.',
	file_size_exceeds: 'The file size is more than 2 MB.',
	give_feedback: 'Give feedback',
	create_new_feedback: 'Create New Feedback',
	give_feedback_subtitle: 'Tell us how we can make our product more useful for you',
	give_us_feedback: 'Give us feedback',
	leave_comment: 'Leave a comment',
	likes: '%1 Likes',
	likes_heading: 'Likes',
	login_cta: 'Login',
	login_signup_cta: 'Login / Signup',
	login_to_submit: 'Login to submit',
	login_to_comment: 'Login to comment',
	logout_cta: 'Logout',
	login_different_user: 'Login with different account',
	name_text: 'Name',
	description_required: 'Description is required',
	newest_first: 'Newest First',
	no_access_title: 'You don’t have access to this portal',
	no_access_desc: 'Contact the admin to access this portal.',
	no_comments_cta: 'Help enhance this feedback by sharing your opinion.',
	no_filter_result_subtitle: 'Try adjusting your filters to find what you are looking for.',
	no_internet_desc: 'Looks like you are not connected, please ',
	no_internet_title: 'No internet',
	no_likes_yet: 'No likes yet',
	no_preview: 'No preview available',
	no_result_found: 'No results found',
	no_search_result_subtitle: 'Try adjusting your search to find what you are looking for.',
	no_votes_yet: 'No votes yet',
	oldest_first: 'Oldest First',
	page_not_found: 'Page not found',
	page_not_found_description: "What you are looking for may have been removed or doesn't exist",
	powered_by: 'Powered by',
	private_portal_no_access_desc: 'Contact the %1 to access this portal',
	private_portal_no_access_title: 'You don’t have access to this portal',
	private_portal_unauthorized_desc: 'To access this portal, please ',
	private_portal_unauthorized_title: 'This portal is private',
	public_roadmap_url_cta: 'Check out what we are building for you!',
	release_note_does_not_exist: 'Oops something is lost!',
	release_note_does_not_exist_subtitle: 'What you are looking for may have been removed!',
	reply: 'Reply',
	retry_cta: 'Retry',
	select: 'Select',
	share_thoughts: 'Share your thoughts',
	somethings_brewing: "Something's brewing",
	somethings_brewing_subtitle: 'We will be sharing product updates with you, very soon!',
	invalid_link: `Oops, this link isn't valid`,
	invalid_link_subtitle:
		'The link you entered appears to be incorrect. Please check the spelling and try again ',
	something_went_wrong: 'Something went wrong',
	something_went_wrong_desc: 'Oops! Something went wrong. ',
	deleted_item: 'This link does not exist anymore',
	deleted_item_subtext: 'What you are looking for may have been removed',
	go_back_cta: ' Go back',
	sort_by: 'Sort by',
	submit_another: 'Submit another feedback',
	submit_text: 'Submit',
	cancel_text: 'Cancel',
	summary_placeholder: 'More context will help us solve this problem better',
	summary_text: 'Summary',
	tags: 'Tags',
	title_placeholder: 'Tell us what this feedback is about',
	title_text: 'Title',
	type_label: 'Type of Feedback',
	type_placeholder: 'Pick a type of feedback',
	upvote_feedback_desc: 'Check out similar feedbacks. Found anything relevant? Give it an upvote',
	voter_text: 'Voters',
	page_meta_description:
		"Check out %1's portal to share your feedback with them and get to know what they are building",
	feedback_form_meta_description:
		"Share feedback with %1's product team to make the product more useful.",
	something_wrong_try_later: 'Something went wrong. Try again later.',
	timeline: 'Timeline',

	// Duration
	today: 'Today',
	this_week: 'This week',
	two_weeks_from_current_date: '2 weeks from now',
	this_month: 'This month',
	last_month: 'Last month',
	this_quarter: 'This quarter',
	last_quarter: 'Last quarter',
	next_quarter: 'Next quarter',
	next_to_next_quarter: 'Next-to-next quarter',
	six_months_from_current_date: 'Six months from now',
	start_date: 'Start Date',
	end_date: 'End Date',
	select_date: 'Select date',
};
