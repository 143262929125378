import { MutableRefObject, RefObject, ChangeEvent, MouseEvent } from 'react';
import { DropdownProps } from 'reactstrap';

import { DirectionType } from 'components/Dropdown';
import { Colors, FontSize } from 'theme';
import { BorderModel, Box } from 'theme/types';

export type ViewType = 'month';
export type ValueType = Date | [Date] | [Date, Date] | undefined | null;

export type OnDateChangeCallback = (
	value: ValueType,
	event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>
) => void;
export type OnChangeDateCallback = (
	value: ValueType,
	e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>
) => void;
export type OnDateClickCallback = (
	value: Date,
	event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>
) => void;

export enum RichOptions {
	THIS_WEEK,
	TWO_WEEKS_FROM_NOW,
	THIS_MONTH,
	THIS_QUARTER,
	NEXT_QUARTER,
	NEXT_TO_NEXT_QUARTER,
	SIX_MONTHS_FROM_NOW,
}

export interface RichOptionsType {
	label: string;
	value: [Date] | [Date, Date];
}

export type RichCalendarOptions = {
	[k in RichOptions]?: RichOptionsType;
};

export interface CommonProps {
	className?: string | undefined;
	inputRef?:
		| ((ref: HTMLInputElement | null) => void)
		| RefObject<HTMLInputElement | null>
		| MutableRefObject<HTMLInputElement | null>
		| undefined;
	locale?: string | undefined;
	maxDate?: Date | undefined;
	minDate?: Date | undefined;
	isRangeEnabled?: boolean | undefined;
	value?: ValueType;
	view?: ViewType | undefined;
	width?: string | number;
	maxWidth?: string | number;
}

export type ActiveDate = 'startDate' | 'endDate';

export interface BaseCalendarProps extends CommonProps {
	onChange?: OnDateChangeCallback | undefined;
	onClickDay?: OnDateClickCallback | undefined;
	activeDate: ActiveDate;
	activeStartDate: Date | undefined;
	setActiveDate: React.Dispatch<React.SetStateAction<ActiveDate>>;
	setSelectedValue: React.Dispatch<React.SetStateAction<ValueType>>;
	setActiveStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

export interface CalendarProps extends CommonProps {
	isRichCalendar?: boolean;
	onChange?: OnChangeDateCallback | undefined;
	onCancel?: () => void | undefined;
	richOptions?: RichOptions[];
}

type optionType = {
	label: string;
	value: string | number;
	element?: JSX.Element;
};

export enum LabelPosition {
	top = 'top',
	left = 'left',
}

export interface ToggleButtonProps {
	name?: string;
	direction?: DirectionType;
	children?: React.ReactNode;
	placeholder?: string;
	fontSize?: FontSize;
	isOpen?: boolean;
	padding?: Partial<Box>;
	onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
	color?: Colors;
}

export interface CustomDropdownProps extends DropdownProps {
	border?: BorderModel;
	flexGrow?: number;
	flexShrink?: number;
	flexBasis?: string | number;
	margin?: Partial<Box>;
	width?: string | number;
	height?: string | number;
}

export interface SelectBaseProps extends ToggleButtonProps, CalendarProps {
	id?: string;
	label?: string;
	labelClass?: string;
	options?: optionType[];
	width?: string | number;
	height?: string | number;
	zIndex?: number;
	bgColor?: string;
	color?: Colors;
	noOptionsMessage?: string;
	className?: string;
	toggleButton: React.ElementType;
	isInlineView?: boolean;
	disabled?: boolean;
	labelPosition?: LabelPosition;
	border?: BorderModel;
	flexGrow?: number;
	flexShrink?: number;
	flexBasis?: string | number;
	margin?: Partial<Box>;
	container?: string;
	canClear?: boolean;
	onClear?: () => void;
	isRichCalendar?: boolean;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}
