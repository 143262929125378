import styled from '@emotion/styled';
import React from 'react';
import { SearchAltIcon, XmarkIcon } from 'zeda-icons/v3';

import IconButton from '../Button/IconButton';

import { SearchInputProps } from './Input';

import Input from './index';

import { noop } from 'helpers/utils';
import { ColorFamily, Colors } from 'theme';

export const CustomCloseIcon = styled(XmarkIcon)`
	cursor: pointer;
`;

const CustomSearchIcon = styled(SearchAltIcon)`
	margin-right: 8px;
`;

const SearchInput = (props: SearchInputProps) => {
	const { clear = noop, placeholder = 'Search', ...rest } = props;
	const suffixComponent = props.value && (
		<IconButton
			margin={{ left: 8 }}
			icon={CustomCloseIcon}
			colorTheme={ColorFamily.grey}
			variant="tertiary"
			onClick={clear}
			size="xxs"
		/>
	);
	const prefixComponent = <CustomSearchIcon color={Colors.grey500} fontSize={20} />;

	return (
		<Input
			placeholder={placeholder}
			prefixComponent={prefixComponent}
			suffixComponent={suffixComponent}
			{...rest}
		/>
	);
};

export default SearchInput;
