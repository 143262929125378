import styled from '@emotion/styled';
import React from 'react';

import Dropdown, { DropdownToggle } from 'components/Dropdown';
import { SelectDropdownItemProps } from 'components/Dropdown/DropdownItem/DropdownItem';
import Tag from 'components/Tag';
import { Colors, TextAlign } from 'theme';
import { FlexAlignSelf, FlexJustify } from 'theme/layout';
import { Border, Padding } from 'theme/mixins';
import { BorderModel, Box, PositionModel } from 'theme/types';
import { ColorKeys, FontSizeKeys, FontWeightkeys } from 'types';

export interface BaseMultiSelectProps {
	/**
	 * Margin for toggle button
	 */
	margin?: Partial<Box>;
	/**
	 * Additional CSS class
	 */
	className?: string;
	/**
	 * Set how toggle button is positioned in DOM
	 */
	position?: PositionModel;
	/**
	 * Flex basis - Only applied if parent is a flex container
	 */
	flexBasis?: string | number;
	/**
	 * Flex grow - Only applied if parent is a flex container
	 */
	flexGrow?: number;
	/**
	 * Flex shrink - Only applied if parent is a flex container
	 */
	flexShrink?: number;
	/**
	 * Flex align self - Only applied if parent is a flex container
	 */
	alignSelf?: FlexAlignSelf;
	/**
	 * Flex justify self - Only applied if parent is a flex container
	 */
	justifySelf?: FlexJustify;
	/**
	 * Grid row start position - Only applied if parent is a grid container
	 */
	gridRowStart?: number;
	/**
	 * Grid row end position - Only applied if parent is a grid container
	 */
	gridRowEnd?: number;
	/**
	 * Grid column start position - Only applied if parent is a grid container
	 */
	gridColumnStart?: number;
	/**
	 * Grid column end position - Only applied if parent is a grid container
	 */
	gridColumnEnd?: number;
	/**
	 * Background color for the toggle button
	 */
	bgColor?: ColorKeys;
	/**
	 * Height of the Button
	 */
	height?: string | number;
	/**
	 * Border for toggle button
	 */
	border?: BorderModel;
	/**
	 * Padding for toggle button
	 */
	padding?: Partial<Box>;
	/**
	 * Font size for toggle button children
	 */
	fontSize?: FontSizeKeys;
	/**
	 * Font weight for toggle button children
	 */
	fontWeight?: FontWeightkeys;
	/**
	 * Color for toggle button children
	 */
	color?: ColorKeys;
	/**
	 * Width of the input dropdown
	 */
	width?: string | number;
	/**
	 * element to be rendered inside menu
	 */
	children?: React.ReactNode;
	/**
	 * Disable dropdown
	 */
	disable?: boolean;
	/**
	 * Alignment of text within toggle button
	 */
	textAlign?: TextAlign;
	/**
	 * Alignment of text within toggle button
	 */
	placeholder?: React.ReactNode;
	/**
	 * Remove selected option
	 */
	removeSelected?: () => void;
	/**
	 * Active value
	 */
	values?: (string | number)[];
	/**
	 * Customized default button
	 */
	toggleButton?: React.ReactNode;
	/**
	 * parent of the drop down
	 */
	container?: string;
	/**
	 * Function triggered on clicking on item
	 */
	onChange?: (value: (string | number)[], event?: React.MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Function triggered when focus is lost from the select component
	 */
	onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
	/**
	 * Options to show in the menu
	 */
	options: BasicOption[];
	/**
	 * Is inline component
	 */
	isInline?: boolean;
	label?: string;
	name?: string;
	hintText?: string;
	showSelectedBackground?: boolean;
}

export interface BasicOption {
	value: string | number;
	label: string;
	disabled?: boolean;
	element?: React.ReactNode;
	itemProps?: Omit<SelectDropdownItemProps, 'children' | 'disabled'>;
}

export const StyledDropdown = styled(Dropdown)`
	position: relative;
`;

export const MultiSelectDropdownToggle = styled(DropdownToggle)<
	Omit<BaseMultiSelectProps, 'children' | 'options'> & { openMenu: boolean; isInvalid: boolean }
>`
	display: flex;
	background-color: ${({ bgColor = Colors.transparent }) => bgColor};
	width: 100%;
	${({ color }) => color && `color: ${Colors[color]}`};
	cursor: ${({ disable }) => (disable ? 'default' : 'pointer')};
	${({ justify }) => justify && `justify-content: ${justify};`}
	${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
	${Padding}
	${Border}
	${({ disable, openMenu, isInline, isInvalid, theme }) =>
		!disable &&
		`
		.close {
			visibility: hidden;
		}
		.chevron {
			transition: all 0.2s linear;
		}
		:focus {
			border-color : ${isInvalid ? theme.colors.error600 : theme.colors.primary600};
		}
		:focus-visible {
			outline: none;
		}
		:hover .close{
			visibility: visible;
		}
		${
			isInline
				? `
			border-color : ${Colors.transparent};
			:hover {
				border-color : ${Colors.grey300};
			}
		`
				: ''
		};
		${
			openMenu
				? `.chevron {
				transform : rotate(180deg);
			}`
				: ''
		}
	`}
`;

export const StyledMultiSelectTag = styled(Tag)<{ disabled: boolean }>`
	${({ disabled }) =>
		disabled &&
		`
		background-color: ${Colors.grey100};
		>span{
			color: ${Colors.grey300};
		}
	`}
`;

export const DefaultDropdownToggleStylingProps = {
	padding: { top: 10, bottom: 10, left: 14, right: 14 },
	border: { all: 1, radius: 8, color: Colors.grey300 },
	width: 320,
	height: 40,
	cursor: 'pointer',
	color: 'grey900',
	fontSize: 'text_md',
	fontWeight: 'regular',
};
