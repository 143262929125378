import React, { Suspense } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import appRoutes, { RouteConstants } from './config';

import { FETCH_PORTAL_CONFIG } from 'actions/AppActions';
import PrivatePortalLogin from 'containers/emptystates/PrivatePortalLogin';
import Layout from 'containers/Layout';
import { getRedirectedRoute, hasAccessToRoute } from 'helpers/routes';
import AuthCallback from 'Screens/AuthCallback';
import AutoLogin from 'Screens/AutoLogin';
import PageNotFound from 'Screens/PageNotFound';
import {
	getPortalSettings,
	getVisiblePortalSections,
	getWorkspaceDetails,
} from 'selectors/AppSelector';
import { getUserInfo } from 'selectors/UserSelector';
import { RootState } from 'types/state';

interface RouterCompProps extends ReduxProps {
	isCustomDomain: boolean;
	uniqueName: string;
	boardUniqueName: string;
}

const Router: React.FC<RouterCompProps> = (props) => {
	const { settings, isCustomDomain, uniqueName, sections, boardUniqueName } = props;

	const getAppRoutes = (workspaceName: string, hasCustomDomain: boolean, boardName: string) => {
		if (!props.settings || !boardName) return null;
		const routes = appRoutes.map((route) => {
			const hasAccess = hasAccessToRoute(sections, route.section!);

			const RouteComp = hasAccess ? (
				<route.component />
			) : (
				<Navigate to={getRedirectedRoute(props.sections, boardName, workspaceName)} />
			);

			return (
				<Route
					key={route.key}
					element={RouteComp}
					path={
						hasCustomDomain ? `/:boardName${route.path}` : `/:workspaceName/:boardName${route.path}`
					}
				/>
			);
		});

		const pageNotFoundRoute = <Route key="page_not_found" path="*" element={<PageNotFound />} />;
		return [...routes, pageNotFoundRoute];
	};

	const getAppRedirects = (workspaceName: string, hasCustomDomain: boolean, boardName: string) => {
		if (props.sections.length <= 0) return null;

		return (
			<Route
				path={hasCustomDomain ? '/:boardName' : '/:workspaceName/:boardName'}
				element={<Navigate to={getRedirectedRoute(props.sections, boardName, workspaceName)} />}
			/>
		);
	};

	const getAutoLoginRoute = (hasCustomDomain: boolean) => {
		return (
			<Route
				element={<AutoLogin />}
				path={hasCustomDomain ? `/:boardName/auto-login` : `/:workspaceName/:boardName/auto-login`}
			/>
		);
	};

	if (props.settings?.redirectUrl || props.loadingStatus === FETCH_PORTAL_CONFIG.loading) {
		return null;
	}

	if (settings?.visibility === 'PRIVATE' && !props.userInfo) {
		return <PrivatePortalLogin />;
	}

	return (
		<Suspense>
			<Routes>
				<Route key="auth_callback" path={RouteConstants.AUTH_CALLBACK} element={<AuthCallback />} />
				{getAutoLoginRoute(isCustomDomain)}
				<Route path="/" element={<Layout />}>
					<Route
						path={`/${uniqueName}/${boardUniqueName}/feedbacks/*`}
						element={<Navigate to={`/${uniqueName}/${boardUniqueName}/feedback`} />}
					/>
					{getAppRedirects(uniqueName, isCustomDomain, boardUniqueName)}
					{getAppRoutes(uniqueName, isCustomDomain, boardUniqueName)}
				</Route>
			</Routes>
		</Suspense>
	);
};

const mapStateToProps = (state: RootState) => ({
	settings: getPortalSettings(state),
	workspaceDetails: getWorkspaceDetails(state),
	userInfo: getUserInfo(state),
	loadingStatus: state.appReducer.loadingStatus,
	sections: getVisiblePortalSections(state),
});

const connector = connect(mapStateToProps, {});
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Router);
