import React, { useState, ChangeEvent, MouseEvent } from 'react';

import Button from '../Button';

import { richCalendarOptions, StyledFlexContainer, StyledItem } from './Calendar';
import { ActiveDate, CalendarProps, ValueType } from './calendarTypes';

import BaseCalendar from 'components/Calendar/BaseCalendar';
import FlexContainer from 'components/Container/FlexContainer';
import Text from 'components/Text';
import { compareValue } from 'helpers/date';
import { ColorFamily, Colors, FontSize, FontWeight } from 'theme';
import { FlexAlignItems, FlexDirection, FlexJustify, Space } from 'theme/layout';

// change is triggered on click of done button

const Calendar = (props: CalendarProps) => {
	const {
		view,
		value,
		isRangeEnabled = false,
		inputRef,
		className,
		locale,
		maxDate,
		minDate,
		onChange,
		onCancel,
		isRichCalendar = false,
		richOptions,
	} = props;

	const [selectedValue, setSelectedValue] = useState<ValueType>(value);
	const [activeDate, setActiveDate] = useState<ActiveDate>('startDate');
	const [activeStartDate, setActiveStartDate] = useState<Date | undefined>(undefined);

	const updateStartDate = (newVal: Date) => {
		if (!selectedValue) {
			setSelectedValue([newVal]);
			setActiveDate('endDate');
			return;
		}

		if (Array.isArray(selectedValue)) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const [_, endDate] = selectedValue;

			if (!endDate) {
				setActiveStartDate(newVal);
				setSelectedValue([newVal]);
				setActiveDate('endDate');
				return;
			}

			// if the startDate > endDate, set both the values to the startDate value
			if (endDate && newVal > endDate) {
				setSelectedValue([newVal, newVal]);
				return;
			}

			setActiveStartDate(newVal);
			setSelectedValue([newVal, endDate]);
			setActiveDate('endDate');
		}
	};

	const updateEndDate = (newVal: Date) => {
		// if there are no date values, set both the values to the endDate and focus on startDate
		if (!selectedValue) {
			setActiveStartDate(newVal);
			setSelectedValue([newVal, newVal]);
			setActiveDate('startDate');
			return;
		}

		if (Array.isArray(selectedValue)) {
			const [startDate] = selectedValue;

			// if the endDate < startDate, set both the values to the endDate
			if (newVal < startDate) {
				setActiveStartDate(newVal);
				setSelectedValue([newVal, newVal]);
				return;
			}
			setActiveStartDate(startDate);
			setSelectedValue([startDate, newVal]);
			setActiveDate('endDate');
		}
	};

	const onDateChange = (dateValue: Date) => {
		if (!isRangeEnabled) {
			setActiveStartDate(dateValue);
			return setSelectedValue(dateValue);
		}
		return activeDate === 'startDate' ? updateStartDate(dateValue) : updateEndDate(dateValue);
	};

	const onConfirmValue = (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>) => {
		onChange?.(selectedValue, e);
	};

	const handleClearDate = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setActiveDate('startDate');
		setActiveStartDate(undefined);
		setSelectedValue(null);
	};

	const configuration = {
		view,
		value: selectedValue,
		isRangeEnabled,
		inputRef,
		className,
		locale,
		maxDate,
		minDate,
		onClickDay: onDateChange,
	};

	const isDoneDisabled = () => {
		if (isRangeEnabled && Array.isArray(selectedValue) && selectedValue?.length !== 2) {
			return true;
		}
		return compareValue(value, selectedValue);
	};

	const richCalendarList = richOptions?.map((option) => richCalendarOptions[option]);
	const showRichCalendar = isRichCalendar && richCalendarList?.length;

	return (
		<StyledFlexContainer>
			{showRichCalendar && (
				<StyledFlexContainer
					direction={FlexDirection.column}
					justify={FlexJustify.flexStart}
					alignItems={FlexAlignItems.flexStart}
					border={{ right: 1, color: Colors.grey200 }}
					width={160}
					padding={{ all: 8 }}
					flexShrink={0}
				>
					{richCalendarList?.map((option) => {
						const isSelected = selectedValue === option?.value;
						return (
							<StyledItem
								alignItems={FlexAlignItems.center}
								padding={{ all: 8 }}
								bgColor={isSelected ? Colors.grey50 : Colors.transparent}
								cursor="pointer"
								margin={{ bottom: 8 }}
								isSelected={isSelected}
								minWidth={140}
								border={{
									all: 1,
									color: isSelected ? Colors.grey100 : Colors.transparent,
									radius: 4,
								}}
								onClick={() => {
									setSelectedValue(option?.value);
								}}
							>
								<Text
									fontSize={FontSize.text_sm}
									fontWeight={FontWeight.semiBold}
									color={Colors.grey500}
								>
									{option?.label}
								</Text>
							</StyledItem>
						);
					})}
				</StyledFlexContainer>
			)}
			<FlexContainer direction={FlexDirection.column}>
				<BaseCalendar
					{...configuration}
					maxWidth={356}
					activeDate={activeDate}
					setActiveDate={setActiveDate}
					setSelectedValue={setSelectedValue}
					activeStartDate={activeStartDate}
					setActiveStartDate={setActiveStartDate}
				/>
				<StyledFlexContainer
					border={{ top: 1, color: Colors.grey200 }}
					width={isRangeEnabled ? 356 : 312}
					alignItems={FlexAlignItems.center}
					columnGap={12}
					padding={{ all: 8 }}
					height={48}
				>
					<Button
						variant="tertiary"
						onClick={handleClearDate}
						size="sm"
						translation="clear"
						colorTheme={ColorFamily.grey}
					/>
					<Button
						margin={{ left: Space.auto }}
						onClick={() => onCancel?.()}
						variant="secondary"
						size="sm"
						translation="cancel_text"
						colorTheme={ColorFamily.grey}
					/>
					<Button
						variant="primary"
						disabled={isDoneDisabled()}
						onClick={(e) => onConfirmValue(e)}
						size="sm"
						translation="apply"
					/>
				</StyledFlexContainer>
			</FlexContainer>
		</StyledFlexContainer>
	);
};

export default Calendar;
