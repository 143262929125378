import { css } from '@emotion/css';
import styled from '@emotion/styled';
import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { CloseIcon } from 'zeda-icons/v2';

import { ToastConfig } from './config';

import { Colors, FontSize } from 'theme';

const StyledToastContainer = styled(ToastContainer)`
	&.Toastify__toast-container {
		width: 396px;
	}
	&.Toastify__toast-container .Toastify__toast .Toastify__toast-body {
		padding: 0px;
	}
	&.Toastify__toast-container .Toastify__toast {
		height: 48px;
		font-family: 'Satoshi-Variable', sans-serif;
		box-shadow: none;
		padding: 12px;
	}
`;

const CustomCloseIcon = styled(CloseIcon)`
	align-self: center;
	:hover {
		cursor: pointer;
	}
`;

const getToasterStyles = (color: string, borderColor: string) => css`
	border: 1px solid ${borderColor};
	background: ${color};
	border-radius: 8px;
`;

interface ToastProps {
	closeToast: () => void;
}

const Toast = () => {
	const CloseButton = ({ closeToast }: ToastProps) => (
		<CustomCloseIcon color={Colors.grey500} fontSize={FontSize.text_md} onClick={closeToast} />
	);

	return (
		<StyledToastContainer
			position="bottom-left"
			autoClose={1500}
			closeButton={CloseButton}
			hideProgressBar
			newestOnTop
			pauseOnFocusLoss
			transition={Slide}
			toastClassName={(toasterProps) => {
				const { type } = toasterProps ?? {};
				const toastType = ToastConfig[type ?? 'info'];
				return `${getToasterStyles(toastType.bgColor, toastType.iconBg)} Toastify__toast`;
			}}
		/>
	);
};

export default Toast;
