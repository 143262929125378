import startCase from 'lodash/startCase';
import { useEffect, useState } from 'react';

import useWindowDimensions from './useWindowDimensions';

import { SCREEN_DIMENSIONS } from 'constants/constants';
import { Screens } from 'types';

const defaultScreenVals = {
	isMobile: false,
	isTablet: false,
	isDesktop: false,
};

export default () => {
	const { width } = useWindowDimensions();
	const [currentScreen, setCurrenScreen] = useState(Screens.DESKTOP);

	useEffect(() => {
		if (width > SCREEN_DIMENSIONS.desktop.min) {
			setCurrenScreen(Screens.DESKTOP);
			return;
		}

		if (width > SCREEN_DIMENSIONS.tablet.min && width <= SCREEN_DIMENSIONS.tablet.max) {
			setCurrenScreen(Screens.TABLET);
			return;
		}

		if (width < SCREEN_DIMENSIONS.mobile.max) {
			setCurrenScreen(Screens.MOBILE);
		}
	}, [width]);

	const screenVals = { ...defaultScreenVals, [`is${startCase(currentScreen)}`]: true };

	return { currentScreen, ...screenVals };
};
