import { useTheme } from '@emotion/react';
import React from 'react';
import { CheckIcon } from 'zeda-icons/v3';

import Checkbox from 'components/Checkbox';
import Container from 'components/Container/default';
import FlexContainer from 'components/Container/FlexContainer';
import { StyledDefaultDropdownItem } from 'components/Dropdown/DropdownItem/default';
import {
	DefaultDropdownStylingProps,
	SelectDropdownItemProps,
} from 'components/Dropdown/DropdownItem/DropdownItem';
import { FlexAlignItems, Space } from 'theme/layout';

const SelectDropdownItem = (props: SelectDropdownItemProps) => {
	const { tickOnSelect, showCheckbox, children, disabled } = props;
	const { colors } = useTheme();

	return (
		<StyledDefaultDropdownItem
			{...((showCheckbox || disabled) && {
				toggle: false,
			})}
			{...props}
			bgColor="white"
		>
			<FlexContainer
				maxWidth="100%"
				bgColor={colors.transparent}
				alignItems={FlexAlignItems.center}
			>
				{showCheckbox && (
					<Checkbox isDisabled={disabled} margin={{ right: 8 }} isSelected={props.active} />
				)}
				{children}
				{!showCheckbox && tickOnSelect && props.active && (
					<Container margin={{ left: Space.auto }} bgColor={colors.transparent}>
						<CheckIcon fontSize={20} color={colors.primary600} />
					</Container>
				)}
			</FlexContainer>
		</StyledDefaultDropdownItem>
	);
};

SelectDropdownItem.defaultProps = {
	...DefaultDropdownStylingProps,
	showCheckbox: false,
	tickOnSelect: false,
};
export default SelectDropdownItem;
