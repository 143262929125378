import { FlexAlignSelf, FlexJustify, Space } from './layout';
import { PositionModel, Box, BorderModel, GridModel, GridTemplateModel } from './types';

import { Colors } from '.';

import { displayInPx } from 'helpers/theme';
import { isUndefined } from 'helpers/utils';

export const Position = ({ position }: { position?: PositionModel }): string | undefined =>
	position &&
	`
	${position.value && `position: ${position.value}`};
	${!isUndefined(position.top) && `top: ${displayInPx(position.top!)}`};
	${!isUndefined(position.bottom) && `bottom: ${displayInPx(position.bottom!)}`};
	${!isUndefined(position.left) && `left: ${displayInPx(position.left!)}`};
	${!isUndefined(position.right) && `right: ${displayInPx(position.right!)}`};
`;

export const Padding = ({ padding }: { padding?: Partial<Box> }): string | undefined =>
	padding &&
	`
	padding: ${
		padding.all
			? `${padding.all}px`
			: `${padding.top || Space.none}px
        ${padding.right || Space.none}px
        ${padding.bottom || Space.none}px
        ${padding.left || Space.none}px`
	};
`;

const formatMargin = (value: Space) => (value === Space.auto ? value : `${value}px`);
export const Margin = ({ margin }: { margin?: Partial<Box> }): string | undefined =>
	margin &&
	`
	margin: ${
		margin.all
			? `${formatMargin(margin.all)}`
			: `${formatMargin(margin.top || Space.none)}
			${formatMargin(margin.right || Space.none)}
			${formatMargin(margin.bottom || Space.none)}
			${formatMargin(margin.left || Space.none)}`
	};
`;

export const Border = ({ border }: { border?: Partial<BorderModel> }): string | undefined =>
	border &&
	`
	border-width: ${
		border.all
			? `${border.all}px`
			: `${border.top || Space.none}px
        ${border.right || Space.none}px
        ${border.bottom || Space.none}px
        ${border.left || Space.none}px`
	};
	${!isUndefined(border.radius) ? `border-radius: ${border.radius}px;` : ''}
	border-style: ${border.style ?? 'solid'};
	border-color: ${border.color ?? Colors.grey400};
`;

export const Flex = ({
	flexGrow,
	flexShrink,
	flexBasis,
	alignSelf,
	justifySelf,
}: {
	flexGrow?: number;
	flexShrink?: number;
	flexBasis?: string | number;
	alignSelf?: FlexAlignSelf;
	justifySelf?: FlexJustify;
}): string => `
	${!isUndefined(flexGrow) ? `flex-grow: ${flexGrow};` : ''}
	${!isUndefined(flexShrink) ? `flex-shrink: ${flexShrink};` : ''}
	${
		!isUndefined(flexBasis)
			? `flex-basis: ${typeof flexBasis === 'number' ? `${flexBasis}px` : flexBasis};`
			: ''
	}
	${!isUndefined(alignSelf) ? `align-self: ${alignSelf};` : ''}
	${!isUndefined(justifySelf) ? `justify-self: ${justifySelf};` : ''}
`;

export const Grid = ({
	gridRowStart,
	gridRowEnd,
	gridColumnStart,
	gridColumnEnd,
}: GridModel): string => {
	const gridColumn =
		gridColumnStart && gridColumnEnd ? `grid-column: ${gridColumnStart} / ${gridColumnEnd};` : '';
	const gridRow = gridRowStart && gridRowEnd ? `grid-row: ${gridRowStart} / ${gridRowEnd};` : '';

	return [gridColumn, gridRow].join(' ');
};

export const GridTemplate = ({
	gridTemplateConfig = {},
}: {
	gridTemplateConfig?: GridTemplateModel;
}): string => {
	const { row, column } = gridTemplateConfig;
	const rowTemplate = row ? `grid-template-rows: ${row.join(' ')};` : '';
	const columnTemplate = column ? `grid-template-columns: ${column.join(' ')};` : '';

	return [rowTemplate, columnTemplate].join(' ');
};

export const HideScrollBar = `
	/* Hide scrollbar for Chrome, Safari and Opera */
	::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	 {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
`;
