import styled from '@emotion/styled';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { ConnectedProps } from 'react-redux';
import { ImagePlusIcon, LoaderIcon } from 'zeda-icons/v3';

import connector from '../../Feedback';

import AttachmentsContainer from './AttachmentsContainer';
import CustomFieldsList from './CustomFields';
import { FormikValueType, StyledZedaEditor } from './FeedbackForm';

import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Container from 'components/Container/default';
import FlexContainer from 'components/Container/FlexContainer';
import Input from 'components/Input';
import Select from 'components/Select';
import Text from 'components/Text';
import { EditorType } from 'components/ZedaEditor/config';
import translation from 'constants/translation';
import { generateFileWithEncodedName, noop } from 'helpers/utils';
import { PropsWithRouter, withRouter } from 'routes/WithRouterComponent';
import { ColorFamily, Colors, FontSize, FontWeight } from 'theme';
import { FlexJustify, Position } from 'theme/layout';

interface Props extends ReduxProps, PropsWithRouter {
	formik: FormikProps<FormikValueType>;
	attachments: File[];
	setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
	isValid: boolean;
	isExternalForm: boolean;
	descriptionId?: string;
}

export const FileInput = styled(Input)`
	display: none;
`;

const StyledSubmitButton = styled(Button)`
	width: 112px;
	> span {
		margin: auto;
	}
`;

const LoadingIcon = styled(LoaderIcon)`
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	animation: spin 2s linear infinite;
`;

const Body = (props: Props) => {
	const { formik, attachments, setAttachments, isExternalForm } = props;
	const inputRef = useRef<HTMLInputElement>(null);
	const [descriptionValid, setDescriptionValid] = useState(true);
	let formData;

	const isDescriptionValid = (value: string) => {
		return value?.match(/<img([\w\W]+?)>/g) ||
			value?.match(/<\/?(table|td|th|tr|tfoot|thead|tbody)+>?/)
			? true
			: !!value?.replace(/<\/?[^>]+(>|$)/g, '').trim()?.length;
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(event.target?.files ?? []).map(generateFileWithEncodedName);
		setAttachments([...props.attachments, ...files]);
	};

	const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let trimmedValue = e.target.value.replace(/^\s+/g, '');

		const rawFormData = localStorage.getItem('formData');
		if (!e.target.value && rawFormData) {
			formData = JSON.parse(rawFormData);
			localStorage.setItem('formData', JSON.stringify({ ...formData, title: '' }));
		}
		if (trimmedValue.length > 100) trimmedValue = trimmedValue.substring(0, 100);

		formik.setFieldValue('title', trimmedValue);
	};

	const onAttachmentClose = (index: number | string) => {
		const updatedAttachmentsList = [...props.attachments];

		updatedAttachmentsList.splice(index as number, 1);
		setAttachments(updatedAttachmentsList);
	};

	const checkDescriptionValid = () => {
		if (!isDescriptionValid(formik.values.summary)) {
			setDescriptionValid(false);
			return;
		}
		setDescriptionValid(true);
	};

	const handleSummaryChange = (value: string) => {
		const plainSummary = value.replace(/<\/?[^>]+(>|$)/g, '');
		if (!plainSummary) {
			formData = JSON.parse(localStorage.getItem('formData') || '');
			localStorage.setItem('formData', JSON.stringify({ ...formData, summary: '' }));
		}
		if (isDescriptionValid(value)) {
			setDescriptionValid(true);
		}
		formik.setFieldValue('summary', value);
	};

	const isSubmitDisabled = !props.isValid || !isDescriptionValid(formik.values.summary);

	const isNameEmailRequired =
		(props.portalConfig?.actions.canSubmitWithoutLogin && !props.userInfo?.token) || isExternalForm;

	const typeOptions =
		props.typeOptionsForSelect?.map((option) => ({
			...option,
			itemProps: {
				fontSize: 'text_sm' as keyof typeof FontSize,
			},
		})) ?? [];

	return (
		<Container margin={{ top: 16 }} bgColor={Colors.transparent}>
			<Container bgColor={Colors.transparent}>
				<Container margin={{ top: 16 }} bgColor={Colors.transparent}>
					<Text
						fontWeight={FontWeight.semiBold}
						fontSize={FontSize.text_sm}
						color={Colors.grey700}
						translation="type_label"
					/>
					<Select
						placeholder={translation.type_placeholder}
						name="type"
						disable={false}
						options={typeOptions}
						fontSize="text_sm"
						value={formik.values.type}
						border={{ all: 1, radius: 8, color: Colors.grey300 }}
						onChange={(value) => formik.setFieldValue('type', value)}
						onBlur={formik.handleBlur}
						tickOnSelect
						width="100%"
						margin={{ top: 4 }}
					/>
				</Container>
				<Container margin={{ top: 16 }} bgColor={Colors.transparent}>
					<Text
						fontWeight={FontWeight.semiBold}
						fontSize={FontSize.text_sm}
						color={Colors.grey700}
						translation="title_text"
					/>
					<Input
						name="title"
						type="text"
						placeholder={translation.title_placeholder}
						value={formik.values.title}
						onChange={(e) => handleTitleChange(e)}
						onBlur={formik.handleBlur}
					/>
				</Container>
				<Container
					margin={{ top: 16 }}
					bgColor={Colors.transparent}
					position={{ value: Position.relative }}
				>
					<Text
						fontWeight={FontWeight.semiBold}
						fontSize={FontSize.text_sm}
						color={Colors.grey700}
						translation="summary_text"
					/>
					<StyledZedaEditor
						id={props.descriptionId}
						name="summary"
						type={EditorType.CARD}
						placeholder={translation.summary_placeholder}
						value={formik.values.summary}
						onChange={handleSummaryChange}
						onBlur={checkDescriptionValid}
						showBubbleMenu={false}
						isDescriptionValid={descriptionValid}
					/>
					{!descriptionValid && (
						<Text
							htmlTagName="div"
							color={Colors.error500}
							fontSize={FontSize.text_xs}
							fontWeight={FontWeight.regular}
							translation="description_required"
							position={{ value: Position.absolute, top: '100%' }}
							margin={{ top: 4 }}
						/>
					)}
				</Container>
				<CustomFieldsList customFieldsList={formik.values.customFields} formikContext={formik} />
				{isNameEmailRequired && (
					<>
						<Container margin={{ top: 16 }} bgColor={Colors.transparent}>
							<Text
								fontWeight={FontWeight.semiBold}
								fontSize={FontSize.text_sm}
								color={Colors.grey700}
								translation="name_text"
							/>
							<Input
								name="userName"
								type="text"
								placeholder="Name"
								value={formik.values.userName}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</Container>
						<Container margin={{ top: 16 }} bgColor={Colors.transparent}>
							<Text
								fontWeight={FontWeight.semiBold}
								fontSize={FontSize.text_sm}
								color={Colors.grey700}
								translation="email_text"
							/>
							<Input
								name="email"
								type="text"
								placeholder="Email"
								value={formik.values.email}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</Container>
					</>
				)}
				<AttachmentsContainer attachments={attachments} onDelete={onAttachmentClose} />
			</Container>
			<FlexContainer
				margin={{ top: 16 }}
				justify={FlexJustify.spaceBetween}
				bgColor={Colors.transparent}
			>
				<FileInput
					type="file"
					inputFieldRef={inputRef}
					display="none"
					value=""
					multiple
					onChange={handleChange}
				/>
				<IconButton
					icon={ImagePlusIcon}
					variant="secondary"
					colorTheme={ColorFamily.grey}
					size="md"
					onClick={() => {
						inputRef.current?.click();
					}}
				/>
				<StyledSubmitButton
					variant="primary"
					size="md"
					onClick={formik.isSubmitting ? noop : () => formik.handleSubmit()}
					translation="submit_text"
					disabled={isSubmitDisabled}
					{...(formik.isSubmitting && { leadingIcon: LoadingIcon })}
				/>
			</FlexContainer>
		</Container>
	);
};

type ReduxProps = ConnectedProps<typeof connector>;
export default connector(withRouter(Body));
